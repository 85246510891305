import React from "react";
import { REPORT_SALE_OPTION } from "../../../Utils/enums";

const SaleOfIllegal = ({
  selectedReportOption,
  setSelectedReportOption,
  SubmitReport,
}) => {
  const handleReportOptionChange = (option) => {
    setSelectedReportOption(option);
  };
  return (
    <div className="reportPost-info">
      <div className="why-report-list" role="list">
        <p className="title">Why are you reporting this post?</p>

        {REPORT_SALE_OPTION.map((option, i) => (
          <div
            tabIndex="0"
            className="report-reason nudity"
            onClick={() => {
              handleReportOptionChange(option);
            }}
          >
            <div className="group-check">
              <input
                type="radio"
                id={`myCheckbox${i}`}
                aria-label="Check this box"
                checked={selectedReportOption === option}
              />
              <span className="checkmark" />
            </div>
            <p className="report-reason-title">{option}</p>
          </div>
        ))}
        <div className="nudity-guidelines">
          <p className="title">Sale of illegal or regulated goods guidelines</p>

          <div className="we-remove">
            <span className="subtitle">
              We define illegal or regulated goods as things such as:
            </span>
            <ul className="remove-list-wrapper">
              <li className="remove-list">
                <div className="circle" aria-hidden="true" />
                <p className="info">Falsified health documents.</p>
              </li>

              <li className="remove-list">
                <div className="circle" aria-hidden="true" />
                <p className="info">
                  Buying, selling or trading recreational or pharmaceutical
                  drugs.
                </p>
              </li>

              <li className="remove-list">
                <div className="circle" aria-hidden="true" />
                <p className="info">Promoting the use of recreational drugs.</p>
              </li>

              <li className="remove-list">
                <div className="circle" aria-hidden="true" />
                <p className="info">
                  Selling or trading guns, ammunition, explosives, tobacco, live
                  animals or animal parts between people.
                </p>
              </li>

              <li className="remove-list">
                <div className="circle" aria-hidden="true" />
                <p className="info">
                  Intimate images of others that were shared without permission.
                </p>
              </li>
            </ul>
          </div>

          <button className="btn btn-submit" onClick={() => SubmitReport()}>
            Submit Report
          </button>
        </div>
      </div>
    </div>
  );
};

export default SaleOfIllegal;
