import React, { useEffect, useRef, useState } from 'react'
import { getTepars } from "../../Utils/helpers";
import { post_video_thumb_url } from "../../ServerConfiguration/awsConfiguration";

function MediaSliderVideo({ index, sound, post, Media, handlePlayPauseToggleClick, disableRightClick, loading }) {
    
    const videoRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });

    const handleLoadedMetadata = (event) => {
        setVideoDimensions({ width: event.target.videoWidth, height: event.target.videoHeight });
    };
    // 66a3324408ac41eaac723e83
    const handleLoadedData = () => {
        setIsLoading(false);
    };

    useEffect(() => {
        if (videoRef.current && videoRef.current.readyState == 4) {
            setIsLoading(false);
        }
    }, []);

    // const getObjectFit = () => {
    //     return videoDimensions.height < videoDimensions.width ? 'contain' : 'cover';
    // };

    const getObjectFit = () => {
        return videoDimensions.height < videoDimensions.width 
        ? post?.media[0]?.aspectRatio === 0.5 
                ? 'cover'
                : 'contain' 
        : 'cover';
    };

    return (
        <>
            {/* {!isLoading && (
                <img
                    src={post_video_thumb_url + Media?.thumbnail}
                    alt="Thumbnail"
                    style={{ width: '100%', height: 'auto', display: 'block', position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
                />
            )} */}
            <video
                ref={videoRef}
                loop={true}
                muted={sound}
                className="view-video"
                poster={post_video_thumb_url + Media?.thumbnail}
                src={getTepars(Media)}
                name={`${post?._id}-${index}`}
                id={`${post?._id}-${index}`}
                onLoadedMetadata={handleLoadedMetadata}
                onLoadedData={handleLoadedData}
                onClick={() => handlePlayPauseToggleClick(`${post?._id}-${index}`)}
                onContextMenu={disableRightClick}
                style={{
                    opacity: isLoading ? 0 : 1,
                    transition: 'opacity 150ms ease-in-out',
                    objectFit: getObjectFit()
                }}

            />
        </>
    )
}
export default MediaSliderVideo;