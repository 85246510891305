import React, { useState, useRef } from "react";
import MediaSlider from "./MediaSlider";
import ComingSoon from "../Common/ComingSoon";
import axiosRequest from "../../Middleware/api";
import { useDispatch, useSelector } from "react-redux";
import { ERROR, LIKED, UNLIKE } from "../../Utils/enums";
import like from "../../assets/image/SVG_Images/like.svg";
import unlike from "../../assets/image/SVG_Images/unlike.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import unlikeGrey from "../../assets/image/SVG_Images/unlikeGrey.svg";
import {
  getSelfUser,
  getUserProfilePic,
  convertToAgoFormat,
  getSelectedUserDetails,
} from "../../Utils/helpers";
import {
  setTepars,
  handleToast,
  setPostDetails,
  setSelfContact,
  setTogglePostAction,
} from "../../Redux/actions";
import "react-loading-skeleton/dist/skeleton.css";

function TeparsPost({
  skeletonLoad,
  post,
  setPostMenu,
  setSelectedPost,
  setPostLikes,
  whySeenPost,
  repostPopup,
  handleRepostModal,
  setSelectPostID,
  sound,
  setSound,
  setSelectedMenu,
}) {
  const dispatch = useDispatch();

  const replyRef = useRef(null);

  const UserID = localStorage.getItem("user_id");

  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const selfUser = useSelector((state) => state.selfContact);

  const [showAnimation, setShowAnimation] = useState(false);
  const [showReplyPopup, setShowReplyPopup] = useState(false);
  const [showFullMessage, setShowFullMessage] = useState(false);

  const commentToggle = (post) => {
    dispatch(setPostDetails(post));
    dispatch(setTogglePostAction("ViewPost"));
  };

  const toggleLike = (postId) => {
    axiosRequest
      .put(`post/like/${postId}`)
      .then((res) => {
        if (res?.data?.message === LIKED || res?.data?.message === UNLIKE) {
          if (res?.data?.message === LIKED) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps + 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          if (res?.data?.message === UNLIKE) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps - 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          let newpost = post;
          const newObj = {
            userId: {
              _id: selfUser?._id,
              name: "You",
              profile: selfUser?.profile,
              phone: selfUser?.phone,
              countryCode: selfUser?.countryCode,
              contactStatus: selfUser?.isUserVerified,
            },
          };
          if (post?.selfLike) {
            newpost.TotalLike -= 1;
            newpost.selfLike = false;
            newpost.likeUser = newpost?.likeUser?.filter(
              (item) => item?.userId?._id !== UserID
            );
          } else {
            newpost.TotalLike += 1;
            newpost.selfLike = true;
            newpost.likeUser = [...(newpost?.likeUser || []), newObj];
          }
          const findPost = Tepars.map((Post) =>
            Post?._id === postId ? newpost : Post
          );
          dispatch(setTepars(findPost));
          if (newpost?.selfLike) {
            setShowAnimation(true);
            setTimeout(() => {
              setShowAnimation(false);
            }, 1000);
          }
        } else {
          dispatch(handleToast(res?.data?.message));
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const toggleReadMore = () => {
    setShowFullMessage((prevState) => !prevState);
  };

  const openGoogleMaps = () => {
    const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${post?.location?.latitude},${post?.location?.longitude}`;
    window.open(googleMapsURL, "_blank");
  };

  const renderLikeInfo = () => {
    if (post?.userId?.userType === "Admin") {
      if(post?.TotalLike >0){
      return (
        <p className="users-liked-info">
          <span>{post?.TotalLike}</span> Teps
        </p>
      );
    }
    } else if (post?.TotalLike === 1) {
      if (
        post?.likeUser[0]?.userId?.contactStatus ||
        post?.likeUser[0]?.userId._id === UserID
      ) {
        return (
          <p className="users-liked-info" onClick={() => setPostLikes(true)}>
            <span>{post?.likeUser[0]?.userId?.name}</span> Teps this
          </p>
        );
      } else {
        return (
          <p className="users-liked-info" onClick={() => setPostLikes(true)}>
            <span>~{post?.likeUser[0]?.userId?.name}</span> Teps this
          </p>
        );
      }
    } else if (post?.TotalLike > 0) {
      const userLiked = post?.likeUser?.some(
        (likes) => likes?.userId?._id === UserID
      );
      const otherLikes = post?.TotalLike - 1;

      if (userLiked) {
        return (
          <p className="users-liked-info" onClick={() => setPostLikes(true)}>
            <span>You</span> and{" "}
            <span>
              {post?.hideLikeCount !== true && otherLikes}
              {otherLikes > 1 ? " others " : " other "}
            </span>
            Teps this
          </p>
        );
      }
      if (
        post?.likeUser[0]?.userId?.contactStatus ||
        post?.likeUser[0]?.userId._id === UserID
      ) {
        return (
          <p className="users-liked-info">
            <span>{post?.likeUser[0]?.userId?.name}</span> and{" "}
            <span>
              {post?.hideLikeCount !== true && otherLikes}
              {post?.TotalLike > 2 ? " others " : " other "}
            </span>
            Teps this
          </p>
        );
      } else {
        return (
          <p className="users-liked-info">
            <span>~{post?.likeUser[0]?.userId?.name}</span> and{" "}
            <span>
              {post?.hideLikeCount !== true && otherLikes}
              {post?.TotalLike > 2 ? " others " : " other "}
            </span>
            Teps this
          </p>
        );
      }
    }
    return null;
  };

  return (
    <>
      {skeletonLoad ? (
        <SkeletonTheme
          baseColor={
            theme === "Light"
              ? ""
              : theme === "Dark"
              ? "#202020"
              : window.matchMedia("(prefers-color-scheme: light)").matches
              ? ""
              : "#202020"
          }
          highlightColor={
            theme === "Light"
              ? ""
              : theme === "Dark"
              ? "#2A2A2A"
              : window.matchMedia("(prefers-color-scheme: light)").matches
              ? ""
              : "#2A2A2A"
          }
        >
          <div className="post-wrapper">
            <article className="post">
              <div className="header">
                <div className="profile">
                  <div className="profile-image">
                    <Skeleton width={50} height={53} circle={true} />
                  </div>
                  <div className="profile-info">
                    <div className="profile-info-wrapper">
                      <div className="profile-info-name">
                        <span className="name">
                          <Skeleton width={150} height={20} borderRadius={50} />
                        </span>
                      </div>
                      <div className="profile-details">
                        <p className="profile-details-time">
                          <Skeleton width={50} height={5} borderRadius={50} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="post-info-wrapper">
                <Skeleton height={350} borderRadius={10} />
              </div>
            </article>
          </div>
        </SkeletonTheme>
      ) : (
        <div className="post-wrapper">
          {post?.RepostPostId && (
            <div className="reposted-notification">
              <div className="reposted-notification-wrapper">
                <div className="repost-image">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none">
                    <title>Repost</title>
                    <g id="Iconsax/Linear/messagetext">
                      <g id="Group 1261154220">
                        <g id="Group">
                          <path
                            id="Vector"
                            d="M19.9523 11.9214V9.19735C19.9523 8.11367 19.5218 7.07437 18.7555 6.3081C17.9893 5.54182 16.95 5.11133 15.8663 5.11133H4.97021"
                            stroke={
                              theme === "Light"
                                ? "#686868"
                                : theme === "Dark"
                                ? "#999999"
                                : window.matchMedia(
                                    "(prefers-color-scheme: light)"
                                  ).matches
                                ? "#686868"
                                : "#999999"
                            }
                            strokeWidth="1.37293"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            id="Vector_2"
                            d="M9.0537 9.19158L4.96631 5.10556L9.0537 1.01953"
                            stroke={
                              theme === "Light"
                                ? "#686868"
                                : theme === "Dark"
                                ? "#999999"
                                : window.matchMedia(
                                    "(prefers-color-scheme: light)"
                                  ).matches
                                ? "#686868"
                                : "#999999"
                            }
                            strokeWidth="1.37293"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <g id="Group_2">
                          <path
                            id="Vector_3"
                            d="M4.96566 12.0005V14.7245C4.96566 15.8082 5.39615 16.8475 6.16243 17.6138C6.92871 18.3801 7.96801 18.8105 9.05169 18.8105H19.9478"
                            stroke={
                              theme === "Light"
                                ? "#686868"
                                : theme === "Dark"
                                ? "#999999"
                                : window.matchMedia(
                                    "(prefers-color-scheme: light)"
                                  ).matches
                                ? "#686868"
                                : "#999999"
                            }
                            strokeWidth="1.37293"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            id="Vector_4"
                            d="M15.8643 14.7303L19.9517 18.8163L15.8643 22.9023"
                            stroke={
                              theme === "Light"
                                ? "#686868"
                                : theme === "Dark"
                                ? "#999999"
                                : window.matchMedia(
                                    "(prefers-color-scheme: light)"
                                  ).matches
                                ? "#686868"
                                : "#999999"
                            }
                            strokeWidth="1.37293"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                {post?.userId?._id === UserID ? (
                  <span className="text">
                    You Reposted
                    <span
                      className="reposted-user-name"
                      onClick={() =>
                        getSelectedUserDetails(
                          post?.RepostPostId?.userId?._id,
                          dispatch,
                          setSelectedMenu
                        )
                      }
                    >
                      @{post?.RepostPostId?.userId?.name}
                    </span>
                    Post
                  </span>
                ) : (
                  <span className="text">Reposted</span>
                )}
              </div>
            </div>
          )}
          <article id={`post-${post?._id}`} className="post">
            <div className="header">
              <div className="profile">
                {skeletonLoad ? (
                  <Skeleton width={60} height={60} circle={true} />
                ) : (
                  <div
                    className="profile-image"
                    tabIndex="0"
                    onClick={() =>
                      getSelectedUserDetails(
                        post?.userId?._id,
                        dispatch,
                        setSelectedMenu
                      )
                    }
                  >
                    <img
                      className="profile-avatar"
                      src={getUserProfilePic(post.userId.profile)}
                      alt="Profile Avatar"
                    />
                  </div>
                )}
                <div className="profile-info">
                  <div className="profile-info-wrapper">
                    <div className="profile-info-name">
                      <span
                        className="name"
                        tabIndex="0"
                        onClick={() =>
                          getSelectedUserDetails(
                            post?.userId?._id,
                            dispatch,
                            setSelectedMenu
                          )
                        }
                      >
                        {post?.userId?._id === UserID
                          ? post?.userId?.webName
                            ? post?.userId?.webName + " (You)"
                            : post?.userId?.name + " (You)"
                          : getSelfUser(post.userId) || <Skeleton />}
                      </span>
                      {post?.location?.name && (
                        <>
                          <span> is in </span>
                          <span
                            className="location-name"
                            onClick={() => openGoogleMaps()}
                          >
                            <svg
                              width="11"
                              height="13"
                              viewBox="0 0 13 18"
                              fill="none"
                              className="location-icon"
                            >
                              <path
                                d="M6.49759 0C5.33853 5.90322e-05 4.20058 0.310141 3.20172 0.898099C2.20285 1.48606 1.37944 2.33049 0.816831 3.34385C0.254225 4.35721 -0.0270883 5.50261 0.00205481 6.66131C0.0311979 7.82 0.369737 8.94981 0.982574 9.93361L6.49759 18L12.0126 9.93361C12.6255 8.94981 12.964 7.82 12.9931 6.66131C13.0223 5.50261 12.741 4.35721 12.1784 3.34385C11.6158 2.33049 10.7923 1.48606 9.79347 0.898099C8.79461 0.310141 7.65666 5.90322e-05 6.49759 0ZM6.49759 8.88797C6.02489 8.88797 5.5628 8.74779 5.16976 8.48517C4.77672 8.22255 4.47038 7.84928 4.28948 7.41255C4.10859 6.97583 4.06126 6.49527 4.15348 6.03165C4.2457 5.56803 4.47333 5.14216 4.80758 4.80791C5.14183 4.47366 5.5677 4.24603 6.03132 4.15381C6.49494 4.06159 6.9755 4.10892 7.41222 4.28981C7.84895 4.47071 8.22222 4.77705 8.48484 5.17009C8.74746 5.56313 8.88764 6.02522 8.88764 6.49793C8.88764 7.1318 8.63583 7.73972 8.18761 8.18794C7.73939 8.63616 7.13147 8.88797 6.49759 8.88797Z"
                                fill="#1790A3"
                              />
                            </svg>
                            {post?.location?.name}
                          </span>
                        </>
                      )}
                    </div>

                    <div className="profile-details">
                      <p className="profile-details-time">
                        {convertToAgoFormat(post?.createAt)}
                      </p>
                    </div>
                  </div>
                  <button
                    className="btn profile-cta"
                    onClick={() => {
                      setPostMenu(true);
                      setSelectedPost(post);
                    }}
                    title="More options"
                  >
                    <svg width="6" height="16" viewBox="0 0 6 24" fill="none">
                      <path
                        d="M3 0.75C1.625 0.75 0.5 1.875 0.5 3.25C0.5 4.625 1.625 5.75 3 5.75C4.375 5.75 5.5 4.625 5.5 3.25C5.5 1.875 4.375 0.75 3 0.75ZM3 18.25C1.625 18.25 0.5 19.375 0.5 20.75C0.5 22.125 1.625 23.25 3 23.25C4.375 23.25 5.5 22.125 5.5 20.75C5.5 19.375 4.375 18.25 3 18.25ZM3 9.5C1.625 9.5 0.5 10.625 0.5 12C0.5 13.375 1.625 14.5 3 14.5C4.375 14.5 5.5 13.375 5.5 12C5.5 10.625 4.375 9.5 3 9.5Z"
                        fill={
                          theme === "Light"
                            ? "#2a2a2a"
                            : theme === "Dark"
                            ? "#ffffff"
                            : window.matchMedia("(prefers-color-scheme: light)")
                                .matches
                            ? "#2a2a2a"
                            : "#ffffff"
                        }
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="post-info-wrapper">
              {post?.description && (
                <div className="post-info">
                  <p className="info">
                    {showFullMessage
                      ? post?.description
                      : post?.description?.slice(0, 140)}{" "}
                    {post?.description.length > 140 && !showFullMessage && (
                      <span onClick={toggleReadMore} className="read-more">
                        ...Read More
                      </span>
                    )}
                  </p>
                </div>
              )}
              {/* Media */}
              <MediaSlider
                post={post}
                sound={sound}
                setSound={setSound}
                showAnimation={showAnimation}
                setShowAnimation={setShowAnimation}
              />
              <div className="post-media-cta">
                <div className="media-cta">
                  {/* Like */}
                  <button
                    className="btn btn-like"
                    onClick={() => {
                      toggleLike(post?._id);
                    }}
                  >
                    <img
                      src={
                        post?.selfLike
                          ? like
                          : theme === "Light" ||
                            (theme !== "Dark" &&
                              window.matchMedia("(prefers-color-scheme: light)")
                                .matches)
                          ? unlike
                          : unlikeGrey
                      }
                      className={post?.selfLike ? "animate" : ""}
                      title={post?.selfLike ? "Unteps" : "Teps"}
                      alt="Teps"
                    />
                  </button>
                  {/* Comment */}
                  {!post?.turnOffComment && (
                    <button
                      className="btn btn-comment"
                      onClick={() => commentToggle(post)}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <title>Comment</title>
                        <g id="Iconsax/Linear/messagetext">
                          <g id="Group 34106">
                            <path
                              id="Vector"
                              d="M7.2793 9.35156H16.745M7.2793 14.6112H13.9053"
                              stroke={
                                theme === "Light"
                                  ? "#686868"
                                  : theme === "Dark"
                                  ? "#999999"
                                  : window.matchMedia(
                                      "(prefers-color-scheme: light)"
                                    ).matches
                                  ? "#686868"
                                  : "#999999"
                              }
                              strokeWidth="1.37293"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              id="Vector_2"
                              d="M11.9932 22.1033C13.9902 22.1033 15.9423 21.5111 17.6027 20.4014C19.2631 19.2918 20.5572 17.7146 21.3214 15.8693C22.0856 14.0241 22.2856 11.9936 21.896 10.0347C21.5064 8.07574 20.5448 6.27636 19.1327 4.86405C17.7207 3.45174 15.9216 2.48995 13.963 2.1003C12.0044 1.71064 9.97431 1.91063 8.12937 2.67496C6.28443 3.43929 4.70754 4.73365 3.59809 6.39435C2.48865 8.05504 1.89648 10.0075 1.89648 12.0048C1.89648 13.6744 2.30035 15.2476 3.01835 16.6355L1.89648 22.1033L7.36331 20.9813C8.74993 21.6983 10.325 22.1033 11.9932 22.1033Z"
                              stroke={
                                theme === "Light"
                                  ? "#686868"
                                  : theme === "Dark"
                                  ? "#999999"
                                  : window.matchMedia(
                                      "(prefers-color-scheme: light)"
                                    ).matches
                                  ? "#686868"
                                  : "#999999"
                              }
                              strokeWidth="1.37293"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </g>
                      </svg>
                    </button>
                  )}
                  {/* Reply */}
                  {post?.canReply &&
                    post?.userId?._id !== UserID &&
                    post?.userId?.userType !== "Admin" && (
                      <button
                        ref={replyRef}
                        className="btn btn-reply"
                        onClick={() => setShowReplyPopup(true)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <title>Reply</title>
                          <g id="Frame 121">
                            <path
                              id="Vector"
                              d="M20.9889 9.74951L14.9783 2.63363C14.8038 2.42713 14.5915 2.26216 14.3549 2.14917C14.1184 2.03618 13.8626 1.97765 13.604 1.97729C13.3454 1.97693 13.0895 2.03475 12.8526 2.14707C12.6157 2.2594 12.403 2.42379 12.2279 2.6298L6.19658 9.7289C5.0976 11.0227 5.94369 13.1127 7.568 13.1149L10.7896 13.1194C10.7825 18.1674 1.44786 21.6879 1.44786 21.6879C1.44786 21.6879 16.3734 23.2231 16.3874 13.1272L19.6099 13.1317C21.2333 13.134 22.0843 11.0463 20.9889 9.74951Z"
                              stroke={
                                theme === "Light"
                                  ? "#686868"
                                  : theme === "Dark"
                                  ? "#999999"
                                  : window.matchMedia(
                                      "(prefers-color-scheme: light)"
                                    ).matches
                                  ? "#686868"
                                  : "#999999"
                              }
                              strokeWidth="1.37293"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </svg>
                      </button>
                    )}
                </div>
                {/* Re-post */}
                <div className="repost">
                  {post?.settingRepost &&
                    (UserID === post?.userId?._id ? (
                      <>
                        <button
                          className="btn btn-repost"
                          onClick={() => repostPopup(post)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <title>Repost</title>
                            <g id="Iconsax/Linear/messagetext">
                              <g id="Group 1261154220">
                                <g id="Group">
                                  <path
                                    id="Vector"
                                    d="M19.9523 11.9214V9.19735C19.9523 8.11367 19.5218 7.07437 18.7555 6.3081C17.9893 5.54182 16.95 5.11133 15.8663 5.11133H4.97021"
                                    stroke={
                                      theme === "Light"
                                        ? "#686868"
                                        : theme === "Dark"
                                        ? "#999999"
                                        : window.matchMedia(
                                            "(prefers-color-scheme: light)"
                                          ).matches
                                        ? "#686868"
                                        : "#999999"
                                    }
                                    strokeWidth="1.37293"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    id="Vector_2"
                                    d="M9.0537 9.19158L4.96631 5.10556L9.0537 1.01953"
                                    stroke={
                                      theme === "Light"
                                        ? "#686868"
                                        : theme === "Dark"
                                        ? "#999999"
                                        : window.matchMedia(
                                            "(prefers-color-scheme: light)"
                                          ).matches
                                        ? "#686868"
                                        : "#999999"
                                    }
                                    strokeWidth="1.37293"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <g id="Group_2">
                                  <path
                                    id="Vector_3"
                                    d="M4.96566 12.0005V14.7245C4.96566 15.8082 5.39615 16.8475 6.16243 17.6138C6.92871 18.3801 7.96801 18.8105 9.05169 18.8105H19.9478"
                                    stroke={
                                      theme === "Light"
                                        ? "#686868"
                                        : theme === "Dark"
                                        ? "#999999"
                                        : window.matchMedia(
                                            "(prefers-color-scheme: light)"
                                          ).matches
                                        ? "#686868"
                                        : "#999999"
                                    }
                                    strokeWidth="1.37293"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    id="Vector_4"
                                    d="M15.8643 14.7303L19.9517 18.8163L15.8643 22.9023"
                                    stroke={
                                      theme === "Light"
                                        ? "#686868"
                                        : theme === "Dark"
                                        ? "#999999"
                                        : window.matchMedia(
                                            "(prefers-color-scheme: light)"
                                          ).matches
                                        ? "#686868"
                                        : "#999999"
                                    }
                                    strokeWidth="1.37293"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </button>
                        <span className="repost-number">
                          {post?.countRepost}
                        </span>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-repost"
                          onClick={() => handleRepostModal(post)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <title>Repost</title>
                            <g id="Iconsax/Linear/messagetext">
                              <g id="Group 1261154220">
                                <g id="Group">
                                  <path
                                    id="Vector"
                                    d="M19.9523 11.9214V9.19735C19.9523 8.11367 19.5218 7.07437 18.7555 6.3081C17.9893 5.54182 16.95 5.11133 15.8663 5.11133H4.97021"
                                    stroke={
                                      theme === "Light"
                                        ? "#686868"
                                        : theme === "Dark"
                                        ? "#999999"
                                        : window.matchMedia(
                                            "(prefers-color-scheme: light)"
                                          ).matches
                                        ? "#686868"
                                        : "#999999"
                                    }
                                    strokeWidth="1.37293"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    id="Vector_2"
                                    d="M9.0537 9.19158L4.96631 5.10556L9.0537 1.01953"
                                    stroke={
                                      theme === "Light"
                                        ? "#686868"
                                        : theme === "Dark"
                                        ? "#999999"
                                        : window.matchMedia(
                                            "(prefers-color-scheme: light)"
                                          ).matches
                                        ? "#686868"
                                        : "#999999"
                                    }
                                    strokeWidth="1.37293"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <g id="Group_2">
                                  <path
                                    id="Vector_3"
                                    d="M4.96566 12.0005V14.7245C4.96566 15.8082 5.39615 16.8475 6.16243 17.6138C6.92871 18.3801 7.96801 18.8105 9.05169 18.8105H19.9478"
                                    stroke={
                                      theme === "Light"
                                        ? "#686868"
                                        : theme === "Dark"
                                        ? "#999999"
                                        : window.matchMedia(
                                            "(prefers-color-scheme: light)"
                                          ).matches
                                        ? "#686868"
                                        : "#999999"
                                    }
                                    strokeWidth="1.37293"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    id="Vector_4"
                                    d="M15.8643 14.7303L19.9517 18.8163L15.8643 22.9023"
                                    stroke={
                                      theme === "Light"
                                        ? "#686868"
                                        : theme === "Dark"
                                        ? "#999999"
                                        : window.matchMedia(
                                            "(prefers-color-scheme: light)"
                                          ).matches
                                        ? "#686868"
                                        : "#999999"
                                    }
                                    strokeWidth="1.37293"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </button>
                        <span className="repost-number">
                          {post?.countRepost}
                        </span>
                      </>
                    ))}
                </div>
              </div>
            </div>
            <div className="post-report">
              {post?.TotalLike > 0 && (
                <div className="users-liked" tabIndex="0">
                  {post?.userId?.userType !== "Admin" && (
                    <div className="users-profiles-wrapper">
                      {post?.likeUser?.slice(0, 3).map((likes, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="users-profile">
                              <img
                                src={getUserProfilePic(likes?.userId.profile)}
                                alt="profile"
                              />
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  )}
                  <span
                    onClick={() => {
                      setPostLikes(true);
                      setSelectPostID(post?._id);
                    }}
                  >
                    {renderLikeInfo()}
                  </span>
                </div>
              )}
              {post?.turnOffComment !== true &&
                (post?.comments >= 1 ? (
                  <div
                    className="users-comments"
                    tabIndex="0"
                    onClick={() => commentToggle(post)}
                  >
                    <span className="view-all">
                      View all {post?.comments} comments
                    </span>
                  </div>
                ) : (
                  <div className="users-comments" tabIndex="0">
                    <span className="view-all">No comments yet</span>
                  </div>
                ))}
              {post?.turnOffComment !== true && (
                <div className="add-comment-wrapper" tabIndex="0">
                  <div className="comment-user-profile">
                    <img
                      src={getUserProfilePic(selfUser?.profile)}
                      alt="profile"
                    />
                  </div>
                  <div
                    className="add-comment"
                    onClick={() => commentToggle(post)}
                  >
                    <span className="comment">Add a comment...</span>
                  </div>
                </div>
              )}
              {post?.userId?._id !== UserID &&
                post?.userId?.userType !== "Admin" && (
                  <div
                    className="why-seen-post"
                    tabIndex="0"
                    onClick={() => whySeenPost(post)}
                  >
                    <p className="text">Why you're seeing this post</p>
                  </div>
                )}
            </div>
          </article>
        </div>
      )}
      {showReplyPopup && (
        <ComingSoon onClose={() => setShowReplyPopup(false)} />
      )}
    </>
  );
}

export default TeparsPost;
