import React, { useState, useEffect, useRef } from "react";
import Privacy from "./Privacy";
import SeePost from "./SeePost";
import Slider from "react-slick";
import Location from "./Location";
import EasyCrop from "./EasyCrop";
import ShowDots from "./ShowDots";
import TrimVideo from "./TrimVideo";
import ShareGroup from "./ShareGroup";
import Spinner from "../Common/Spinner";
import DiscardPost from "./DiscardPost";
import ExceptContact from "./ExceptContact";
import OnlyShareWith from "./OnlyShareWith";
import AddTeparsMedia from "./AddTeparsMedia";
import PostVisibility from "./PostVisibility";
import { IMAGE, VIDEO } from "../../Utils/enums";
import { getMediaType } from "../../Utils/helpers";
import { useSelector, useDispatch } from "react-redux";
import { setAwsUploadedMedia, setVideoCurrentTime } from "../../Redux/actions";
import { useSwiperSlider } from "../../Utils/helpers";
import "../../assets/css/tepars.scss";

function UploadTepears({
  onClose,
  setUploadMedia,
  getDescription,
  setGetDescription,
  getVisibility,
  setGetVisibility,
  privacy,
  setPrivacy,
  getRePosting,
  setGetRePosting,
  getOffComment,
  setGetOffComment,
  getCanReplyPost,
  setGetCanReplyPost,
  sharedGroups,
  setSharedGroups,
  getSelectedLocation,
  setGetSelectedLocation,
  exceptContact,
  setExceptContact,
  onlyShareContact,
  setOnlyShareContact,
  setCropAspectForUpload,
  HandlePostUpload,
}) {
  const dispatch = useDispatch();

  const videoRef = useRef(null);
  const sliderRef = useRef(null);
  const onlyVideoRef = useRef(null);
  const fileInputRef = useRef(null);

  const sliderWrapperRef = useRef(null);

  const theme = useSelector((state) => state.theme);

  const [zooms, setZooms] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasTrim, setHasTrim] = useState(false);
  const [onlyVideo, setOnlyVideo] = useState([]);
  const [hasVideo, setHasVideo] = useState(false);
  const [cropAspect, setCropAspect] = useState(1);
  const [openNext, setOpenNext] = useState(false);
  const [mediaIndex, setMediaIndex] = useState(0);
  const [hasImage, setHasImage] = useState(false);
  const [saveTrim, setSaveTrim] = useState(false);
  const [leftClick, setLeftClick] = useState(false);
  const [rightClick, setRightClick] = useState(false);
  const [cropedimages, setCropdeImages] = useState([]);
  const [openLastBack, setOpenLastBack] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [getCurrentIndex, setGetCurrentIndex] = useState(0);
  const [mediaIndexFinal, setMediaIndexFinal] = useState(0);
  const [getContactArray, setGetContactArray] = useState([]);
  const [cropCtaVisible, setCropCtaVisible] = useState(false);
  const [zoomCtaVisible, setZoomCtaVisible] = useState(false);
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const [imagesList, setImagesList] = useState(setUploadMedia);
  const [sharedGroupsList, setSharedGroupsList] = useState([]);
  const [getContactArrayOnly, setGetContactArrayOnly] = useState([]);
  const [handlePrivacyOptions, setHandlePrivacyOptions] = useState("Privacy");
  const [handleMediaType, setHandleMediaType] = useState("");
  const [selectedTeparsImage, setSelectedTeparsImage] = useState(imagesList[0]);
  const [isImgOrVideo, setIsImgOrVideo] = useState(
    selectedTeparsImage?.type.split("/")[0]
  );

  var countGetContactExcept = exceptContact?.length ?? 0;
  var countGetOnlyShareWith = onlyShareContact?.length ?? 0;

  const handleTimeUpdate = () => {
    dispatch(setVideoCurrentTime(onlyVideoRef.current?.currentTime));
  };

  useEffect(() => {
    setHasImage(
      imagesList?.some((item) => item?.type?.split("/")[0] === "image") || false
    );
    setHasVideo(
      imagesList?.some((item) => item?.type?.split("/")[0] === "video") || false
    );
    const videoItems =
      imagesList?.filter((item) => item?.type?.split("/")[0] === "video") || [];
    setOnlyVideo(videoItems);

    if (imagesList?.length > 1) {
      setHandleMediaType("multiple");
      setCropAspect(1);
      setCropAspectForUpload(1);
    }

    if (imagesList?.length === 1) {
      setHandleMediaType("single");
      if (imagesList[0].type.split("/")[0] === "video") {
        const file = imagesList[0];
        getVideoDimensions(file);
      }
      if (isImgOrVideo === "video") {
        setIsImgOrVideo(imagesList[0].type.split("/")[0]);
      }
    }
  }, [imagesList]);

  const getVideoDimensions = (file) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(file);

    video.onloadedmetadata = () => {
      const width = video.videoWidth;
      const height = video.videoHeight;

      if (width === height) {
        setCropAspectForUpload(1);
      } else if (width < height) {
        setCropAspectForUpload(5 / 4);
      } else if (width > height) {
        setCropAspectForUpload(height / width);
      }
      URL.revokeObjectURL(video.src); // Clean up
    };
  };

  useEffect(() => {
    setHasTrim(false);
  }, [!hasVideo]);

  // when Video || IMG & VIDEO =  crop -> trim video then buffet then open pricacy
  const handleTrimed = () => {
    setMediaIndex(0);
    setMediaIndexFinal(0);
    setHasVideo(true);
    setHasTrim(true);
    setLoading(false);
  };

  const loadBackToCrop = (loading) => {
    if (!loading) {
      var timer = setTimeout(() => {
        setLoading(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  };

  useEffect(() => {
    loadBackToCrop(loading);
  }, [loading]);

  const toggleCropCta = () => {
    setCropCtaVisible(!cropCtaVisible);
    setZoomCtaVisible(false);
  };

  const toggleZoomCta = () => {
    setZoomCtaVisible(!zoomCtaVisible);
    setCropCtaVisible(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const closePopup = document.querySelector(".crop-wrapper");
      if (closePopup && !closePopup.contains(event.target)) {
        setCropCtaVisible(false);
      }

      const closePopup2 = document.querySelector(".zoom-wrapper");
      if (closePopup2 && !closePopup2.contains(event.target)) {
        setZoomCtaVisible(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelected = async (e) => {
    e.preventDefault();
    let selectedFiles = [...e.target.files].slice(0, 10 - imagesList?.length);
    var imageType = getMediaType(selectedFiles);
    if (imageType === "Image") {
      if (selectedFiles?.length) {
        var processedImages = [];
        const images = Object.values(selectedFiles);
        for (var i = 0; i < images.length; i++) {
          processedImages.push(images[i]);
        }
        const concatImages = [...imagesList, ...processedImages];
        setImagesList(concatImages);
        fileInputRef.current.value = "";
      }
    } else if (imageType === "Video") {
      if (selectedFiles?.length) {
        var multipleData = [];
        const images = Object.values(selectedFiles);
        for (var j = 0; j < images.length; j++) {
          multipleData.push(images[j]);
        }
        var concatImages = [];
        concatImages.push(...imagesList, ...multipleData);
        setImagesList(concatImages);
        fileInputRef.current.value = "";
      }
    }
  };

  const handleCropPlayPauseToggle = (e, index) => {
    const videoElement = e.target;
    if (videoElement.paused) {
      // Pause all other videos
      imagesList.forEach((_, i) => {
        if (i !== index) {
          const otherVideo = document.querySelector(`video[data-index="${i}"]`);
          if (otherVideo) {
            otherVideo.pause();
          }
        }
      });
      videoElement.play();
    } else {
      videoElement.pause();
    }
  };

  const HandleBackClick = () => {
    switch (true) {
      case handlePrivacyOptions === "ContactExcept" ||
        handlePrivacyOptions === "OnlyShareWith":
        setOpenNext(true);
        setHandlePrivacyOptions("SeePost");
        break;
      case handlePrivacyOptions === "SeePost" ||
        handlePrivacyOptions === "Location" ||
        handlePrivacyOptions === "PostVisibility" ||
        handlePrivacyOptions === "ShareGroup":
        setOpenNext(true);
        setHandlePrivacyOptions("Privacy");
        break;
      case openNext && hasImage && !hasVideo && !hasTrim:
        setMediaIndex(0);
        setMediaIndexFinal(0);
        setOpenNext(false);
        setLoading(false);
        setCropdeImages([]);
        break;
      default:
        onClose(false);
        break;
    }
  };

  // back 4
  const HandleBackTrimToCropClick = () => {
    if (
      handlePrivacyOptions === "ContactExcept" ||
      handlePrivacyOptions === "OnlyShareWith"
    ) {
      setOpenNext(true);
      setHandlePrivacyOptions("SeePost");
    }
    if (
      handlePrivacyOptions === "SeePost" ||
      handlePrivacyOptions === "Location" ||
      handlePrivacyOptions === "PostVisibility" ||
      handlePrivacyOptions === "ShareGroup"
    ) {
      setOpenNext(true);
      setHandlePrivacyOptions("Privacy");
    }
    // IMG = crop <- post
    if (openNext && hasImage && !hasVideo && !hasTrim) {
      setOpenNext(false);
      setHasVideo(false);
      setLoading(false);
    }
    // IMG & VIDEO = crop <- trim
    if (openNext && hasImage && hasVideo && !hasTrim) {
      setMediaIndex(0);
      setMediaIndexFinal(0);
      setOpenNext(false);
      setLoading(false);
    }
    // IMG & VIDEO = crop -> trim <- post
    if (
      openNext &&
      hasImage &&
      hasVideo &&
      hasTrim &&
      handlePrivacyOptions === "Privacy"
    ) {
      setMediaIndex(0);
      setMediaIndexFinal(0);
      setHasTrim(false);
      setLoading(false);
      setSaveTrim(false);
    }
    // Video = crop -> trim <- post
    if (
      openNext &&
      !hasImage &&
      hasVideo &&
      hasTrim &&
      handlePrivacyOptions === "Privacy"
    ) {
      setMediaIndex(0);
      setMediaIndexFinal(0);
      setHasTrim(false);
      setLoading(false);
      setSaveTrim(false);
    }
    // Video = crop <- trim
    if (openNext && !hasImage && hasVideo && !hasTrim) {
      setMediaIndex(0);
      setMediaIndexFinal(0);
      setOpenNext(false);
      setLoading(false);
      setSaveTrim(false);
    }
  };

  // Next-1 for direct privacy
  const HandleNextClick = () => {
    if (!openNext) {
      setOpenNext(true);
      setLoading(false);
    }
  };

  // Next-2 for trim
  const HandleHasVideoTrimNextClick = () => {
    if (!openNext && hasVideo) {
      setMediaIndex(0);
      setMediaIndexFinal(0);
      setOpenNext(true);
      setLoading(false);
    }
  };

  // Trim
  const HandleTrimClick = () => {
    if (hasVideo) {
      setSaveTrim(true);
    }
  };

  const HandleDoneClick = () => {
    if (!openNext || handlePrivacyOptions === "ContactExcept") {
      setHandlePrivacyOptions("SeePost");
    } else if (!openNext || handlePrivacyOptions === "OnlyShareWith") {
      setHandlePrivacyOptions("SeePost");
    } else {
      setHandlePrivacyOptions("Privacy");
    }
  };

  const handleDiscart = () => {
    setImages();
    setImagesList();
    setExceptContact([]);
    setOnlyShareContact([]);
    setSharedGroups([]);
    onClose(false);
  };

  const HandleLastBackClick = () => {
    setOpenLastBack(!openLastBack);
    // setHandlePrivacyOptions(null);
  };

  let width = "43.75rem";
  let height = "85vh";

  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  if (viewportWidth <= 1024 && viewportHeight <= 1380) {
    width = openNext ? "40rem" : "30rem";
    height = "30rem";
  } else if (viewportWidth <= 1440 || viewportWidth < 1600) {
    width = openNext ? "60rem" : "43.75rem";
  } else if (viewportWidth > 1600) {
    width = openNext ? "60rem" : "43.75rem";
    height = "40rem";
  }

  // image crop fnc
  const handleImagesUpload = async (files) => {
    let UploadImages = [...files];
    let imagesUrl = [];
    UploadImages.map((image, index) => {
      imagesUrl.push(
        URL.createObjectURL(new Blob([image], { type: image.type }))
      );
      setZooms((zoom) => [...zoom, 1]);
      return imagesUrl;
    });

    setImages(imagesUrl);
  };

  const setZoomValue = (value) => {
    var getCrop = [...zooms];
    getCrop[mediaIndex] = value;
    setZooms(getCrop);
  };

  useEffect(() => {
    if (imagesList.length) {
      handleImagesUpload(imagesList);
    }
    return () => {};
  }, [imagesList]);

  let setCropImages = async (url, i) => {
    const { v4 } = require("uuid");
    const uuid = v4();
    let blob = await fetch(url).then((r) => r.blob());
    let oldArray = cropedimages;

    const lastModified = new Date().getTime();
    const newFileName = uuid + "." + imagesList[i]?.type.split("/")[1];

    const newFile = new File([blob], newFileName, {
      lastModified: lastModified,
      type: imagesList[i].type,
      size: imagesList[i].size,
      webkitRelativePath: "",
      sname: imagesList[i].name,
    });
    oldArray[i] = newFile;
    setCropdeImages(oldArray);
    dispatch(setAwsUploadedMedia(oldArray));
  };

  const goToSlide = (index) => {
    sliderRef?.current?.slickGoTo(index);
  };

  // first video open in img crop time
  const handlePlayPauseToggle = (entry) => {
    const video = entry.target;
    const index = parseInt(video.dataset.index, 10);

    if (index === activeVideoIndex) {
      if (video.paused) {
        if (entry.isIntersecting) {
          video.play();
        }
      }
    } else {
      video.pause();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      function (enteries) {
        enteries.forEach(handlePlayPauseToggle);
      },
      { threshold: 0.5 }
    );
    const cropMedia = document.querySelectorAll("#crop");
    cropMedia.forEach((media) => {
      observer.observe(media);
    });
    return () => observer.disconnect();
  }, [loading, mediaIndex, imagesList, activeVideoIndex]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach(handlePlayPauseToggle);
      },
      { threshold: 0.5 }
    );
    const cropMedia = document.querySelectorAll("#Trim");
    cropMedia.forEach((media) => {
      observer.observe(media);
    });
    return () => observer.disconnect();
  }, [loading, mediaIndex, cropedimages, activeVideoIndex]);

  useEffect(() => {
    const overlayOpenEvent = new Event("overlayOpen");
    document.dispatchEvent(overlayOpenEvent);
  }, []);

  useSwiperSlider(sliderRef, sliderWrapperRef, imagesList.length);

  return (
    <div className="uploadTepars">
      <div className={`uploadTepars-popup `} style={{ width, height }}>
        {/* header */}
        <div className="header">
          {/* [ Back | Back ] : [ Close | Back ] */}
          {openNext ? (
            <>
              {/* Back 1 | Back */}
              {openNext && hasImage && !hasVideo && !hasTrim ? (
                <div
                  className="btn btn-back"
                  onClick={HandleBackClick}
                  tabIndex="0"
                  aria-label="Back"
                  role="button"
                >
                  <svg width="20" height="20" viewBox="0 0 26 26" fill="none">
                    <title>Back 1</title>
                    <path
                      d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                      stroke={
                        theme === "Light"
                          ? "#1891a2"
                          : theme === "Dark"
                          ? "#ffffff"
                          : window.matchMedia("(prefers-color-scheme: light)")
                              .matches
                          ? "#1891a2"
                          : "#ffffff"
                      }
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              ) : (
                <div
                  className="btn btn-back"
                  onClick={HandleBackTrimToCropClick}
                  tabIndex="0"
                  aria-label="Back"
                  role="button"
                  style={{ visibility: isProcessing && "hidden" }}
                >
                  <svg width="20" height="20" viewBox="0 0 26 26" fill="none">
                    <title>Back</title>
                    <path
                      d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                      stroke={
                        theme === "Light"
                          ? "#1891a2"
                          : theme === "Dark"
                          ? "#ffffff"
                          : window.matchMedia("(prefers-color-scheme: light)")
                              .matches
                          ? "#1891a2"
                          : "#ffffff"
                      }
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              )}
            </>
          ) : (
            <div
              className="btn btn-back"
              onClick={HandleLastBackClick}
              aria-label={openNext ? "Back" : "Close"}
              role="button"
              tabIndex="0"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  HandleLastBackClick();
                }
              }}
            >
              {/* Close | Back */}
              {!openNext ? (
                <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
                  <title>Close</title>
                  <g id="Frame 2595">
                    <path
                      id="Shape"
                      d="M24.072 5.69684L24.1924 5.80567C24.7728 6.3861 24.8091 7.30462 24.3012 7.92731L24.1924 8.04771L17.242 14.9981L24.1924 21.9484C24.7728 22.5288 24.8091 23.4473 24.3012 24.07L24.1924 24.1904C23.612 24.7709 22.6934 24.8072 22.0707 24.2993L21.9503 24.1904L15 17.2401L8.04966 24.1904C7.46923 24.7709 6.55071 24.8072 5.92802 24.2993L5.80761 24.1904C5.22718 23.61 5.19091 22.6915 5.69878 22.0688L5.80761 21.9484L12.758 14.9981L5.80761 8.04771C5.22718 7.46729 5.19091 6.54877 5.69878 5.92608L5.80761 5.80567C6.38804 5.22524 7.30656 5.18896 7.92925 5.69684L8.04966 5.80567L15 12.756L21.9503 5.80567C22.5308 5.22524 23.4493 5.18896 24.072 5.69684L24.1924 5.80567L24.072 5.69684Z"
                      fill={
                        theme === "Light"
                          ? "#1891a2"
                          : theme === "Dark"
                          ? "#ffffff"
                          : window.matchMedia("(prefers-color-scheme: light)")
                              .matches
                          ? "#1891a2"
                          : "#ffffff"
                      }
                    />
                  </g>
                </svg>
              ) : (
                <svg width="20" height="20" viewBox="0 0 26 26" fill="none">
                  <title>Back</title>
                  <path
                    d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                    stroke={
                      theme === "Light"
                        ? "#1891a2"
                        : theme === "Dark"
                        ? "#ffffff"
                        : window.matchMedia("(prefers-color-scheme: light)")
                            .matches
                        ? "#1891a2"
                        : "#ffffff"
                    }
                    stroke-width="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
          )}

          {/* Middle Title Create new post | Crop */}
          <div className="heading">
            <h1 className="title">
              {openNext
                ? hasVideo && !hasTrim
                  ? "Edit"
                  : "Create new post"
                : "Crop"}
            </h1>
          </div>

          {/* Next 1 = for go to direct Privacy  */}
          {!openNext && !hasVideo && (
            <div className="next" onClick={HandleNextClick} tabIndex="0">
              <span>Next</span>
            </div>
          )}
          {/* Next 2 = for trim */}
          {!openNext && hasVideo && (
            <div
              className="next"
              onClick={HandleHasVideoTrimNextClick}
              tabIndex="0"
            >
              <span>Next</span>
            </div>
          )}

          {/* Done 1*/}
          {[
            "SeePost",
            "Location",
            "OnlyShareWith",
            "ShareGroup",
            "PostVisibility",
            "ContactExcept",
          ].includes(handlePrivacyOptions) && (
            <div className="next" onClick={HandleDoneClick} tabIndex="0">
              <span>Done</span>
            </div>
          )}

          {/* Trim, Post */}
          {openNext &&
            handlePrivacyOptions === "Privacy" &&
            ((openNext && hasImage && hasVideo && !hasTrim) ||
            (openNext && !hasImage && hasVideo && !hasTrim) ? (
              isProcessing ? (
                <div style={{ margin: "auto", width: "28px", height: "20px" }}>
                  <Spinner triming="1.5rem" />
                </div>
              ) : (
                <div
                  className="next"
                  onClick={HandleTrimClick}
                  tabIndex="0"
                  style={{ visibility: !loading && "hidden" }}
                >
                  <span>Trim</span>
                </div>
              )
            ) : (
              <div className="next" onClick={HandlePostUpload} tabIndex="0">
                <span>Post</span>
              </div>
            ))}
        </div>

        <main className="container" style={{ display: openNext ? "flex" : "" }}>
          {/* Image Container */}
          <div className="upload-image-container" ref={sliderWrapperRef}>
            {/* Create and Crop post image and video --------------------*/}
            {!openNext &&
              setUploadMedia &&
              (loading ? (
                <Slider
                  ref={sliderRef}
                  {...{
                    infinite: false,
                    speed: 500,
                    slidesToShow: 1,
                    swipe: false,
                    afterChange: (currentSlide) => {
                      setSelectedTeparsImage(imagesList[currentSlide]);
                      setMediaIndex(currentSlide);
                      setIsImgOrVideo(
                        imagesList[currentSlide]?.type.split("/")[0]
                      );
                      setActiveVideoIndex(currentSlide);
                    },
                  }}
                >
                  {images?.map((media, index) => {
                    if (getMediaType([imagesList[index]]) === VIDEO) {
                      setCropImages(media, index);
                    }
                    const mediaType = getMediaType([imagesList[index]]);
                    if (mediaType === VIDEO) {
                      return (
                        <div className="upload-image" key={index}>
                          <video
                            id="crop"
                            className="upload-video"
                            src={media}
                            ref={videoRef}
                            autoPlay={index === activeVideoIndex}
                            controls={false}
                            width="100%"
                            loop
                            data-index={index}
                            onClick={(e) => handleCropPlayPauseToggle(e, index)}
                            style={{
                              background:
                                theme === "Light" ? "#7F7F7F" : "#2e3038",
                            }}
                          />
                        </div>
                      );
                    } else if (mediaType === IMAGE) {
                      return (
                        <div className="upload-image" gitkey={index}>
                          <EasyCrop
                            image={media}
                            aspect={cropAspect}
                            type="image"
                            setCropImages={setCropImages}
                            index={index}
                            zooms={zooms[index]}
                            setZoomValue={setZoomValue}
                          />
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Slider>
              ) : (
                <div style={{ margin: "auto" }}>
                  <Spinner />
                </div>
              ))}

            {/* IMG = Croped only IMG show slider in privacy  */}
            {openNext &&
              hasImage &&
              !hasVideo &&
              !hasTrim &&
              setUploadMedia &&
              (loading ? (
                <Slider
                  ref={sliderRef}
                  {...{
                    infinite: false,
                    speed: 500,
                    slidesToShow: 1,
                    swipe: false,
                    afterChange: (currentSlide) => {
                      setMediaIndexFinal(currentSlide);
                      setMediaIndex(currentSlide);
                    },
                  }}
                >
                  {cropedimages?.map((media, index) => {
                    return getMediaType([cropedimages[index]]) === IMAGE ? (
                      <div className="next-img" key={index}>
                        <div
                          key={index}
                          className="upload-image"
                          style={{
                            backgroundImage: `url(${URL.createObjectURL(
                              new Blob([media], { type: media.type })
                            )})`,
                          }}
                          ref={videoRef}
                          // autoplay
                          controls={false}
                          width="100%"
                          height="100%"
                        />
                      </div>
                    ) : getMediaType([cropedimages[index]]) === VIDEO ? (
                      <div className="upload-image">
                        <video
                          className="upload-video"
                          src={URL.createObjectURL(
                            new Blob([media], { type: media.type })
                          )}
                          ref={videoRef}
                          autoplay
                          controls={false}
                          width="100%"
                          height="100%"
                          onClick={(e) => handleCropPlayPauseToggle(e, index)}
                        />
                      </div>
                    ) : null;
                  })}
                </Slider>
              ) : (
                <div style={{ margin: "auto" }}>
                  <Spinner />
                </div>
              ))}

            {/* IMG & Video = in trim only video [ Slider ] ------------------ */}
            {openNext &&
              hasImage &&
              hasVideo &&
              !hasTrim &&
              setUploadMedia &&
              (loading ? (
                <Slider
                  ref={sliderRef}
                  {...{
                    infinite: false,
                    speed: 500,
                    slidesToShow: 1,
                    swipe: false,
                    afterChange: (currentSlide) => {
                      setMediaIndexFinal(currentSlide);
                      setMediaIndex(currentSlide);
                      const currentVideo = document.querySelector(
                        `video[data-index="${currentSlide}"]`
                      );
                      if (currentVideo) {
                        if (!currentVideo.paused) {
                          currentVideo.play();
                        }
                      }
                    },
                  }}
                >
                  {onlyVideo?.map((media, index) => {
                    return (
                      getMediaType([onlyVideo[index]]) === VIDEO && (
                        <div className="upload-image">
                          <video
                            key={index}
                            ref={
                              index === mediaIndexFinal ? onlyVideoRef : null
                            }
                            data-index={index}
                            autoload="metadata"
                            className="upload-video"
                            src={URL.createObjectURL(
                              new Blob([media], { type: media.type })
                            )}
                            autoPlay={index === mediaIndexFinal}
                            width="100%"
                            height="100%"
                            onClick={(e) => handleCropPlayPauseToggle(e, index)}
                            onTimeUpdate={handleTimeUpdate}
                          />
                        </div>
                      )
                    );
                  })}
                </Slider>
              ) : (
                <div style={{ margin: "auto" }}>
                  <Spinner />
                </div>
              ))}

            {/* IMG & video = post [crop image, trim video] from privacy [ Slider ] */}
            {openNext &&
              hasImage &&
              hasVideo &&
              hasTrim &&
              setUploadMedia &&
              (loading ? (
                <Slider
                  ref={sliderRef}
                  {...{
                    infinite: false,
                    speed: 500,
                    slidesToShow: 1,
                    swipe: false,
                    afterChange: (currentSlide) => {
                      setMediaIndexFinal(currentSlide);
                      setMediaIndex(currentSlide);
                      setActiveVideoIndex(currentSlide);
                    },
                  }}
                >
                  {cropedimages?.map((media, index) => {
                    return getMediaType([cropedimages[index]]) === IMAGE ? (
                      <div className="next-img">
                        <div
                          className="upload-image"
                          style={{
                            backgroundImage: `url(${URL.createObjectURL(
                              new Blob([media], { type: media.type })
                            )})`,
                          }}
                          ref={videoRef}
                          autoplay
                          controls={false}
                          width="100%"
                          height="100%"
                        />
                      </div>
                    ) : getMediaType([cropedimages[index]]) === VIDEO ? (
                      <div className="upload-image">
                        <video
                          id="Trim"
                          className="upload-video"
                          src={URL.createObjectURL(
                            new Blob([media], { type: media.type })
                          )}
                          ref={videoRef}
                          autoPlay={index === activeVideoIndex}
                          controls={false}
                          width="100%"
                          height="100%"
                          loop
                          data-index={index}
                          onClick={(e) => handleCropPlayPauseToggle(e, index)}
                        />
                      </div>
                    ) : null;
                  })}
                </Slider>
              ) : (
                <div style={{ margin: "auto" }}>
                  <Spinner />
                </div>
              ))}

            {/* only video = trim video [ Slider ]------------------------*/}
            {openNext &&
              !hasImage &&
              hasVideo &&
              !hasTrim &&
              setUploadMedia &&
              (loading ? (
                <Slider
                  ref={sliderRef}
                  {...{
                    infinite: false,
                    speed: 500,
                    slidesToShow: 1,
                    swipe: false,
                    afterChange: (currentSlide) => {
                      setMediaIndexFinal(currentSlide);
                      setMediaIndex(currentSlide);
                      const currentVideo = document.querySelector(
                        `video[data-index="${currentSlide}"]`
                      );
                      if (currentVideo) {
                        if (!currentVideo.paused) {
                          currentVideo.play();
                        }
                      }
                    },
                  }}
                >
                  {imagesList?.map((media, index) => {
                    return (
                      getMediaType([imagesList[index]]) === VIDEO && (
                        <div className="upload-image">
                          <video
                            key={index}
                            ref={
                              index === mediaIndexFinal ? onlyVideoRef : null
                            }
                            data-index={index}
                            autoload="metadata"
                            className="upload-video"
                            src={URL.createObjectURL(
                              new Blob([media], { type: media.type })
                            )}
                            autoPlay={index === mediaIndexFinal}
                            width="100%"
                            height="100%"
                            onClick={(e) => handleCropPlayPauseToggle(e, index)}
                            onTimeUpdate={handleTimeUpdate}
                          />
                        </div>
                      )
                    );
                  })}
                </Slider>
              ) : (
                <div style={{ margin: "auto" }}>
                  <Spinner />
                </div>
              ))}

            {/* only video = Trim [trimed video] for privacy [ Slider ] */}
            {openNext &&
              !hasImage &&
              hasVideo &&
              hasTrim &&
              setUploadMedia &&
              (loading ? (
                <Slider
                  ref={sliderRef}
                  {...{
                    infinite: false,
                    speed: 500,
                    slidesToShow: 1,
                    swipe: false,
                    afterChange: (currentSlide) => {
                      setMediaIndexFinal(currentSlide);
                      setMediaIndex(currentSlide);
                      setIsImgOrVideo(
                        cropedimages[currentSlide]?.type.split("/")[0]
                      );
                      setActiveVideoIndex(currentSlide);
                    },
                  }}
                >
                  {cropedimages?.map((media, index) => {
                    return (
                      <div className="upload-image">
                        <video
                          id="Trim"
                          className="upload-video"
                          src={URL.createObjectURL(
                            new Blob([media], { type: media.type })
                          )}
                          ref={videoRef}
                          autoPlay={index === activeVideoIndex}
                          controls={false}
                          width="100%"
                          height="100%"
                          loop
                          data-index={index}
                          onClick={(e) => handleCropPlayPauseToggle(e, index)}
                        />
                      </div>
                    );
                  })}
                </Slider>
              ) : (
                <div style={{ margin: "auto" }}>
                  <Spinner />
                </div>
              ))}

            {/* Crop | Zo0m | Add Img */}
            {!openNext && (
              <div className="cta-wrapper">
                <div className="crop-zoom">
                  {/* Crop */}
                  <div
                    className={`crop-wrapper ${
                      isImgOrVideo === "video" && "wrapper-none"
                    } ${handleMediaType === "multiple" && "wrapper-none"}`}
                  >
                    <button className="btn btn-crop" onClick={toggleCropCta}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <title>Select Crop</title>
                        <g id="outputsvg">
                          <g id="l6UHXhJ0QsN8tQtHo1QAsIL">
                            <g id="Group">
                              <path
                                id="p17o8ZGl1y"
                                d="M2.16407 18.2266C1.94141 18.125 1.83204 18 1.73829 17.7617C1.6836 17.6172 1.6797 17.2188 1.68751 14.1016C1.69923 10.2539 1.6836 10.5156 1.94141 10.2578C2.2422 9.95312 2.75782 9.95312 3.0586 10.2578C3.31641 10.5156 3.30079 10.3008 3.31251 13.6445L3.32032 16.6797H6.28126C8.08204 16.6797 9.28907 16.6953 9.3711 16.7188C9.5547 16.7695 9.73438 16.9023 9.84766 17.0625C9.92579 17.1797 9.94141 17.25 9.94141 17.5C9.94141 17.75 9.92579 17.8203 9.84766 17.9375C9.73438 18.0977 9.5547 18.2305 9.3711 18.2812C9.28907 18.3047 7.91407 18.3203 5.80079 18.3203H2.36329L2.16407 18.2266Z"
                                fill="white"
                              />
                              <path
                                id="peuKpdCwE"
                                d="M17.168 9.91016C17.0273 9.84375 16.8516 9.67188 16.7656 9.51172C16.707 9.40625 16.6992 9.09766 16.6875 6.35938L16.6797 3.32031H13.7188C11.918 3.32031 10.7109 3.30469 10.6289 3.28125C10.4453 3.23047 10.2656 3.09766 10.1523 2.9375C10.0742 2.82031 10.0586 2.75 10.0586 2.5C10.0586 2.25 10.0742 2.17969 10.1523 2.0625C10.2656 1.90234 10.4453 1.76953 10.6289 1.71875C10.7109 1.69531 12.0859 1.67969 14.1992 1.67969H17.6367L17.832 1.77344C18.0625 1.87891 18.1328 1.95312 18.2383 2.1875C18.3164 2.35938 18.3203 2.42969 18.3203 5.80078C18.3203 7.91406 18.3047 9.28906 18.2812 9.37109C18.2305 9.55469 18.0977 9.73438 17.9375 9.84766C17.7734 9.96094 17.3555 9.99219 17.168 9.91016Z"
                                fill="white"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </button>

                    {cropCtaVisible && (
                      <div className="crop-cta">
                        {/* Square */}
                        <div
                          className={`cta square ${
                            cropAspect === 1 ? "active" : ""
                          }`}
                          onClick={() => {
                            setCropAspect(1);
                            setCropAspectForUpload(1);
                          }}
                        >
                          <span className="text">1:1</span>
                          <div className="scta-crop-image">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <title>Crop square</title>
                              <g id="solar:gallery-outline">
                                <rect
                                  id="Rectangle 955971"
                                  x="1.5"
                                  y="1.5"
                                  width="17"
                                  height="17"
                                  rx="4.5"
                                  stroke="white"
                                />
                              </g>
                            </svg>
                          </div>
                        </div>

                        {/* Portrait */}
                        <div
                          className={`cta portrait  ${
                            cropAspect === 0.8 ? "active" : ""
                          }`}
                          onClick={() => {
                            setCropAspect(4 / 5);
                            setCropAspectForUpload(5 / 4);
                          }}
                        >
                          <span className="text">4:5</span>
                          <div className="cta-crop-image">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <title>Crop portrait</title>
                              <g id="solar:gallery-outline">
                                <rect
                                  id="Rectangle 955972"
                                  x="4.5"
                                  y="1.5"
                                  width="11"
                                  height="17"
                                  rx="2.5"
                                  stroke="white"
                                />
                              </g>
                            </svg>
                          </div>
                        </div>

                        {/* Landscape */}
                        <div
                          className={`cta landscape  ${
                            cropAspect === 1.7777777777777777 ? "active" : ""
                          }`}
                          onClick={() => {
                            setCropAspect(16 / 9);
                            setCropAspectForUpload(9 / 16);
                          }}
                        >
                          <span className="text">16:9</span>
                          <div className="cta-crop-image">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <title>Crop landscape</title>
                              <g id="solar:gallery-outline">
                                <rect
                                  id="Rectangle 955973"
                                  x="1.5"
                                  y="4.5"
                                  width="17"
                                  height="11"
                                  rx="2.5"
                                  stroke="white"
                                />
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Zoom */}
                  <div
                    className={`zoom-wrapper ${
                      isImgOrVideo === "video" && "wrapper-none"
                    } ${handleMediaType === "multiple" && "wrapper-none"}`}
                  >
                    <button
                      className={`btn btn-zoom ${
                        zoomCtaVisible ? "active" : ""
                      }`}
                      onClick={toggleZoomCta}
                    >
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <title>Select Zoom</title>
                        <g id="Frame" clipPath="url(#clip0_7387_18312)">
                          <path
                            id="Vector"
                            d="M24.0426 22.1534L29.7533 27.8627L27.8666 29.7494L22.1573 24.0387C20.033 25.7417 17.3906 26.6679 14.668 26.6641C8.04397 26.6641 2.66797 21.2881 2.66797 14.6641C2.66797 8.04006 8.04397 2.66406 14.668 2.66406C21.292 2.66406 26.668 8.04006 26.668 14.6641C26.6718 17.3867 25.7456 20.029 24.0426 22.1534ZM21.368 21.1641C23.0601 19.4239 24.0051 17.0913 24.0013 14.6641C24.0013 9.50673 19.824 5.33073 14.668 5.33073C9.51064 5.33073 5.33464 9.50673 5.33464 14.6641C5.33464 19.8201 9.51064 23.9974 14.668 23.9974C17.0952 24.0012 19.4278 23.0562 21.168 21.3641L21.368 21.1641Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7387_18312">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>

                    <div
                      className={`slider-wrapper ${
                        zoomCtaVisible ? "active" : ""
                      }`}
                    >
                      <input
                        className="slider"
                        dir="ltr"
                        type="range"
                        max={3}
                        min={1}
                        readOnly=""
                        step={0.001}
                        value={zooms[mediaIndex]}
                        onChange={(e) => setZoomValue(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* Add Img */}
                <div className="add-tepars-container">
                  {/* add tepars = Image and video */}
                  <div className="add-tepars">
                    <AddTeparsMedia
                      media={imagesList}
                      goToSlide={goToSlide}
                      updateMedia={setImagesList}
                      setCropdeImages={setCropdeImages}
                      getSelectedImage={setSelectedTeparsImage}
                      setSelectImageIndex={selectedTeparsImage}
                      onClose={onClose}
                      getCurrentIndex={getCurrentIndex}
                      setGetCurrentIndex={setGetCurrentIndex}
                      setRightClick={setRightClick}
                      checkRightClick={rightClick}
                      setLeftClick={setLeftClick}
                      checkLeftClick={leftClick}
                      mediaIndex={mediaIndex}
                    />

                    {imagesList?.length <= 9 && (
                      <button
                        className="btn upload"
                        onClick={(e) => handleImageClick(e)}
                      >
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                        >
                          <title>Add Media</title>
                          <path
                            d="M13.8346 0.807139L13.9992 0.798828C14.8327 0.798828 15.5182 1.43227 15.6006 2.244L15.609 2.40858V12.3891H25.5894C26.4229 12.3891 27.1084 13.0225 27.1909 13.8342L27.1992 13.9988C27.1992 14.8323 26.5658 15.5178 25.754 15.6003L25.5894 15.6086H15.609V25.5891C15.609 26.4225 14.9755 27.1081 14.1638 27.1905L13.9992 27.1988C13.1657 27.1988 12.4802 26.5654 12.3977 25.7537L12.3894 25.5891V15.6086H2.40895C1.57547 15.6086 0.889941 14.9751 0.807505 14.1634L0.799194 13.9988C0.799194 13.1653 1.43263 12.4798 2.24436 12.3974L2.40895 12.3891H12.3894V2.40858C12.3894 1.57511 13.0229 0.889575 13.8346 0.807139L13.9992 0.798828L13.8346 0.807139Z"
                            fill="#1891A2"
                          />
                        </svg>
                        <input
                          accept="image/*,video/*"
                          ref={fileInputRef}
                          type="file"
                          multiple
                          onChange={handleFileSelected}
                        />
                      </button>
                    )}
                  </div>

                  <div className="select-btns">
                    <div className="select active" tabIndex="0" />
                    <div className="select" tabIndex="0" />
                    <div className="select" tabIndex="0" />
                    <div className="select" tabIndex="0" />
                    <div className="select" tabIndex="0" />
                  </div>
                </div>

                {/* Visually hidden div for centering */}
                <div className="hidden-crop-zoom" aria-hidden="true"></div>
              </div>
            )}

            {/* Dots */}
            {openNext && (
              <div className="cta-wrapper" style={{ justifyContent: "center" }}>
                <div className="add-tepars-container">
                  <div className="add-tepars">
                    <div className="media-wrapper">
                      {(onlyVideo?.length > 1 || imagesList?.length > 1) && (
                        <ShowDots
                          media={
                            openNext && hasVideo && !hasTrim
                              ? onlyVideo
                              : imagesList
                          }
                          mediaIndexFinal={mediaIndexFinal}
                          getSelectedImage={setSelectedTeparsImage}
                          getCurrentIndex={setGetCurrentIndex}
                          setRightClick={setRightClick}
                          checkRightClick={rightClick}
                          setLeftClick={setLeftClick}
                          checkLeftClick={leftClick}
                          goToSlide={goToSlide}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="gradient" aria-hidden="true" />
          </div>

          {/*  IMG|VIDEO -> trim , VIDEO -> trim  */}
          {((openNext && hasImage && hasVideo && !hasTrim) ||
            (loading && openNext && !hasImage && hasVideo && !hasTrim)) && (
            <div className="filters">
              <TrimVideo
                onlyVideoRef={onlyVideoRef}
                imagesList={cropedimages}
                currentVideoIndex={mediaIndexFinal}
                setCropdeImages={setCropdeImages}
                saveTrim={saveTrim}
                setIsProcessing={setIsProcessing}
                handleTrimed={() => handleTrimed()}
              />
            </div>
          )}

          {/* IMG -> privacy , IMG|VIDEO -> trim -> privacy , VIDEO -> trim -> privacy */}
          {openNext &&
            handlePrivacyOptions === "Privacy" &&
            ((hasImage && !hasVideo && !hasTrim) ||
              (hasImage && hasVideo && hasTrim) ||
              (!hasImage && hasVideo && hasTrim)) && (
              <div className="filters">
                <Privacy
                  setHandlePrivacyOptions={setHandlePrivacyOptions}
                  getDescription={getDescription}
                  setGetDescription={setGetDescription}
                  privacy={privacy}
                  setPrivacy={setPrivacy}
                  getVisibility={getVisibility}
                  getRePosting={getRePosting}
                  setGetRePosting={setGetRePosting}
                  getOffComment={getOffComment}
                  setGetOffComment={setGetOffComment}
                  getCanReplyPost={getCanReplyPost}
                  setGetCanReplyPost={setGetCanReplyPost}
                  getSelectedLocation={getSelectedLocation}
                  sharedGroups={sharedGroups}
                  sharedGroupsList={sharedGroupsList}
                />
              </div>
            )}

          {/* LOCATION */}
          {handlePrivacyOptions === "Location" && (
            <div className="filters">
              <Location
                getSelectedLocation={getSelectedLocation}
                setGetSelectedLocation={setGetSelectedLocation}
                setHandlePrivacyOptions={setHandlePrivacyOptions}
              />
            </div>
          )}
          {/* SEEPOST */}
          {handlePrivacyOptions === "SeePost" && (
            <div className="filters">
              <SeePost
                privacy={privacy}
                setPrivacy={setPrivacy}
                countContactExcept={countGetContactExcept}
                countGetOnlyShareWith={countGetOnlyShareWith}
                setHandlePrivacyOptions={setHandlePrivacyOptions}
              />
            </div>
          )}
          {/* SHARECONTACT */}
          {handlePrivacyOptions === "ContactExcept" && (
            <div className="filters">
              <ExceptContact
                getContactExcept={exceptContact}
                setGetContactExcept={setExceptContact}
                getContactArray={getContactArray}
                setGetContactArray={setGetContactArray}
              />
            </div>
          )}
          {/* ONLYSHAREWITH */}
          {handlePrivacyOptions === "OnlyShareWith" && (
            <div className="filters">
              <OnlyShareWith
                getOnlyShareWith={onlyShareContact}
                setGetOnlyShareWith={setOnlyShareContact}
                getContactArray={getContactArrayOnly}
                setGetContactArray={setGetContactArrayOnly}
              />
            </div>
          )}
          {/* SHAREGROUP */}
          {handlePrivacyOptions === "ShareGroup" && (
            <div className="filters">
              <ShareGroup
                sharedGroups={sharedGroups}
                setSharedGroups={setSharedGroups}
                sharedGroupsList={sharedGroupsList}
                setSharedGroupsList={setSharedGroupsList}
              />
            </div>
          )}
          {/* POSTVISIBILITY */}
          {handlePrivacyOptions === "PostVisibility" && (
            <div className="filters">
              <PostVisibility
                getVisibility={getVisibility}
                setGetVisibility={setGetVisibility}
              />
            </div>
          )}
        </main>
      </div>
      {/* DiscardPost */}
      {openLastBack && (
        <DiscardPost
          setOpenLastBack={setOpenLastBack}
          // setTeparsDiscart={setTeparsDiscart}
          handleDiscart={() => handleDiscart()}
        />
      )}
    </div>
  );
}

export default UploadTepears;
