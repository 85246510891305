import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { resetStore, setAuthenticateSync } from "../Redux/actions";

const token = Cookies.get("token");
const axiosRequest = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });
axiosRequest.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axiosRequest.defaults.headers.common["Content-Type"] = "application/json";

axiosRequest.interceptors.response.use(
  (response) => {
    // if (response.data.status === 401) {
    //   return Promise.reject(response.data.message);
    // }
    // if (response.data.status === "500") {
    //   return Promise.reject(response.data.message);
    // }
    return response;
  },
  (error) => {
    const dispatch = useDispatch();
    if (error?.response?.data?.status === 401) {
      Cookies.remove("token");
      Cookies.remove("user_id");
      Cookies.remove("sessionId");
      localStorage.clear();
      dispatch(resetStore());
      dispatch(setAuthenticateSync("login"));
      window.location.reload();
    }
    // if (error.response.status === 500) {
    //   // Redirect to the 500 error page
    //   window.location.href = "/error/500";
    // }
    return Promise.reject(error);
  }
);

export default axiosRequest;
