import { React, useEffect, useState } from "react";
import {
  GROUP,
  RE_POSTING,
  CONTACT_ALL,
  OFF_COMMENT,
  ONLY_WITH_ME,
  CAN_REPLY_POST,
  CONTACT_EXCEPT,
  ONLY_SHARE_WITH,
} from "../../Utils/enums";
import { useSelector } from "react-redux";
import { getUserProfilePic } from "../../Utils/helpers";
import "../../assets/css/tepars.scss";

function Privacy({
  setHandlePrivacyOptions,
  getDescription,
  setGetDescription,
  privacy,
  setPrivacy,
  getVisibility,
  getRePosting,
  setGetRePosting,
  getOffComment,
  setGetOffComment,
  getCanReplyPost,
  setGetCanReplyPost,
  getSelectedLocation,
  sharedGroups,
  sharedGroupsList,
  setDescriptionEdit,
}) {
  const User = useSelector((state) => state?.selfContact);
  const togglePost = useSelector((state) => state.togglePost);
  const postDetails = useSelector((state) => state.postDetails);

  const groupCounts = sharedGroupsList?.length;

  const [emojiPopup, setemojiPopup] = useState(false);
  const [value, setValue] = useState(
    postDetails?.description ? postDetails.description : getDescription
  );

  const toggleMultiple = (val) => {
    if (val === RE_POSTING) {
      setGetRePosting(!getRePosting);
    }
    if (val === OFF_COMMENT) {
      setGetOffComment(!getOffComment);
    }
    if (val === CAN_REPLY_POST) {
      setGetCanReplyPost(!getCanReplyPost);
    }
  };

  const handleCheckmarkClick = (value) => {
    if (value === ONLY_WITH_ME) {
      setPrivacy(value);
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    if (togglePost === "EditPost") {
      setDescriptionEdit(true);
    }
  };

  useEffect(() => {
    var timer = setTimeout(() => {
      setGetDescription(value);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [value]);

  return (
    <div className="privacy">
      <div className="privacy-wrapper">
        {/* Tepars Profile */}
        <div className="tepars-profile">
          <div className={`profile-details`}>
            <div className="profile-image">
              <img src={getUserProfilePic(User?.profile)} alt="profile" />
            </div>
            <div className="profile-info">
              <span className="name">
                {User?.name}
                {getSelectedLocation && " - In " + getSelectedLocation.name}
              </span>

              {togglePost === "RePost" ? (
                ""
              ) : (
                <div
                  className="btn-location"
                  onClick={() => setHandlePrivacyOptions("Location")}
                >
                  <div className="location-icon">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g id="ion:location-sharp">
                        <path
                          id="Vector"
                          d="M10 1.25C6.54961 1.25 3.75 3.76992 3.75 6.875C3.75 11.875 10 18.75 10 18.75C10 18.75 16.25 11.875 16.25 6.875C16.25 3.76992 13.4504 1.25 10 1.25ZM10 10C9.50555 10 9.0222 9.85338 8.61107 9.57867C8.19995 9.30397 7.87952 8.91352 7.6903 8.45671C7.50108 7.99989 7.45157 7.49723 7.54804 7.01227C7.6445 6.52732 7.8826 6.08186 8.23223 5.73223C8.58186 5.3826 9.02732 5.1445 9.51227 5.04804C9.99723 4.95157 10.4999 5.00108 10.9567 5.1903C11.4135 5.37952 11.804 5.69995 12.0787 6.11107C12.3534 6.5222 12.5 7.00555 12.5 7.5C12.4993 8.16282 12.2357 8.79828 11.767 9.26697C11.2983 9.73565 10.6628 9.99928 10 10Z"
                          fill="#1891A2"
                        />
                      </g>
                    </svg>
                  </div>
                  <span className="text">Location</span>
                </div>
              )}
            </div>
          </div>

          {((togglePost === "RePost" && value) ||
            togglePost === "EditPost"|| !togglePost)  && (
            <div className="profile-caption">
              <textarea
                name="caption"
                cols="40"
                rows="3"
                placeholder="Write a caption..."
                className="caption"
                value={value}
                onChange={(e) => handleChange(e)}
                readOnly={togglePost === "RePost" || postDetails?.RepostPostId}
                maxLength={2200}
                // onKeyUp={() => {
                //   document.querySelector("textarea").contentEditable = true;
                // }}
              />

              {/* <div
              className="profile-emoji"
              onClick={() => setemojiPopup(!emojiPopup)}
            >
              💀
            </div> */}
            </div>
          )}
        </div>

        {emojiPopup ? (
          <div className="profile-emoji-popup" />
        ) : (
          <>
            {/* Who can see post? */}
            <div className="post-visible">
              <h3 className="title">Who can see your post?</h3>
              <p className="sub-info">
                Your post will be visible to Tepnot members and your groups.
              </p>
              <div className="visible-to-contacts">
                <div className="contact-select">
                  <div className="group-check">
                    <input
                      type="radio"
                      id="contact-check"
                      aria-label="Check this box"
                      checked={
                        privacy === CONTACT_ALL ||
                        privacy === CONTACT_EXCEPT ||
                        privacy === ONLY_SHARE_WITH
                      }
                    />
                    <span
                      className="checkmark"
                      onClick={() => handleCheckmarkClick(CONTACT_ALL)}
                    />
                  </div>
                  <div
                    className="contact-select-info"
                    onClick={() => setHandlePrivacyOptions("SeePost")}
                  >
                    <div
                      className="wrapper"
                      onClick={() => handleCheckmarkClick(CONTACT_ALL)}
                    >
                      <span className="title">Contacts</span>
                      <p className="sub-text">
                        {privacy === CONTACT_ALL
                          ? "My all contacts"
                          : privacy === CONTACT_EXCEPT
                          ? "My contacts excepts"
                          : privacy === ONLY_SHARE_WITH
                          ? "Only share with"
                          : "My all contacts"}
                      </p>
                    </div>
                    <div className="btn btn-more">
                      <svg
                        width="10"
                        height="16"
                        viewBox="0 0 10 16"
                        fill="none"
                      >
                        <title>More info</title>
                        <path
                          id="Vector"
                          d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                          fill="#C5C5C5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="onlyme-select">
                  <div className="group-check">
                    <input
                      type="radio"
                      id="contact-check2"
                      aria-label="Check this box"
                      checked={privacy === ONLY_WITH_ME}
                    />
                    <span
                      className="checkmark"
                      onClick={() => handleCheckmarkClick(ONLY_WITH_ME)}
                    />
                  </div>
                  <div className="onlyme-select-info">
                    <div
                      className="wrapper"
                      onClick={() => handleCheckmarkClick(ONLY_WITH_ME)}
                    >
                      <span className="title">Only me</span>
                      <p className="sub-text">Only me</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Share to group */}
            <div className="share-group-wrapper">
              <div className="share-group">
                <p className="text">Share your post on groups</p>
              </div>
              <div
                className="group-select"
                onClick={() => setHandlePrivacyOptions("ShareGroup")}
              >
                <div className="group-check">
                  <input
                    type="radio"
                    id="contact-check3"
                    aria-label="Check this box"
                    checked={sharedGroups?.length > 0}
                  />
                  <span
                    className="checkmark"
                    onClick={() => handleCheckmarkClick(GROUP)}
                  />
                </div>

                <div
                  className="group-select-info"
                  onClick={() => handleCheckmarkClick(GROUP)}
                >
                  <div className="wrapper">
                    <span className="title">Groups</span>
                    {/* <p className="sub-text">
                        {countGetGroup >= 1 && (
                          <>
                            <span>{`${groupsArray[0]?.GroupId.groupName}`}</span>
                            {groupsArray[1]?.GroupId.groupName !==
                              undefined && (
                              <span>{` and ${groupsArray[1]?.GroupId.groupName} `}</span>
                            )}
                          </>
                        )}
                        {countGetGroup >= 3 &&
                          `and ${countGetGroup - 2} others`}
                        {(countGetGroup === 0 || countGetGroup === undefined) &&
                          `Select your groups`}
                      </p> */}

                    <p className="sub-text">
                      {groupCounts >= 1 && (
                        <>
                          {`${sharedGroupsList[0]?.GroupId.groupName}`}
                          {sharedGroupsList[1]?.GroupId.groupName !==
                            undefined &&
                            ` and ${sharedGroupsList[1]?.GroupId.groupName}`}
                        </>
                      )}
                      {groupCounts >= 3 && ` and ${groupCounts - 2} others`}
                      {(groupCounts === 0 || groupCounts === undefined) &&
                        `Select your groups`}
                    </p>
                  </div>
                  <div className="btn btn-more">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <title>More info</title>
                      <path
                        id="Vector"
                        d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            {/* // visibility */}
            <div
              className="visibility-group-wrapper"
              onClick={() => setHandlePrivacyOptions("PostVisibility")}
            >
              <div className="visibility-group">
                <p className="text">Post visibility</p>
              </div>
              <div className="visibility-select">
                <div className="visibility-select-info">
                  <div className="wrapper">
                    <span className="title">Post visibility</span>
                    <p className="sub-text">
                      Post will be visible only for {getVisibility} days
                    </p>
                  </div>
                  <div className="btn btn-more">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <title>More info</title>
                      <path
                        id="Vector"
                        d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            {/* // Privacy Settings */}
            <div className="privacy-settings">
              <div className="privacy-settings-info">
                <p className="text">
                  Changes to your privacy settings won’t affect post updates
                  that you've already uploaded.
                </p>
              </div>

              <div className="privacy-cta">
                {/* Turn on Reposting */}
                <div className="reposting-settings">
                  <div className="reposting-info">
                    <span className="title">Turn on RePosting</span>
                    <p className="sub-text">Viewers can repost your post</p>
                  </div>
                  <div className="toggle-switch reposting-switch">
                    <div
                      className={`off ${getRePosting ? "toggled" : ""}`}
                      onClick={() => toggleMultiple(RE_POSTING)}
                      role="button"
                      tabIndex="0"
                      aria-label="Turn On Reposting"
                    >
                      <div className="on" />
                    </div>
                  </div>
                </div>

                {/* Turn off Comment */}
                <div className="comment-settings">
                  <div className="comment-info">
                    <span className="title">Turn off comment</span>
                    <p className="sub-text">
                      You can also change this on your post
                    </p>
                  </div>
                  <div className="toggle-switch comment-switch">
                    <div
                      className={`off ${getOffComment ? "toggled" : ""}`}
                      onClick={() => toggleMultiple(OFF_COMMENT)}
                      role="button"
                      tabIndex="0"
                      aria-label="Turn Off Comment"
                    >
                      <div className="on" />
                    </div>
                  </div>
                </div>

                {/* Can Reply Post */}
                <div className="replyPost-settings">
                  <div className="replyPost-info">
                    <span className="title">Can reply</span>
                    <p className="sub-text">Can reply</p>
                  </div>
                  <div className="toggle-switch replyPost-switch">
                    <div
                      className={`off ${getCanReplyPost ? "toggled" : ""}`}
                      onClick={() => toggleMultiple(CAN_REPLY_POST)}
                      role="button"
                      tabIndex="0"
                      aria-label="Can Reply Post"
                    >
                      <div className="on" />
                    </div>
                  </div>
                </div>

                <div className="privacy-settings-info note">
                  <p className="text">
                    Note: You can not add or edit post caption while repost
                    others post due to Tepnot policy guidelines.
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Privacy;
