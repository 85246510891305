import AWS from "aws-sdk";
import {
  AWS_ACCESS_KEY_ID,
  AWS_REGION,
  AWS_S3_BUCKET,
  AWS_SECRET_ACCESS_KEY,
  ERROR,
  IMAGE,
  SENDER_AUDIO,
  SENDER_DOCUMENT,
  SENDER_IMAGE,
  SENDER_VIDEO,
  UPLOADED,
  UPLOADFAILED,
  UPLOADING,
  VIDEO,
} from "../Utils/enums";
import {
  chatImagePath,
  chatThumbImagePath,
  chatThumbVideoImagePath,
  chatVideoPath,
  chatDocumentPath,
  chatAudioFilePath,
  postImagePath,
  postVideoPath,
  postVideoThumb,
  userProfileImage,
} from "../ServerConfiguration/awsConfiguration";
import { base64StringToFile } from "../Utils/helpers";
import { handleToast, setLoader } from "../Redux/actions";

// here, media upload into s3 bucket..
export async function uploadFile(
  file,
  mediaName,
  messageId,
  msgObj,
  docUrl,
  onMediaSend,
  setProgressCount,
  updateMediaStatus
) {
  if (!file) {
    console.error("No file selected.");
    return;
  }

  updateMediaStatus(messageId, UPLOADING);

  const UPLOAD_PATH =
    msgObj?.type === SENDER_IMAGE
      ? chatImagePath
      : msgObj?.type === SENDER_VIDEO
      ? chatVideoPath
      : msgObj?.type === SENDER_DOCUMENT
      ? chatDocumentPath
      : msgObj?.type === SENDER_AUDIO
      ? chatAudioFilePath
      : "";

  AWS.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
  });

  const keys =
    msgObj?.type === SENDER_DOCUMENT
      ? UPLOAD_PATH + docUrl
      : UPLOAD_PATH + mediaName;

  const params = { Bucket: AWS_S3_BUCKET, Key: keys, Body: file };

  const s3 = new AWS.S3({
    params: { Bucket: AWS_S3_BUCKET },
    region: AWS_REGION,
  });

  s3.putObject(params)
    .on("httpUploadProgress", (evt) => {
      // console.log(
      //   "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%",
      //   (parseInt((evt.loaded * 10) / evt.total) % 10) + 1
      // );
      setProgressCount(parseInt((evt.loaded * 100) / evt.total));
    })
    .promise()
    .then((data) => {
      updateMediaStatus(messageId, UPLOADED);
      onMediaSend(msgObj, "", "");
    })
    .catch((err) => {
      console.log(err);
      updateMediaStatus(messageId, UPLOADFAILED);
    });
}

// here, media thumb upload into s3 bucket..
export function uploadThumbFile(Thumbnail, thumbName, mediaType) {
  if (!Thumbnail) {
    console.error("No file selected.");
    return;
  }

  const UPLOAD_PATH =
    mediaType === IMAGE
      ? chatThumbImagePath
      : mediaType === VIDEO
      ? chatThumbVideoImagePath
      : "";

  const file =
    mediaType === IMAGE
      ? Thumbnail
      : mediaType === VIDEO
      ? base64StringToFile(Thumbnail, thumbName)
      : "";

  AWS.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
  });

  const keys = UPLOAD_PATH + thumbName;
  const params = { Bucket: AWS_S3_BUCKET, Key: keys, Body: file };

  const s3 = new AWS.S3({
    params: { Bucket: AWS_S3_BUCKET },
    region: AWS_REGION,
  });

  s3.putObject(params)
    .on("httpUploadProgress", (evt) => {
      console.log(
        "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
      );
    })
    .promise()
    .then((data) => {})
    .catch((err) => {
      console.log(err);
    });
}

// hear tepars media upload in to S3 bucket
export async function uploadTeparsFile(
  file,
  mediaFileType,
  mediaName,
  setProgress
) {
  if (!file) {
    console.error("No file selected.");
    return;
  }

  const UPLOAD_PATH =
    mediaFileType === IMAGE
      ? postImagePath
      : mediaFileType === VIDEO
      ? postVideoPath
      : "";

  const keys = UPLOAD_PATH + mediaName;

  AWS.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
  });
  const s3 = new AWS.S3({
    params: { Bucket: AWS_S3_BUCKET },
    region: AWS_REGION,
  });
  const params = { Bucket: AWS_S3_BUCKET, Key: keys, Body: file };

  s3.putObject(params)
    .on("httpUploadProgress", (evt) => {
      // console.log(
      //   "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
      // );
      setProgress(parseInt((evt.loaded * 100) / evt.total));
    })
    .promise()
    .then((data) => {})
    .catch((err) => {
      console.log("error", err);
    });

  return true;
}

// hear tepars media upload in to S3 bucket
export function uploadTeparsThumbFile(
  Thumbnail,
  mediaThumbNailName,
  cancelSignal
) {
  if (!Thumbnail) {
    return Promise.reject("No file selected.");
  }

  const UPLOAD_PATH = postVideoThumb;
  AWS.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
  });

  const file = base64StringToFile(Thumbnail, mediaThumbNailName);
  const keys = UPLOAD_PATH + mediaThumbNailName;

  const s3 = new AWS.S3({
    params: { Bucket: AWS_S3_BUCKET },
    region: AWS_REGION,
  });

  const params = { Bucket: AWS_S3_BUCKET, Key: keys, Body: file };

  return new Promise((resolve, reject) => {
    const upload = s3.putObject(params);

    cancelSignal.onCancel = () => {
      upload.abort();
    };

    upload
      .on("httpUploadProgress", (evt) => {
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .send((err, data) => {
        if (err) {
          if (err.code === "RequestAbortedError") {
            reject({
              code: "RequestAbortedError",
              message: "Thumbnail upload cancelled",
            });
          } else {
            reject(err);
          }
        } else {
          resolve(data);
        }
      });
  });
}

// For upload profile photo
export async function uploadProfileFile(
  file,
  updateUser,
  setProfilePhotoMenu,
  dispatch
) {
  if (!file) {
    console.error("No file selected.");
    return;
  }
  dispatch(setLoader(true));
  const { v4 } = require("uuid");
  const uuid = v4();
  const extension = file.type.split("/")[1];
  const name = uuid + "." + extension;
  AWS.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
  });

  const UPLOAD_PATH = userProfileImage;
  const keys = UPLOAD_PATH + name;
  const params = { Bucket: AWS_S3_BUCKET, Key: keys, Body: file };

  const s3 = new AWS.S3({
    params: { Bucket: AWS_S3_BUCKET },
    region: AWS_REGION,
  });

  s3.putObject(params)
    .on("httpUploadProgress", (evt) => {})
    .promise()
    .then((data) => {
      setProfilePhotoMenu(false);
      dispatch(handleToast("Setting up profile"));
      updateUser(keys);
      dispatch(setLoader(false));
    })
    .catch((err) => {
      setProfilePhotoMenu(false);
      dispatch(setLoader(false));
      dispatch(handleToast(ERROR));
    });
}
