import React from "react";

const TeparsDelete = ({ postId, confirmDelete, cancelDelete }) => {
  return (
    <div className="deleteTepars">
      <div className="dialog-info">
        <h4 className="title">Delete post?</h4>
      </div>
      <p className="description">Are you sure you want to delete this post?</p>

      <div className="discardPost-cta">
        <button className="btn btn-cancel" onClick={cancelDelete}>
          Cancel
        </button>

        <button
          className="btn btn-delete active"
          onClick={() => confirmDelete(postId)}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default TeparsDelete;
