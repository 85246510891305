import React, { useEffect, useState, useRef } from "react";
import Spinner from "../Common/Spinner";
import { ERROR } from "../../Utils/enums";
import axiosRequest from "../../Middleware/api";
import { handleToast } from "../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedUserDetails, getUserProfilePic } from "../../Utils/helpers";
import "../../assets/css/postLikes.scss";

function PostLikes({
  onClose,
  selectPostID,
  setSelectPostID,
  setSelectedMenu,
}) {
  const dispatch = useDispatch();

  const containerRef = useRef(null);

  const UserID = useSelector((state) => state.selfContact._id);

  const searchInputRef = useRef(null);
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [searchTotalPages, setSearchTotalPages] = useState(0); 
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState();
  const [appendTeps, setAppendTeps] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [likeData, setLikeData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (likeData) {
      setAppendTeps(likeData);
    }
  }, [likeData]);

  const getLikes = (postId, pageNumber, isSearch = false) => {
    setLoading(true);

    const endpoint = isSearch
      ? `post/getweblike/${postId}/?search=${searchValue}&page=${pageNumber}`
      : `post/getweblike/${postId}?page=${pageNumber}`;

    axiosRequest
      .get(endpoint, {})
      .then((res) => {
        setCount(res?.data?.totalCount);
        if (isSearch) {
          setSearchTotalPages(res?.data?.totalPages);
          setAppendTeps((prevGroups) =>
            pageNumber === 1 ? res.data.data : [...prevGroups, ...res.data.data]
          );
          setSearchPage(pageNumber + 1);
        } else {
          setTotalPages(res?.data?.totalPages);
          setLikeData((prevTepars) => [...prevTepars, ...res.data.data]);
          setPage(pageNumber + 1);
        }
        setLoading(false);
        setSearchLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setLoading(false);
        setSearchLoading(false);
      });
  };

  useEffect(() => {
    if (selectPostID) {
      getLikes(selectPostID, 1);
    }
  }, [selectPostID]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      !loading &&
      count !== appendTeps.length
    ) {
      if (searchValue) {
        if (searchPage <= searchTotalPages) { 
          getLikes(selectPostID, searchPage, true);
        }
      } else {
        if (page <= totalPages) { 
          getLikes(selectPostID, page);
        }
      }
    }
  };
  useEffect(() => {
    if (searchValue !== "") {
      var timer = setTimeout(() => {
        setSearchLoading(true);
        setSearchPage(1);
        getLikes(selectPostID, 1, true);

        if (containerRef.current) {
          containerRef.current.scrollTo(0, 0);
        }
      }, 1000);
    } else {
      if (containerRef.current) {
        containerRef.current.scrollTo(0, 0);
      }
      setSearchLoading(false);
      setAppendTeps(likeData);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  const handleSearchInputChange = (e) => {
    const keyword = e.target.value;
    setSearchValue(keyword);
    setSearchLoading(true);
  };

  const handleClearSearch = () => {
    setSearchValue("");
    setAppendTeps(likeData);
    setSearchPage(searchPage);
    setPage(page); 
    searchInputRef.current.focus();
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  };

  return (
    <div className="postLikes">
      <div className="postLikes-container">
        <div className="heading">
          <div className="hidden" aria-hidden="true" />
          <h4 className="title">Teps</h4>
          <div
            className="btn btn-close"
            aria-label="Close"
            role="button"
            tabIndex="0"
            onClick={() => {
              onClose(false);
              setSelectPostID();
            }}
          >
            <svg width="18" height="18" viewBox="0 0 25 25" fill="none">
              <title>Close</title>
              <g id="iconoir:cancel">
                <path
                  id="Vector"
                  d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                  stroke="white"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="search-bar">
          <div className="search-bar-wrapper">
            <svg width="12" height="12" viewBox="0 0 18 18" fill="none">
              <title>Search</title>
              <path
                id="Vector"
                d="M14.2049 12.952L18 16.7462L16.7462 18L12.952 14.2049C11.5402 15.3366 9.78419 15.9522 7.9748 15.9496C3.57271 15.9496 0 12.3769 0 7.9748C0 3.57271 3.57271 0 7.9748 0C12.3769 0 15.9496 3.57271 15.9496 7.9748C15.9522 9.78419 15.3366 11.5402 14.2049 12.952ZM12.4274 12.2945C13.5519 11.138 14.18 9.58786 14.1774 7.9748C14.1774 4.54741 11.4013 1.77218 7.9748 1.77218C4.54741 1.77218 1.77218 4.54741 1.77218 7.9748C1.77218 11.4013 4.54741 14.1774 7.9748 14.1774C9.58786 14.18 11.138 13.5519 12.2945 12.4274L12.4274 12.2945Z"
                fill="#999999"
              />
            </svg>
            <input
              type="search"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => handleSearchInputChange(e)}
              ref={searchInputRef}
            />
            {searchValue.length > 0 && (
              <svg
                width="12"
                height="12"
                viewBox="0 0 25 25"
                fill="none"
                style={{
                  overflow: "visible",
                  pointerEvents: "auto",
                  cursor: "pointer",
                }}
                onClick={handleClearSearch}
              >
                <title>Close</title>
                <g id="iconoir:cancel">
                  <path
                    id="Vector"
                    d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                    stroke="#999999"
                    strokeWidth="2.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            )}
          </div>
        </div>

        <div className="profiles" onScroll={handleScroll} ref={containerRef}>
          <div className="teps-header">
            <span className="teps-by">Teps by</span>
            <span className="count-teps">{count} Teps</span>
          </div>
          {searchLoading && <Spinner />}
          {loading && page === 1 ? (
            <Spinner />
          ) : appendTeps?.length > 0 ? (
            appendTeps?.map((like) => (
              <div
                className="profile"
                tabIndex="0"
                key={like._id}
                onClick={() =>
                  getSelectedUserDetails(
                    like?.userId?._id,
                    dispatch,
                    setSelectedMenu
                  )
                }
              >
                <div className="profile-image">
                  <img
                    className="profile-avatar"
                    src={getUserProfilePic(like?.userId?.profile)}
                    alt="Profile Avatar"
                  />
                </div>
                <div className="profile-info-container">
                  <div className="profile-info">
                    <p className="name">
                      {like?.userId?.contactStatus ||
                      like?.userId?._id === UserID ? (
                        <span>{like?.userId?.name}</span>
                      ) : (
                        <span>~{like?.userId?.name}</span>
                      )}
                    </p>
                    <p className="description">{like?.userId?.description}</p>
                  </div>
                </div>
              </div>
            ))
          ) : searchValue && appendTeps?.length === 0 ? (
            <div className="no-results">No teps found</div>
          ) : (
            <></>
          )}
          {!searchLoading && loading && page >= 2 && (
            <Spinner />
          )}
        </div>
      </div>
    </div>
  );
}

export default PostLikes;
