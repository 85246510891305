import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ERROR } from "../../Utils/enums";
import axiosRequest from "../../Middleware/api";
import { handleToast } from "../../Redux/actions";
import "../../assets/css/tepars.scss";
import Spinner from "../Common/Spinner";

function PostVisibility({ getVisibility, setGetVisibility }) {
  const dispatch = useDispatch();

  const [days, setDays] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCheckmarkClick = (val) => {
    setGetVisibility(val);
  };

  const fetchVisibilityDays = () => {
    setLoading(true);
    axiosRequest
      .get("user/tag/days")
      .then((res) => {
        setDays(res?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchVisibilityDays();
  }, []);

  return (
    <div className="postVisibility">
      <h3 className="heading">Post visibility</h3>

      <div className="visibility-cta">
        {loading ? (
          <Spinner />
        ) : (
          days?.map((day) => (
            <div className="month" onClick={() => handleCheckmarkClick(day)}>
              <div className="group-check">
                <input
                  type="radio"
                  id="contact-check"
                  aria-label="Check this box"
                  checked={getVisibility === day}
                />
                <span className="checkmark" />
              </div>
              <span className="text">{day} Days</span>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default PostVisibility;
