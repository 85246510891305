import React, { useState, useEffect } from "react";
import axiosRequest from "../../Middleware/api";
import { ERROR } from "../../Utils/enums";
import { handleToast } from "../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfilePic } from "../../Utils/helpers";
import {
  post_image__url,
  post_video_thumb_url,
} from "../../ServerConfiguration/awsConfiguration";
import "../../assets/css/repostPost.scss";

function RepostPost({ onClose, postData }) {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);

  const [repostData, setRepostData] = useState([]);

  const closePopup = () => {
    onClose(false);
  };

  useEffect(() => {
    try {
      axiosRequest.get(`post/reposted/${postData._id}`).then((res) => {
        setRepostData(res.data.data);
      });
    } catch (error) {
      if (error?.response?.status !== 401) {
        dispatch(handleToast(ERROR));
      }
    }
  }, []);

  return (
    <div className="repostPost">
      <div className="dialog">
        <div className="header">
          <div className="hidden" aria-hidden="true" />

          <div className="heading">
            <h4 className="title">Reposted</h4>
          </div>

          <div
            className="btn btn-back"
            tabIndex="0"
            aria-label="Back"
            role="button"
            onClick={() => closePopup()}
          >
            <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
              <title>Close</title>
              <g id="Frame 2595">
                <path
                  id="Shape"
                  d="M24.072 5.69684L24.1924 5.80567C24.7728 6.3861 24.8091 7.30462 24.3012 7.92731L24.1924 8.04771L17.242 14.9981L24.1924 21.9484C24.7728 22.5288 24.8091 23.4473 24.3012 24.07L24.1924 24.1904C23.612 24.7709 22.6934 24.8072 22.0707 24.2993L21.9503 24.1904L15 17.2401L8.04966 24.1904C7.46923 24.7709 6.55071 24.8072 5.92802 24.2993L5.80761 24.1904C5.22718 23.61 5.19091 22.6915 5.69878 22.0688L5.80761 21.9484L12.758 14.9981L5.80761 8.04771C5.22718 7.46729 5.19091 6.54877 5.69878 5.92608L5.80761 5.80567C6.38804 5.22524 7.30656 5.18896 7.92925 5.69684L8.04966 5.80567L15 12.756L21.9503 5.80567C22.5308 5.22524 23.4493 5.18896 24.072 5.69684L24.1924 5.80567L24.072 5.69684Z"
                  fill={
                    theme === "Light"
                      ? "#1891a2"
                      : theme === "Dark"
                      ? "#ffffff"
                      : window.matchMedia("(prefers-color-scheme: light)")
                          .matches
                      ? "#1891a2"
                      : "#ffffff"
                  }
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="reposted-posts">
          {repostData?.length > 0 ? (
            <>
              {repostData?.map((post, index) => {
                return (
                  <div key={index} className="reposted-posts-info">
                    <div className="profile-image">
                      <img
                        src={getUserProfilePic(post?.userId.profile)}
                        alt="profile avatar"
                      />
                    </div>

                    <div className="profile-info">
                      <div className="profile-name">
                        <p className="reposted-user-info">
                          <span className="reposted-user-name">
                            {post?.userId.name}
                          </span>{" "}
                          Reposted this post
                        </p>
                      </div>

                      <div className="reposted-post-image">
                        <img
                          src={
                            post?.media[0]?.type === "Video"
                              ? `${post_video_thumb_url}${post.media[0].thumbnail}`
                              : `${post_image__url}${post.media[0].url}`
                          }
                          alt="profile avatar"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="repost-post">
              <div className="default-repost-wrapper no-repost">
                <div className="default-repost">
                  <div className="default-repost-image">
                    <svg width="55" height="55" viewBox="0 0 72 72" fill="none">
                      <path
                        d="M55.0317 35.7516V28.3353C55.0317 25.385 53.8596 22.5554 51.7734 20.4692C49.6872 18.383 46.8576 17.2109 43.9073 17.2109H14.2422"
                        stroke={
                          theme === "Light"
                            ? "#2a2a2a"
                            : theme === "Dark"
                            ? "#ffffff"
                            : window.matchMedia("(prefers-color-scheme: light)")
                                .matches
                            ? "#2a2a2a"
                            : "#ffffff"
                        }
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M25.3703 28.3308L14.2422 17.2064L25.3703 6.08203"
                        stroke={
                          theme === "Light"
                            ? "#2a2a2a"
                            : theme === "Dark"
                            ? "#ffffff"
                            : window.matchMedia("(prefers-color-scheme: light)")
                                .matches
                            ? "#2a2a2a"
                            : "#ffffff"
                        }
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.2535 35.9984V43.4147C14.2535 46.365 15.4255 49.1946 17.5118 51.2808C19.598 53.367 22.4275 54.5391 25.3779 54.5391H55.043"
                        stroke={
                          theme === "Light"
                            ? "#2a2a2a"
                            : theme === "Dark"
                            ? "#ffffff"
                            : window.matchMedia("(prefers-color-scheme: light)")
                                .matches
                            ? "#2a2a2a"
                            : "#ffffff"
                        }
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M43.9149 43.4192L55.043 54.5436L43.9149 65.668"
                        stroke={
                          theme === "Light"
                            ? "#2a2a2a"
                            : theme === "Dark"
                            ? "#ffffff"
                            : window.matchMedia("(prefers-color-scheme: light)")
                                .matches
                            ? "#2a2a2a"
                            : "#ffffff"
                        }
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <span className="default-text">No reposted yet</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RepostPost;
