import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSetting, setTheme } from "../../Redux/actions";
import "../../assets/css/themePopup.scss";

function ThemePopup() {
  const dispatch = useDispatch();

  const theme = useSelector((state) => state.theme);
  const [selectedTheme, setSelectedTheme] = useState(theme);
  const [isClosing, setIsClosing] = useState(false);

  const applyTheme = () => {
    setIsClosing(true);
    setTimeout(() => {
      const body = document.body;
      body.classList.remove("dark", "light");

      if (selectedTheme === "Light") {
        body.classList.remove("dark");
        body.classList.add("light");
      } else if (selectedTheme === "Dark") {
        body.classList.remove("light");
        body.classList.add("dark");
      } else if (selectedTheme === "Default") {
        const prefersLightTheme = window.matchMedia(
          "(prefers-color-scheme: light)"
        ).matches;
        const prefersDarkTheme = window.matchMedia(
          "(prefers-color-scheme: dark)"
        ).matches;
        if (prefersDarkTheme) {
          body.classList.remove("light");
          body.classList.add("dark");
        } else if (prefersLightTheme) {
          body.classList.remove("dark");
          body.classList.add("light");
        }
      }
      dispatch(setTheme(selectedTheme));
      dispatch(setSelectedSetting(""));
    }, 250);  
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      dispatch(setSelectedSetting(""));
    }, 250);
  };

  return (
    <div className={`themePopup ${isClosing ? 'closing' : ''}`}>
      <div className={`dialog ${isClosing ? 'closing' : ''}`}>
        <div className="dialog-info">
          <h4>Theme</h4>
        </div>
        <div className="dialog-info-wrapper">
          <div className="light-wrapper">
            <div className="group-check">
              <input
                id="lightMode"
                type="radio"
                aria-label="Check this box"
                value="Light"
                checked={selectedTheme === "Light"}
                onChange={(e) => setSelectedTheme("Light")}
              />
              <span
                className="checkmark"
                onClick={(e) => setSelectedTheme("Light")}
              />
            </div>
            <label className="description" htmlFor="lightMode">
              Light
            </label>
          </div>

          <div className="dark-wrapper">
            <div className="group-check">
              <input
                id="darkMode"
                type="radio"
                aria-label="Check this box"
                value="Dark"
                checked={selectedTheme === "Dark"}
                onChange={(e) => setSelectedTheme("Dark")}
              />
              <span
                className="checkmark"
                onClick={(e) => setSelectedTheme("Dark")}
              />
            </div>
            <label className="description" htmlFor="darkMode">
              Dark
            </label>
          </div>

          <div className="systemDefault-wrapper">
            <div className="group-check">
              <input
                id="default"
                type="radio"
                aria-label="Check this box"
                value="Default"
                checked={selectedTheme === "Default"}
                onChange={(e) => setSelectedTheme("Default")}
              />
              <span
                className="checkmark"
                onClick={(e) => setSelectedTheme("Default")}
              />
            </div>
            <label className="description" htmlFor="default">
              System default
            </label>
          </div>
        </div>

        <div className="cta">
          <button
            className="btn cancel"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button className="btn for-me active" 
          onClick={() => applyTheme()}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
}

export default ThemePopup;
