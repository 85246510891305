import React, { useState, useEffect, useRef, useCallback } from "react";
import "../../assets/css/trimVideo.scss";
import { useSelector, useDispatch } from "react-redux";
import { setVideoCurrentTime } from "../../Redux/actions";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { formatTime } from "../../Utils/helpers";
import Spinner from "../Common/Spinner";

function TrimVideo({
  onlyVideoRef,
  imagesList,
  currentVideoIndex,
  setCropdeImages,
  saveTrim,
  setIsProcessing,
  handleTrimed,
}) {
  const [ffmpeg, setFfmpeg] = useState(null);
  const dispatch = useDispatch();
  const progressBarRef = useRef();
  const playBackBarRef = useRef();
  const canvasRef = useRef(document.createElement("canvas"));
  const currentlyGrabbedRef = useRef({ index: 0, type: "none" });

  const videoCurrentTime = useSelector((state) => state.videoCurrentTime);

  const MAXCAPTURES = 5;
  const MAXDURATION = 180;
  const GRABBER_WIDTH = 17;
  const videoItems = imagesList?.filter((item) => item?.type?.split("/")[0] === "video") || [];
  const [videoOrImage, setVideoOrImage] = useState(videoItems);
  const [videoUrls, setVideoUrls] = useState({});
  const [playing, setPlaying] = useState(false);
  const [deletingGrabber, setDeletingGrabber] = useState(false);
  const [allVideoTimings, setAllVideoTimings] = useState({});
  const [thumbnails, setThumbnails] = useState({});
  const [isThumbnailGenerating, setIsThumbnailGenerating] = useState(false);
  const [videoDurations, setVideoDurations] = useState({});
  const [seekTimeData, setSeekTimeData] = useState(false);

  useEffect(() => {
    if (saveTrim) {
      handleSave();
    }
  }, [saveTrim]);

  useEffect(() => {
    if (onlyVideoRef.current) {
      const trimmedSection = allVideoTimings[currentVideoIndex]?.[0];
      if (trimmedSection) {
        onlyVideoRef.current.currentTime = trimmedSection.start;
      }
    }
  }, [currentVideoIndex, allVideoTimings]);

  // useEffect for FFmpeg initialization
  useEffect(() => {
    const loadFFmpeg = async () => {
      const ffmpegInstance = new FFmpeg();
      await ffmpegInstance.load();
      setFfmpeg(ffmpegInstance);
    };

    loadFFmpeg();
  }, []);

  useEffect(() => {
    const urls = {};
    videoOrImage?.forEach((file, index) => {
      if (file.type.startsWith("video/")) {
        urls[index] = URL.createObjectURL(file);
      }
    });
    setVideoUrls(urls);

    return () => {
      Object.values(urls).forEach((url) => URL.revokeObjectURL(url));
    };
  }, [videoOrImage]);

  useEffect(() => {
    const setupVideos = async () => {
      const newVideoDurations = {};
      const newAllVideoTimings = {};

      for (let index = 0; index < videoOrImage.length; index++) {
        const file = videoOrImage[index];
        if (file.type.startsWith("video/")) {
          const videoElement = document.createElement("video");
          videoElement.src = videoUrls[index];

          await new Promise((resolve) => {
            videoElement.onloadedmetadata = () => {
              const duration = videoElement.duration;
              newVideoDurations[index] = duration;

              newAllVideoTimings[index] = [
                {
                  start: 0,
                  end: Math.min(duration, MAXDURATION),
                },
              ];
              resolve();
            };
          });
        }
      }

      setVideoDurations(newVideoDurations);
      setAllVideoTimings(newAllVideoTimings);
    };

    setupVideos();
  }, [videoUrls, videoOrImage]);

  useEffect(() => {
    if (videoDurations[currentVideoIndex]) {
      addActiveSegments();
      updateProgressBar();
    }
  }, [videoDurations, currentVideoIndex, allVideoTimings, videoCurrentTime]);

  const updateProgressBar = () => {
    const videoElement = onlyVideoRef.current;
    const duration = videoDurations[currentVideoIndex];
    if (!videoElement || !duration || !allVideoTimings[currentVideoIndex])
      return;

    const currentTimings = allVideoTimings[currentVideoIndex];
    const currentTiming =
      currentTimings.find(
        (timing) =>
          videoCurrentTime >= timing.start && videoCurrentTime <= timing.end
      ) || currentTimings[0];

    if (!currentTiming) return;

    const { start: trimStart, end: trimEnd } = currentTiming;
    const trimDuration = trimEnd - trimStart;

    const progressWithinTrim = (videoCurrentTime - trimStart) / trimDuration;
    const clampedProgress = Math.min(Math.max(progressWithinTrim, 0), 1);

    const containerWidth = progressBarRef.current?.parentElement.offsetWidth;
    const startOffset = (trimStart / duration) * containerWidth;
    const trimWidth = (trimDuration / duration) * containerWidth;
    const pixelPosition = startOffset + clampedProgress * trimWidth;

    progressBarRef.current.style.left = `${pixelPosition}px`;

    if (videoCurrentTime >= trimEnd) {
      videoElement.pause();
      setPlaying(false);
      currentlyGrabbedRef.current = {
        index: currentTimings.indexOf(currentTiming),
        type: "start",
      };
      videoElement.currentTime = trimStart;
      dispatch(setVideoCurrentTime(trimStart));
      progressBarRef.current.style.left = `${startOffset}px`;
    }
  };

  const handleMouseMoveWhenGrabbed = (event) => {
    setSeekTimeData(event);
    onlyVideoRef.current.pause();
    addActiveSegments();
    let playbackRect = playBackBarRef.current.getBoundingClientRect();
    let seekRatio = (event.clientX - playbackRect.left) / playbackRect.width;
    const index = currentlyGrabbedRef.current.index;
    const type = currentlyGrabbedRef.current.type;
    let time = [...(allVideoTimings[currentVideoIndex] || [])];
    let seek = onlyVideoRef.current?.duration * seekRatio;

    const MAX_DURATION = 180;
    const MIN_DURATION = 1;

    if (type === "start") {
      if (
        seek > (index !== 0 ? time[index - 1].end + 0.1 + 0.2 : 0) &&
        seek < time[index].end - MIN_DURATION
      ) {
        const newDuration = time[index].end - seek;
        if (newDuration <= MAX_DURATION) {
          time[index].start = seek;
        } else {
          time[index].start = seek;
          time[index].end = seek + MAX_DURATION;
        }
      }
    } else if (type === "end") {
      if (
        seek > time[index].start + MIN_DURATION &&
        seek <
          (index !== time.length - 1
            ? time[index + 1].start - 0.1 - 0.2
            : onlyVideoRef.current?.duration)
      ) {
        const newDuration = seek - time[index].start;
        if (newDuration <= MAX_DURATION) {
          time[index].end = seek;
        } else {
          time[index].start = seek - MAX_DURATION;
          time[index].end = seek;
        }
      }
    }

    const currentDuration = time[index].end - time[index].start;
    if (currentDuration < MIN_DURATION) {
      if (type === "start") {
        time[index].start = time[index].end - MIN_DURATION;
      } else {
        time[index].end = time[index].start + MIN_DURATION;
      }
    } else if (currentDuration > MAX_DURATION) {
      if (type === "start") {
        time[index].start = time[index].end - MAX_DURATION;
      } else {
        time[index].end = time[index].start + MAX_DURATION;
      }
    }

    setAllVideoTimings((prev) => ({ ...prev, [currentVideoIndex]: time }));
    setPlaying(false);
    onlyVideoRef.current.currentTime = time[index].start;
    progressBarRef.current.style.left = `${
      (time[index].start / onlyVideoRef.current?.duration) * 100
    }%`;
  };

  const removeMouseMoveEventListener = () => {
    window.removeEventListener("mousemove", handleMouseMoveWhenGrabbed);
  };

  const removePointerMoveEventListener = () => {
    window.removeEventListener("pointermove", handleMouseMoveWhenGrabbed);
  };

  const updateProgress = (event) => {
    const videoElement = onlyVideoRef.current;
    if (!videoElement) return;

    let playbackRect = playBackBarRef.current.getBoundingClientRect();
    let seekTime =
      ((event.clientX - playbackRect.left) / playbackRect.width) *
      videoDurations[currentVideoIndex];
    videoElement.pause();
    const currentTimings = allVideoTimings[currentVideoIndex] || [];
    let index = currentTimings.findIndex(
      (timing) => seekTime >= timing.start && seekTime <= timing.end
    );
    if (index === -1) return;

    setPlaying(false);
    currentlyGrabbedRef.current = { index: index, type: "start" };
    progressBarRef.current.style.left = `${
      (currentTimings[index].start / videoDurations[currentVideoIndex]) * 100
    }%`;
    videoElement.currentTime = seekTime;
  };

  const deleteGrabber = (index) => {
    let time = [...(allVideoTimings[currentVideoIndex] || [])];
    setDeletingGrabber(false);
    if (time.length === 1) return;
    time.splice(index, 1);
    setAllVideoTimings((prev) => ({ ...prev, [currentVideoIndex]: time }));
    progressBarRef.current.style.left = `${
      (time[0].start / videoDurations[currentVideoIndex]) * 100
    }%`;
    dispatch(setVideoCurrentTime(time[0].start));
    addActiveSegments();
  };

  const addActiveSegments = () => {
    if (!playBackBarRef.current || !videoDurations[currentVideoIndex]) return;

    const duration = videoDurations[currentVideoIndex];
    const currentTimings = allVideoTimings[currentVideoIndex] || [];
    let colors = "";
    colors += `, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) ${
      (currentTimings[0]?.start / duration) * 100
    }%`;

    currentTimings.forEach((timing, index) => {
      if (index > 0) {
        colors += `, rgba(0, 0, 0, 0.5) ${
          (currentTimings[index - 1].end / duration) * 100
        }%, rgba(0, 0, 0, 0.5) ${(timing.start / duration) * 100}%`;
      }
      colors += `, transparent ${
        (timing.start / duration) * 100
      }%, transparent ${(timing.end / duration) * 100}%`;
    });

    colors += `, rgba(0, 0, 0, 0.5) ${
      (currentTimings[currentTimings.length - 1]?.end / duration) * 100
    }%, rgba(0, 0, 0, 0.5) 100%`;

    playBackBarRef.current.style.background = `linear-gradient(to right${colors})`;
  };

  // START video 5 thumb duration wise
  useEffect(() => {
    generateAllThumbnails();
  }, [videoOrImage]);

  const generateAllThumbnails = async () => {
    setIsThumbnailGenerating(true);
    const allThumbnails = {};
    for (let i = 0; i < videoOrImage.length; i++) {
      if (videoOrImage[i].type.startsWith("video/")) {
        allThumbnails[i] = await generateThumbnailsForVideo(videoOrImage[i]);
      }
    }
    setThumbnails(allThumbnails);
    setIsThumbnailGenerating(false);
  };

  const generateThumbnailsForVideo = (videoFile) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(videoFile);
      video.onloadedmetadata = () => {
        const interval = video.duration / MAXCAPTURES;
        const thumbnails = [];
        let currentTime = 0;

        const captureThumbnail = () => {
          if (
            currentTime >= video.duration ||
            thumbnails.length >= MAXCAPTURES
          ) {
            URL.revokeObjectURL(video.src);
            resolve(thumbnails);
            return;
          }

          video.currentTime = currentTime;
          video.onseeked = () => {
            canvasRef.current.width = 200;
            canvasRef.current.height = 100;
            const ctx = canvasRef.current.getContext("2d");
            ctx.drawImage(video, 0, 0, 200, 100);
            thumbnails.push(canvasRef.current.toDataURL("image/jpeg", 0.7));
            currentTime += interval;
            captureThumbnail();
          };
        };

        captureThumbnail();
      };
    });
  };
  // END

  // video start to end trim function
  const handleSave = async () => {
    if (!ffmpeg) {
      console.error("FFmpeg is not initialized");
      return;
    }

    setIsProcessing(true);

    try {
      const finalList = [...imagesList]; // Create a copy of the original list
      const videoIndices = [];

      // First, identify all video indices in the original imagesList
      imagesList.forEach((item, index) => {
        if (item.type.startsWith("video/")) {
          videoIndices.push(index);
        }
      });

      for (let i = 0; i < videoOrImage.length; i++) {
        const file = videoOrImage[i];
        const originalIndex = videoIndices[i]; // Get the original index in imagesList

        const currentTiming = allVideoTimings[i][0];
        const start = currentTiming.start;
        const end = currentTiming.end;

        const videoUrl = videoUrls[i];
        if (!videoUrl) {
          console.error(`Video URL not found for index ${i}`);
          continue;
        }

        // Write the input file
        await ffmpeg.writeFile(`input_${i}.mp4`, await fetchFile(videoUrl));

        // Trim the video
        // await ffmpeg.exec([
        //   "-ss", `${start}`,
        //   "-i", `input_${i}.mp4`,
        //   "-t", `${end - start}`,
        //   "-c", "copy",
        //   `output_${i}.mp4`
        // ]);

        // await ffmpeg.exec([
        //   "-ss",`${start}`,
        //   "-i",`input_${i}.mp4`,
        //   "-t",`${end - start}`,
        //   "-vcodec","libx264",
        //   "-preset","ultrafast",
        //   "-crf","24",
        //   "-vf","scale=iw/2:-1",
        //   "-c:a","aac",
        //   "-b:a","128k",
        //   `output_${i}.mp4`,
        // ]);

        await ffmpeg.exec([
          "-ss", `${start}`,
          "-i", `input_${i}.mp4`,
          "-t", `${end - start}`,
          "-c:v", "libx264",
          "-preset", "ultrafast", // Changed from "ultrafast" for better compression
          "-crf", "24", // Adjusted for better quality-to-size ratio
          "-vf", "scale='min(1280,iw)':'min(720,ih)':force_original_aspect_ratio=decrease",
          "-c:a", "aac",
          "-b:a", "128k",
          "-movflags", "+faststart", // Optimize for web playback
          "-pix_fmt", "yuv420p", // Ensure compatibility
          `output_${i}.mp4`
        ]);

        // Read the output file
        const data = await ffmpeg.readFile(`output_${i}.mp4`);

        // Create a blob and file
        const blob = new Blob([data.buffer], { type: "video/mp4" });
        const url = URL.createObjectURL(blob);
        const trimmedFile = new File([blob], `trimmed_video_${originalIndex}.mp4`, { type: "video/mp4" });

        // Replace the original video with the trimmed one at the original index
        finalList[originalIndex] = trimmedFile;

        // const a = document.createElement("a");
        // a.href = url;
        // a.download = `trimmed_video_${currentVideoIndex}.mp4`;
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);
      }

      // Update cropedImages state with the final list
      setCropdeImages(finalList);
    } catch (error) {
      console.error("Error trimming videos:", error);
    } finally {
      setIsProcessing(false);
      handleTrimed();
    }
  };

  return (
    <div className="trimVideo">
      <div className="trimVideo-wrapper">
        <div className="trimVideo-warning">
          <p className="text">If the video is more than 3 minutes, crop it.</p>
        </div>
        <div className="trimVideo-container">
          <div className="trimVideo-container-heading">
            <span className="text">Trim</span>
          </div>
          <div className="trimVideo-container-trimSection">
            <div className="trimSection-container">
              <div className="trimSection-backgroundThumbs">
                {isThumbnailGenerating ? (
                  <div style={{ margin: "auto" }}>
                    <Spinner />
                  </div>
                ) : thumbnails[currentVideoIndex] ? (
                  thumbnails[currentVideoIndex].map((thumbnail, index) => (
                    <img 
                      key={index}
                      src={thumbnail}
                      alt={`Thumbnail ${index}`}
                      style={{ width: `${100 / MAXCAPTURES}%` }}
                    />
                  ))
                ) : (
                  <div style={{ margin: "auto" }}>
                    <Spinner />
                  </div>
                )}
              </div>
              <div className="selectedContainer">
                {onlyVideoRef.current && allVideoTimings[currentVideoIndex]
                  ? allVideoTimings[currentVideoIndex]?.map((timing, index) => (
                      <div key={index}>
                        <div key={"grabber_" + index}>
                          <div
                            id="grabberStart"
                            className="grabber start"
                            style={{left: `${(timing.start / videoDurations[currentVideoIndex]) * 100 }%`, }}
                            onMouseDown={() => {
                              if (deletingGrabber) {
                                deleteGrabber(index);
                              } else {
                                currentlyGrabbedRef.current = {index: index, type: "start",};
                                window.addEventListener("mousemove", handleMouseMoveWhenGrabbed);
                                window.addEventListener("mouseup", removeMouseMoveEventListener);
                              }
                            }}
                            onPointerDown={() => {
                              if (deletingGrabber) {
                                deleteGrabber(index);
                              } else {
                                currentlyGrabbedRef.current = {index: index, type: "start"};
                                window.addEventListener("pointermove", handleMouseMoveWhenGrabbed);
                                window.addEventListener("pointerup", removePointerMoveEventListener);
                              }
                            }}
                          />
                          <div
                            id="grabberEnd"
                            className="grabber end"
                            style={{left: `calc(${(timing.end / videoDurations[currentVideoIndex]) * 100 }% - ${GRABBER_WIDTH}px)`}}
                            onMouseDown={() => {
                              if (deletingGrabber) {
                                deleteGrabber(index);
                              } else {
                                currentlyGrabbedRef.current = {index: index, type: "end"};
                                window.addEventListener("mousemove", handleMouseMoveWhenGrabbed);
                                window.addEventListener("mouseup", removeMouseMoveEventListener);
                              }
                            }}
                            onPointerDown={() => {
                              if (deletingGrabber) {
                                deleteGrabber(index);
                              } else {
                                currentlyGrabbedRef.current = {index: index, type: "end"};
                                window.addEventListener("pointermove", handleMouseMoveWhenGrabbed);
                                window.addEventListener("pointerup", removePointerMoveEventListener);
                              }
                            }}
                          />
                        </div>
                      </div>
                    ))
                  : null}
                <div ref={playBackBarRef} className="seekable" onClick={updateProgress} />
                <div className="progress-container">
                  <div className="progress" ref={progressBarRef}></div>
                </div>
              </div>
            </div>
          </div>
          {/* Start Time & End Time & Current Time  */}
          {allVideoTimings[currentVideoIndex]?.map((timing, index) => (
            <div key={index} className="trimTiming">
              {/* <div className="startTime">{formatTime(timing.start)}</div> */}
              <div className="startTime">{formatTime(videoCurrentTime)}</div>
              <span>-</span>
              <div className="endTime">{formatTime(timing.end)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default TrimVideo;
