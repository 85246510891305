import { React, useState, useEffect, useRef } from "react";
import Media from "./Media";
import Repost from "./Repost";
import ComingSoon from "../Common/ComingSoon";
import axiosRequest from "../../Middleware/api";
import { ERROR, NO_PROFILE } from "../../Utils/enums";
import { useDispatch, useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import unlikeDark from "../../assets/image/SVG_Images/unlikeDark.svg";
import unlikeWhite from "../../assets/image/SVG_Images/unlikeWhite.svg";
import { convertToFullURL, getUserProfilePic } from "../../Utils/helpers";
import {
  setTepars,
  handleToast,
  setSettingOptions,
  setSelectedSetting,
  setViewProfilePhoto,
  setAnimationDirection,
} from "../../Redux/actions";
import "../../assets/css/teparsProfile.scss";

function TeparsProfile({ selected, setSelected, postContainerRef }) {
  const dispatch = useDispatch();

  const mediaRef = useRef(null);
  const repostRef = useRef(null);
  const containerRef = useRef(null);

  const theme = useSelector((state) => state.theme);
  const loader = useSelector((state) => state.loader);
  const Tepars = useSelector((state) => state.tepars);
  const selfUser = useSelector((state) => state.selfContact);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [tepar, setTepar] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [userRepost, setUserRepost] = useState([]);
  const [sliderStyle, setSliderStyle] = useState({});
  const [postLoader, setPostLoader] = useState(false);

  const openSettings = () => {
    dispatch(setAnimationDirection("left"));
    dispatch(setSettingOptions(true));
  };

  const fetchTepars = (pageNumber) => {
    setLoading(true);

    if (pageNumber === 1) {
      setPostLoader(true);
    }

    axiosRequest
      .get(`post/getuserpost/${selfUser?._id}?page=${pageNumber}`)
      .then((res) => {
        setCount(res.data.totalCount);
        if (res?.data?.data) {
          let updatedTepars = [...Tepars, ...res.data.data];
          if (pageNumber === 1) {
            dispatch(setTepars(res.data.data));
            setTepar(res.data.data);
          } else {
            dispatch(setTepars(updatedTepars));
            setTepar((prevTepars) => [...prevTepars, ...res.data.data]);
          }
        }
        setPage(pageNumber + 1);
        setLoading(false);
        setPostLoader(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setLoading(false);
        setPostLoader(false);
      });
  };

  const fetchRepostTepars = (pageNumber) => {
    setLoading(true);

    if (pageNumber === 1) {
      setPostLoader(true);
    }

    axiosRequest
      .get(`post/repost/${selfUser?._id}?page=${pageNumber}`)
      .then((res) => {
        setCount(res.data.totalCount);
        if (res?.data?.data) {
          let updatedTepars = [...Tepars, ...res.data.data];
          if (pageNumber === 1) {
            setUserRepost(res.data.data);
            dispatch(setTepars(res.data.data));
          } else {
            setUserRepost((prevTepars) => [...prevTepars, ...res.data.data]);
            dispatch(setTepars(updatedTepars));
          }
        }
        setPage(pageNumber + 1);
        setLoading(false);
        setPostLoader(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setLoading(false);
        setPostLoader(false);
      });
  };

  useEffect(() => {
    if (selected === "media") {
      setPage(1);
      setCount(1);
      fetchTepars(1);
      setUserRepost([]);
    } else if (selected === "repost") {
      setPage(1);
      setCount(1);
      setTepar([]);
      fetchRepostTepars(1);
    }
  }, [selected]);

  const handleScroll = () => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

    if (selected === "media") {
      if (
        tepar?.length > 0 &&
        scrollTop + clientHeight >= scrollHeight - 10 &&
        !loading &&
        count !== tepar.length
      ) {
        fetchTepars(page);
      }
    } else if (selected === "repost") {
      if (
        userRepost?.length > 0 &&
        scrollTop + clientHeight >= scrollHeight - 10 &&
        !loading &&
        count !== userRepost.length
      ) {
        fetchRepostTepars(page);
      }
    }
  };

  const scrollToPost = (postId) => {
    const postElement = postContainerRef.current.querySelector(
      `#post-${postId}`
    );
    if (postElement) {
      postElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handlePostSelect = (postId) => {
    scrollToPost(postId);
  };

  const setDefaultSliderStyle = () => {
    const activeElement = document.querySelector(
      ".btns-wrapper .menu-selected"
    );
    if (activeElement) {
      const position = activeElement.offsetLeft;
      const width = activeElement.offsetWidth;
      setSliderStyle({
        left: position,
        width: width,
        backgroundColor:
          theme === "Light"
            ? "#1891a2"
            : theme === "Dark"
            ? "#99d7de"
            : window.matchMedia("(prefers-color-scheme: light)").matches
            ? "#1891a2"
            : "#99d7de",
      });
      return true;
    }
    return false;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (setDefaultSliderStyle()) {
        clearInterval(intervalId);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setDefaultSliderStyle();
  }, [selected, theme]);

  useEffect(() => {
    document.querySelectorAll("chrome_annotation").forEach((el) => {
      el.style.textDecoration = "none important";
      el.style.borderBottom = "none important";
      el.style.backgroundColor = "transparent important";
      el.style.border = "none important";
    });
  }, []);

  const showProfilePhoto = () => {
    if (selfUser?.profile) {
      dispatch(setViewProfilePhoto(selfUser));
    } else {
      dispatch(handleToast(NO_PROFILE));
    }
  };

  return (
    <>
      {loader ? (
        <SkeletonTheme
          baseColor={
            theme === "Light"
              ? ""
              : theme === "Dark"
              ? "#202020"
              : window.matchMedia("(prefers-color-scheme: light)").matches
              ? ""
              : "#202020"
          }
          highlightColor={
            theme === "Light"
              ? ""
              : theme === "Dark"
              ? "#2A2A2A"
              : window.matchMedia("(prefers-color-scheme: light)").matches
              ? ""
              : "#2A2A2A"
          }
        >
          <div className="teparsProfile">
            <div className="user-profile-wrapper">
              <div className="user-profile">
                <div className="user-profile-details">
                  <div className="profile-image">
                    <Skeleton width={130} height={130} circle={true} />
                  </div>
                  <div className="user-stats">
                    <span className="user-name">
                      <Skeleton height={20} borderRadius={20} />
                    </span>
                    <span className="user-phone">
                      <Skeleton height={20} borderRadius={20} />
                    </span>
                    <div className="stats">
                      <div className="posts">
                        <span className="number">
                          <Skeleton height={20} width={50} borderRadius={20} />
                        </span>

                        <div className="visual-text">
                          <Skeleton height={20} width={100} borderRadius={20} />
                        </div>
                      </div>
                      <div className="teps">
                        <span className="number">
                          <Skeleton height={20} width={50} borderRadius={20} />
                        </span>
                        <div className="visual-text">
                          <Skeleton height={20} width={100} borderRadius={20} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="user-status">
                  <span className="main">
                    <Skeleton height={20} width={250} borderRadius={20} />
                  </span>

                  <p className="sub-status">
                    <Skeleton height={20} count={2} borderRadius={20} />
                  </p>

                  <a
                    href="/"
                    className="sub-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Skeleton height={20} borderRadius={20} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SkeletonTheme>
      ) : (
        <div
          ref={containerRef}
          onScroll={handleScroll}
          className="teparsProfile"
        >
          <div className="user-profile-wrapper">
            <div className="user-profile">
              <div className="user-profile-details">
                <div
                  className="profile-image"
                  onClick={() => showProfilePhoto()}
                >
                  <img
                    src={getUserProfilePic(selfUser?.profile)}
                    alt="profile avatar"
                  />
                </div>
                <div className="user-stats">
                  <span className="user-name">{selfUser?.name}</span>
                  <span className="user-phone">
                    {selfUser?.countryCode + " " + selfUser?.phone}
                  </span>
                  <div className="stats">
                    <div className="posts">
                      <span className="number">{selfUser?.post}</span>
                      <div className="visual-text">
                        <span className="text">Posts</span>
                        {/* <svg
                          width="20"
                          height="20"
                          viewBox="0 0 26 23"
                          fill="none"
                          className="post-image"
                        >
                          <g id="Frame" clipPath="url(#clip0_7992_1860)">
                            <g id="Group">
                              <path
                                id="Vector"
                                d="M24.5454 2.2491C24.5454 6.31125 24.5454 10.3619 24.5454 14.4241C24.5341 14.447 24.5116 14.4585 24.5116 14.4814C24.2189 15.8929 23.2617 16.6846 21.8429 16.6846C16.8209 16.6846 11.7988 16.6846 6.77669 16.6846C6.58526 16.6846 6.39384 16.6846 6.20241 16.6502C4.90748 16.4092 4.07422 15.3535 4.07422 13.9536C4.07422 10.2242 4.07422 6.48337 4.07422 2.754C4.07422 1.12455 5.16647 0 6.76543 0C8.63463 0 10.5038 0 12.373 0C15.5597 0 18.7351 0 21.9218 0C23.0928 0 24.0612 0.699975 24.4215 1.81305C24.4666 1.95075 24.5004 2.09992 24.5454 2.2491ZM22.4397 9.94882C22.4623 9.93735 22.4735 9.93735 22.496 9.92587C22.496 9.85702 22.496 9.7767 22.496 9.70785C22.496 7.3899 22.496 5.08342 22.496 2.76547C22.496 2.2491 22.3384 2.08845 21.8317 2.08845C16.8209 2.08845 11.81 2.08845 6.78795 2.08845C6.26997 2.08845 6.11233 2.2491 6.11233 2.76547C6.11233 5.89815 6.11233 9.01935 6.11233 12.152C6.11233 12.2323 6.08981 12.3127 6.14611 12.4045C6.21367 12.3356 6.28123 12.2782 6.33753 12.2209C7.18206 11.3602 8.02658 10.4996 8.85984 9.65047C9.67058 8.83575 10.729 8.82427 11.5398 9.65047C11.8663 9.98325 12.2041 10.3045 12.5194 10.6488C12.6658 10.798 12.7334 10.8209 12.8798 10.6373C14.0621 9.16852 15.2557 7.7112 16.4493 6.26535C17.26 5.28997 18.4874 5.2785 19.3094 6.2424C19.8611 6.885 20.3904 7.5276 20.9309 8.1702C21.4488 8.7669 21.9443 9.35212 22.4397 9.94882Z"
                                fill="#999999"
                              />
                              <path
                                id="Vector_2"
                                d="M2.53835 7.44897C2.53835 7.54077 2.53835 7.6211 2.53835 7.7129C2.53835 9.89315 2.52709 12.0619 2.53835 14.2422C2.54961 15.8028 3.22523 17.0076 4.5652 17.7879C5.17325 18.1322 5.82635 18.2584 6.52449 18.2584C11.3101 18.2584 16.107 18.2584 20.8926 18.2584C21.2304 18.2584 21.2304 18.2584 21.1291 18.5912C20.8476 19.4862 20.5773 20.3928 20.2958 21.2878C19.913 22.5271 18.7194 23.2156 17.4695 22.8714C12.2222 21.4485 6.96364 20.0141 1.71635 18.5682C0.376376 18.201 -0.321761 16.8814 0.0723486 15.5388C0.849308 12.9111 1.62627 10.2718 2.39197 7.64405C2.44827 7.56372 2.47079 7.50635 2.49331 7.4375C2.50457 7.4375 2.52709 7.4375 2.53835 7.44897Z"
                                fill="#999999"
                              />
                              <path
                                id="Vector_3"
                                d="M9.20331 7.29043C8.07728 7.29043 7.15394 6.37243 7.14268 5.22493C7.13142 4.07743 8.06602 3.125 9.19205 3.125C10.3181 3.125 11.2302 4.06595 11.2414 5.21345C11.2527 6.34948 10.3293 7.29043 9.20331 7.29043Z"
                                fill="#999999"
                              />
                            </g>
                          </g>
                          <defs>
                            <clipPath id="clip0_7992_1860">
                              <rect
                                width="25.245"
                                height="22.95"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg> */}
                      </div>
                    </div>
                    <div className="teps">
                      <span className="number">{selfUser?.Teps}</span>
                      <div className="visual-text">
                        <span className="text">Teps</span>
                        <div className="teps-image">
                          <img
                            src={
                              theme === "Light"
                                ? unlikeDark
                                : theme === "Dark"
                                ? unlikeWhite
                                : window.matchMedia(
                                    "(prefers-color-scheme: light)"
                                  ).matches
                                ? unlikeDark
                                : unlikeWhite
                            }
                            alt="teps"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {(selfUser?.description || selfUser?.tags) && (
                <div className="user-status">
                  {selfUser?.tags && (
                    <span className="main">{selfUser?.tags}</span>
                  )}
                  {selfUser?.description && (
                    <p className="sub-status">{selfUser?.description}</p>
                  )}
                  {selfUser?.link && (
                    <div className="profile-link">
                      <a
                        href={convertToFullURL(selfUser?.link)}
                        className="sub-link"
                        rel="noopener noreferrer"
                        target="_blank"
                        title="Profile Link"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 28 28"
                          fill="none"
                        >
                          <title>Profile Link</title>
                          <path
                            d="M10.6386 18.9215H7.69333C6.36985 18.9215 5.10057 18.3519 4.16472 17.3381C3.22888 16.3243 2.70313 14.9492 2.70312 13.5154C2.70312 12.0817 3.22888 10.7066 4.16472 9.69277C5.10057 8.67894 6.36985 8.10938 7.69333 8.10938H10.5736M16.4569 8.10938H19.3372C20.6606 8.10938 21.9299 8.67894 22.8658 9.69277C23.8016 10.7066 24.3274 12.0817 24.3274 13.5154C24.3274 14.9492 23.8016 16.3243 22.8658 17.3381C21.9299 18.3519 20.6606 18.9215 19.3372 18.9215H16.3919M8.99651 13.5154H18.1379"
                            stroke="#1891a2"
                            strokeWidth="1.52045"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        {selfUser?.link}
                      </a>
                    </div>
                  )}
                </div>
              )}

              <div className="user-tepars-cta">
                <div
                  className="editProfile"
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    dispatch(setSelectedSetting("updateProfile"));
                  }}
                >
                  <div className="edit-profile-image">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                      <path
                        d="M3.97937 28.3632C2.85163 28.072 1.90391 27.119 1.55976 25.933C1.47505 25.6365 1.46446 25.0277 1.44858 17.5677C1.43799 12.252 1.45387 9.34527 1.48564 9.00642C1.55447 8.38696 1.7239 7.91575 2.05216 7.42336C2.44395 6.83566 3.09518 6.34327 3.81524 6.09443L4.16467 5.97266H9.20507H14.2402L14.4731 6.13149C15.1826 6.63447 15.0979 7.62455 14.3037 8.04282C14.129 8.13812 13.8748 8.14341 9.2739 8.16989C4.81061 8.19636 4.41881 8.20165 4.25998 8.28637C4.02172 8.41873 3.78876 8.72581 3.71993 8.99583C3.67758 9.15467 3.66169 11.6378 3.66169 17.2394C3.66169 26.1501 3.63522 25.5254 4.02172 25.9225C4.39234 26.3037 3.83112 26.2825 12.8477 26.2666C20.6413 26.2507 20.9536 26.2454 21.1336 26.1501C21.3772 26.023 21.5466 25.8642 21.6525 25.6683C21.7319 25.5254 21.7425 25.0436 21.769 20.665L21.7955 15.8205L21.9119 15.5981C22.3196 14.8357 23.315 14.7669 23.8074 15.471L23.9662 15.6987V20.7073V25.7213L23.8497 26.0919C23.585 26.9284 22.9656 27.6749 22.2137 28.0614C21.4143 28.4797 22.1396 28.4479 12.71 28.4426C5.54655 28.4426 4.22821 28.4321 3.97937 28.3632Z"
                        fill="white"
                      />
                      <path
                        d="M11.3997 18.7853C11.2885 18.7377 11.1985 18.6424 11.1509 18.5259C11.0768 18.3565 11.0979 18.2241 11.495 16.2492C11.7227 15.1003 11.9345 14.1102 11.9662 14.0626C11.9927 14.0096 14.0311 11.9554 16.4931 9.49341L20.9722 5.01953L22.9418 6.99439L24.9167 8.96396L20.4163 13.4643C17.4408 16.4398 15.863 17.9805 15.7571 18.0176C15.6406 18.0547 11.5903 18.8753 11.5797 18.8647C11.5744 18.8594 11.495 18.8277 11.3997 18.7853Z"
                        fill="white"
                      />
                      <path
                        d="M24.1777 5.73527L22.2188 3.771L23.1718 2.83387C23.9501 2.06616 24.1883 1.86497 24.4689 1.72731C24.9084 1.51024 25.2366 1.44141 25.7661 1.44141C26.7191 1.4467 27.5874 1.94439 28.1169 2.79681C28.6251 3.60687 28.6304 4.76108 28.1327 5.62938C28.011 5.83587 26.2479 7.69425 26.1685 7.69425C26.1473 7.69425 25.2525 6.81006 24.1777 5.73527Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <span className="btn-text">Edit Profile</span>
                </div>

                <div
                  className="editGroups"
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    dispatch(setSelectedSetting("myGroups"));
                  }}
                >
                  <div className="edit-groups-image">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                      <g clipPath="url(#clip0_311_7116)">
                        <path
                          d="M9.71188 13.7873H8.78103C7.51075 13.7414 6.24459 13.957 5.06112 14.4209C3.87766 14.8847 2.80214 15.5868 1.90132 16.4836L1.64453 16.7831V25.6422H6.00989V20.6135L6.59835 19.9502L6.86584 19.6399C8.25882 18.2088 9.99304 17.1558 11.9053 16.5798C10.9479 15.8514 10.1928 14.89 9.71188 13.7873Z"
                          fill="white"
                        />
                        <path
                          d="M30.4163 16.4601C29.5155 15.5633 28.44 14.8612 27.2565 14.3974C26.073 13.9336 24.8069 13.718 23.5366 13.7639C23.147 13.765 22.7577 13.7864 22.3703 13.8281C21.8804 14.8626 21.146 15.7623 20.2305 16.4494C22.2719 17.0142 24.1212 18.1238 25.5802 19.6592L25.8477 19.9588L26.4254 20.6222V25.6616H30.641V16.7597L30.4163 16.4601Z"
                          fill="white"
                        />
                        <path
                          d="M8.74154 11.7015H9.07322C8.91911 10.3782 9.1513 9.03866 9.74175 7.84446C10.3322 6.65026 11.2557 5.65248 12.4007 4.97158C11.9857 4.33748 11.413 3.82204 10.7389 3.47574C10.0648 3.12945 9.31228 2.96416 8.55509 2.99607C7.79789 3.02798 7.06196 3.25599 6.41936 3.65777C5.77676 4.05956 5.24953 4.62134 4.88928 5.28812C4.52903 5.95489 4.34813 6.70379 4.36428 7.46149C4.38042 8.21918 4.59307 8.9597 4.9814 9.61052C5.36973 10.2613 5.92043 10.8001 6.57956 11.1742C7.2387 11.5482 7.98367 11.7447 8.74154 11.7443V11.7015Z"
                          fill="white"
                        />
                        <path
                          d="M23.0108 10.9034C23.0238 11.1493 23.0238 11.3957 23.0108 11.6416C23.2161 11.6742 23.4235 11.6921 23.6313 11.6951H23.8346C24.5892 11.6549 25.3204 11.42 25.9572 11.0132C26.594 10.6065 27.1146 10.0418 27.4684 9.37412C27.8222 8.70644 27.997 7.95854 27.976 7.20323C27.9549 6.44791 27.7386 5.71092 27.3482 5.06401C26.9577 4.4171 26.4064 3.88231 25.7479 3.51171C25.0894 3.14111 24.3462 2.94733 23.5906 2.94923C22.835 2.95113 22.0928 3.14865 21.4362 3.52256C20.7795 3.89647 20.2309 4.43402 19.8438 5.08289C20.8121 5.71516 21.6084 6.57792 22.1612 7.59379C22.7139 8.60966 23.0059 9.74686 23.0108 10.9034Z"
                          fill="white"
                        />
                        <path
                          d="M15.9858 15.6981C18.6271 15.6981 20.7684 13.5568 20.7684 10.9154C20.7684 8.27407 18.6271 6.13281 15.9858 6.13281C13.3444 6.13281 11.2031 8.27407 11.2031 10.9154C11.2031 13.5568 13.3444 15.6981 15.9858 15.6981Z"
                          fill="white"
                        />
                        <path
                          d="M16.2498 18.2479C14.8525 18.1915 13.4582 18.4183 12.1509 18.9148C10.8435 19.4112 9.65012 20.167 8.64248 21.1367L8.375 21.4363V28.209C8.37918 28.4296 8.42677 28.6472 8.51507 28.8495C8.60337 29.0517 8.73064 29.2345 8.88961 29.3875C9.04859 29.5405 9.23615 29.6607 9.44158 29.7412C9.64702 29.8217 9.8663 29.861 10.0869 29.8567H22.3805C22.6011 29.861 22.8204 29.8217 23.0258 29.7412C23.2313 29.6607 23.4188 29.5405 23.5778 29.3875C23.7368 29.2345 23.864 29.0517 23.9523 28.8495C24.0406 28.6472 24.0882 28.4296 24.0924 28.209V21.4577L23.8356 21.1367C22.8345 20.164 21.6453 19.4061 20.3408 18.9094C19.0364 18.4126 17.6443 18.1875 16.2498 18.2479Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_311_7116">
                          <rect
                            width="31.5146"
                            height="31.5146"
                            fill="white"
                            transform="translate(0.371094 0.0273438)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <span className="btn-text">Groups</span>
                </div>

                <div
                  className="editSettings"
                  role="button"
                  tabIndex="0"
                  onClick={() => openSettings()}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <title>Settings</title>
                    <path
                      id="Vector"
                      d="M9.41612 0.255132C11.1197 -0.0842338 12.8787 -0.0850509 14.5826 0.252733C14.6961 0.964524 14.977 1.64286 15.4045 2.23732C15.8319 2.83178 16.3949 3.32708 17.0516 3.68639C17.7077 4.04695 18.441 4.26234 19.1968 4.31656C19.9527 4.37077 20.7117 4.26241 21.4174 3.99952C22.5771 5.23293 23.4555 6.68099 23.9975 8.25261C23.4056 8.702 22.928 9.27233 22.6 9.9212C22.272 10.5701 22.1022 11.2808 22.1031 12.0006C22.1031 13.5171 22.8432 14.8692 24 15.7486C23.4548 17.3189 22.5753 18.7658 21.4161 19.9993C20.7106 19.7366 19.9518 19.6283 19.1962 19.6825C18.4406 19.7367 17.7076 19.952 17.0516 20.3124C16.3954 20.6716 15.8329 21.1666 15.4057 21.7606C14.9785 22.3546 14.6976 23.0324 14.5839 23.7437C12.8805 24.0838 11.1215 24.0855 9.41738 23.7485C9.30441 23.036 9.02375 22.357 8.59628 21.7619C8.16881 21.1667 7.60555 20.6709 6.94843 20.3112C6.29219 19.9508 5.55887 19.7357 4.80302 19.6816C4.04716 19.6276 3.28821 19.7362 2.58261 19.9993C1.42277 18.7655 0.544313 17.317 0.0025259 15.745C0.594056 15.2958 1.07151 14.7259 1.39945 14.0775C1.7274 13.4291 1.89741 12.7188 1.89686 11.9994C1.89748 11.2794 1.72727 10.5686 1.39886 9.91967C1.07046 9.27078 0.592326 8.70057 0 8.25141C0.545151 6.68109 1.42469 5.23418 2.58388 4.00072C3.28939 4.26342 4.04816 4.37168 4.80378 4.31747C5.5594 4.26326 6.29244 4.04797 6.94843 3.68759C7.60456 3.32839 8.1671 2.83341 8.59431 2.23939C9.02151 1.64538 9.3024 0.966389 9.41612 0.255132ZM12 15.5998C13.0048 15.5998 13.9685 15.2206 14.679 14.5456C15.3895 13.8706 15.7887 12.9552 15.7887 12.0006C15.7887 11.046 15.3895 10.1305 14.679 9.45556C13.9685 8.78058 13.0048 8.40137 12 8.40137C10.9952 8.40137 10.0315 8.78058 9.321 9.45556C8.61049 10.1305 8.21132 11.046 8.21132 12.0006C8.21132 12.9552 8.61049 13.8706 9.321 14.5456C10.0315 15.2206 10.9952 15.5998 12 15.5998Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="medias-wrapper">
              <div className="mediaLinksDocs-btn">
                <div className="btns-wrapper">
                  <div className="slider" style={sliderStyle} />

                  <div
                    className={`media ${
                      selected === "media" && "menu-selected"
                    }`}
                    onClick={() => setSelected("media")}
                  >
                    <div className="media-image">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 26 23"
                        fill="none"
                      >
                        <g id="Frame" clipPath="url(#clip0_7992_1860)">
                          <g id="Group">
                            <path
                              id="Vector"
                              d="M24.5454 2.2491C24.5454 6.31125 24.5454 10.3619 24.5454 14.4241C24.5341 14.447 24.5116 14.4585 24.5116 14.4814C24.2189 15.8929 23.2617 16.6846 21.8429 16.6846C16.8209 16.6846 11.7988 16.6846 6.77669 16.6846C6.58526 16.6846 6.39384 16.6846 6.20241 16.6502C4.90748 16.4092 4.07422 15.3535 4.07422 13.9536C4.07422 10.2242 4.07422 6.48337 4.07422 2.754C4.07422 1.12455 5.16647 0 6.76543 0C8.63463 0 10.5038 0 12.373 0C15.5597 0 18.7351 0 21.9218 0C23.0928 0 24.0612 0.699975 24.4215 1.81305C24.4666 1.95075 24.5004 2.09992 24.5454 2.2491ZM22.4397 9.94882C22.4623 9.93735 22.4735 9.93735 22.496 9.92587C22.496 9.85702 22.496 9.7767 22.496 9.70785C22.496 7.3899 22.496 5.08342 22.496 2.76547C22.496 2.2491 22.3384 2.08845 21.8317 2.08845C16.8209 2.08845 11.81 2.08845 6.78795 2.08845C6.26997 2.08845 6.11233 2.2491 6.11233 2.76547C6.11233 5.89815 6.11233 9.01935 6.11233 12.152C6.11233 12.2323 6.08981 12.3127 6.14611 12.4045C6.21367 12.3356 6.28123 12.2782 6.33753 12.2209C7.18206 11.3602 8.02658 10.4996 8.85984 9.65047C9.67058 8.83575 10.729 8.82427 11.5398 9.65047C11.8663 9.98325 12.2041 10.3045 12.5194 10.6488C12.6658 10.798 12.7334 10.8209 12.8798 10.6373C14.0621 9.16852 15.2557 7.7112 16.4493 6.26535C17.26 5.28997 18.4874 5.2785 19.3094 6.2424C19.8611 6.885 20.3904 7.5276 20.9309 8.1702C21.4488 8.7669 21.9443 9.35212 22.4397 9.94882Z"
                              fill="#999999"
                            />
                            <path
                              id="Vector_2"
                              d="M2.53835 7.44897C2.53835 7.54077 2.53835 7.6211 2.53835 7.7129C2.53835 9.89315 2.52709 12.0619 2.53835 14.2422C2.54961 15.8028 3.22523 17.0076 4.5652 17.7879C5.17325 18.1322 5.82635 18.2584 6.52449 18.2584C11.3101 18.2584 16.107 18.2584 20.8926 18.2584C21.2304 18.2584 21.2304 18.2584 21.1291 18.5912C20.8476 19.4862 20.5773 20.3928 20.2958 21.2878C19.913 22.5271 18.7194 23.2156 17.4695 22.8714C12.2222 21.4485 6.96364 20.0141 1.71635 18.5682C0.376376 18.201 -0.321761 16.8814 0.0723486 15.5388C0.849308 12.9111 1.62627 10.2718 2.39197 7.64405C2.44827 7.56372 2.47079 7.50635 2.49331 7.4375C2.50457 7.4375 2.52709 7.4375 2.53835 7.44897Z"
                              fill="#999999"
                            />
                            <path
                              id="Vector_3"
                              d="M9.20331 7.29043C8.07728 7.29043 7.15394 6.37243 7.14268 5.22493C7.13142 4.07743 8.06602 3.125 9.19205 3.125C10.3181 3.125 11.2302 4.06595 11.2414 5.21345C11.2527 6.34948 10.3293 7.29043 9.20331 7.29043Z"
                              fill="#999999"
                            />
                          </g>
                        </g>
                        <defs>
                          <clipPath id="clip0_7992_1860">
                            <rect width="25.245" height="22.95" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <span className="text">Posts</span>
                  </div>
                  <div
                    className={`repost ${
                      selected === "repost" && "menu-selected"
                    }`}
                    onClick={() => setSelected("repost")}
                  >
                    <div className="repost-image">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 27 27"
                        fill="none"
                      >
                        <path
                          d="M20.4403 13.1838V10.3978C20.4403 9.28943 20 8.22647 19.2163 7.44276C18.4325 6.65904 17.3696 6.21875 16.2612 6.21875H5.11719"
                          stroke="#999999"
                          strokeWidth="2.34395"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.28979 10.3971L5.10938 6.21808L9.28979 2.03906"
                          stroke="#999999"
                          strokeWidth="2.34395"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.11442 13.2772V16.0632C5.11442 17.1715 5.55471 18.2345 6.33843 19.0182C7.12215 19.8019 8.1851 20.2422 9.29344 20.2422H20.4375"
                          stroke="#999999"
                          strokeWidth="2.34395"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.2571 16.0638L20.4375 20.2429L16.2571 24.4219"
                          stroke="#999999"
                          strokeWidth="2.34395"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <span className="text">Reposts</span>
                  </div>
                </div>
              </div>
              {/* {selected === "media" && (
                <Media
                  tepars={tepar}
                  loading={loading}
                  postLoading={postLoader}
                  handlePostSelect={(postId) => handlePostSelect(postId)}
                />
              )}
              {selected === "repost" && (
                <Repost
                  userRepost={userRepost}
                  loading={loading}
                  postLoading={postLoader}
                />
              )} */}

              <div className="media-wrapper">
                <div
                  ref={mediaRef}
                  className="container"
                  style={{ display: selected === "media" ? "block" : "none" }}
                >
                  <Media
                    mediaRef={containerRef}
                    onScroll={handleScroll}
                    loading={loading}
                    postLoading={postLoader}
                    handlePostSelect={(postId) => handlePostSelect(postId)}
                  />
                </div>
                <div
                  ref={repostRef}
                  className="container"
                  style={{ display: selected === "repost" ? "block" : "none" }}
                >
                  <Repost
                    userRepost={userRepost}
                    loading={loading}
                    postLoading={postLoader}
                    handlePostSelect={(postId) => handlePostSelect(postId)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {message && <ComingSoon onClose={() => setMessage(false)} />}
    </>
  );
}

export default TeparsProfile;
