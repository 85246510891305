import React, { useState, useRef, useEffect } from "react";
import axiosRequest from "../../Middleware/api";
import Slider from "react-slick";
import ShowDots from "./ShowDots";
import ReportPost from "./ReportPost";
import BigTep from "../../assets/image/SVG_Images/BigTep.svg";
import CommentView from "./CommentView";
import CommentAction from "./CommentAction";
import { getTepars } from "../../Utils/helpers";
import { IMAGE, VIDEO } from "../../Utils/enums";
import { useSelector, useDispatch } from "react-redux";
import {
  setPostDetails,
  setTogglePostAction,
  setSelfContact,
  setTepars,
  handleToast,
} from "../../Redux/actions";
import { useSwiperSlider } from "../../Utils/helpers";
import { LIKED, UNLIKE, ERROR } from "../../Utils/enums";

import "../../assets/css/viewPost.scss";

function ViewPost({
  setSelectedMenu,
  toggleSelfLike,
  showAnimation,
  setShowAnimation,
}) {
  const dispatch = useDispatch();

  const videoRef = useRef(null);
  const sliderRef = useRef(null);

  const sliderWrapperRef = useRef(null);

  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const postDetails = useSelector((state) => state.postDetails);
  const selfUser = useSelector((state) => state.selfContact);

  const imagesList = postDetails?.media;

  const [isReplyId, setIsReplyId] = useState();
  const [isCommentId, setIsCommentId] = useState();
  const [leftClick, setLeftClick] = useState(false);
  const [passPostData, setPassPostData] = useState();
  const [rightClick, setRightClick] = useState(false);
  const [isUserPost, setIsUserPost] = useState(false);
  const [reportMenu, setReportMenu] = useState(false);
  const [isReplyDelete, setIsReplyDelete] = useState(false);
  const [getCurrentIndex, setGetCurrentIndex] = useState(0);
  const [mediaIndexFinal, setMediaIndexFinal] = useState(0);
  const [isCommentDelete, setIsCommentDelete] = useState(false);
  const [isCommentOrReply, setIsCommentOrReply] = useState(false);
  const [isCommentAndReplyId, setIsCommentAndReplyId] = useState();
  const [reportCommentAndReply, setReportCommentAndReply] = useState(false);
  const [selectedTeparsImage, setSelectedTeparsImage] = useState(imagesList[0]);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [aspectRatio, setAspectRatio] = useState("");
  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [videoPause, setVideoPause] = useState(false);

  const handlePlayPauseToggleClick = (e) => {
    const video = document.getElementById(e);
    if (video && video.readyState === 4) {
      video.paused ? video.play() : video.pause();
      setVideoPause(video.paused);
    }
  };

  const disableRightClick = (event) => {
    event.preventDefault();
  };

  const handleCropPlayPauseToggle = (e, index) => {
    const videoElement = e.target;
    if (videoElement.paused) {
      // Pause all other videos
      imagesList.forEach((_, i) => {
        if (i !== index) {
          const otherVideo = document.querySelector(`video[data-index="${i}"]`);
          if (otherVideo) {
            otherVideo.pause();
          }
        }
      });
      videoElement.play();
      setVideoPause(false);
    } else {
      videoElement.pause();
      setVideoPause(true);
    }
  };

  const HendlePostClose = () => {
    dispatch(setTogglePostAction(null));
    dispatch(setPostDetails(null));
  };

  const goToSlide = (index) => {
    sliderRef?.current?.slickGoTo(index);
  };

  // useEffect(() => {
  //   // imagesList.forEach((media) => {
  //   const isVideo = imagesList[0].type === "Video";

  //   if (isVideo) {
  //     const video = document.createElement("video");
  //     video.src = getTepars(imagesList[0]);

  //     video.onloadedmetadata = () => {
  //       const width = video.videoWidth;
  //       const height = video.videoHeight;
  //       calculateDimensions(width, height);
  //     };
  //   } else {
  //     const img = new Image();
  //     img.src = getTepars(imagesList[0]);
  //     img.onload = () => {
  //       const width = img.width;
  //       const height = img.height;
  //       console.log(width);
  //       console.log(height);
  //       calculateDimensions(width, height);
  //     };
  //   }
  //   // });
  // }, []);
  useEffect(() => {
    if (imagesList && imagesList.length > 0) {
      const currentMedia = imagesList[0];
      updateDimensions(currentMedia);
    }
  }, [imagesList]);

  const updateDimensions = (media) => {
    const isVideo = media.type === "Video";
    const mediaUrl = getTepars(media);

    if (isVideo) {
      const video = document.createElement("video");
      video.src = mediaUrl;

      video.onloadedmetadata = () => {
        const width = video.videoWidth;
        const height = video.videoHeight;
        calculateDimensions(width, height);
      };
    } else {
      const img = new Image();
      img.src = mediaUrl;
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        calculateDimensions(width, height);
      };
    }
  };
  const calculateDimensions = (width, height) => {
    setDimensions({ width, height });

    const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));

    const divisor = gcd(width, height);
    const ratioWidth = width / divisor;
    const ratioHeight = height / divisor;

    setAspectRatio(`${ratioWidth}:${ratioHeight}`);
  };

  const handleLoadedMetadata = (event) => {
    setVideoDimensions({
      width: event.target.videoWidth,
      height: event.target.videoHeight,
    });
  };
  const getObjectFit = () => {
    return videoDimensions.height < videoDimensions.width
      ? imagesList[0]?.aspectRatio === 0.5
        ? "cover"
        : "contain"
      : "cover";
  };

  useSwiperSlider(sliderRef, sliderWrapperRef, imagesList.length);

  const toggleLike = (postId) => {
    const data = {};
    axiosRequest
      .put(`post/like/${postId}`, data)
      .then((res) => {
        if (res?.data?.message === LIKED || res?.data?.message === UNLIKE) {
          if (res?.data?.message === LIKED) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps + 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          if (res?.data?.message === UNLIKE) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps - 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          let newpost = postDetails;
          const newObj = {
            userId: {
              _id: selfUser?._id,
              name: "You",
              profile: selfUser?.profile,
              phone: selfUser?.phone,
              countryCode: selfUser?.countryCode,
              contactStatus: selfUser?.isUserVerified,
            },
          };
          if (postDetails?.selfLike) {
            newpost.TotalLike -= 1;
            newpost.selfLike = false;
            newpost.likeUser = newpost?.likeUser?.filter(
              (item) => item?.userId?._id !== selfUser?._id
            );
          } else {
            newpost.TotalLike += 1;
            newpost.selfLike = true;
            newpost.likeUser = [...(newpost?.likeUser || []), newObj];
          }
          const findPost = Tepars.map((Post) =>
            Post?._id === postId ? newpost : Post
          );
          dispatch(setTepars(findPost));
          setShowAnimation(true);
          setTimeout(() => {
            setShowAnimation(false);
          }, 1000);
        } else {
          dispatch(handleToast(res?.data?.message));
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const handleDoubleClick = () => {
    if (!postDetails.selfLike) {
      toggleLike(postDetails._id);
    } else {
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
      }, 1000);
    }
  };

  return (
    <div className="viewPost">
      <div className={`viewPost-popup `}>
        {/* header */}
        <div className="header">
          <div className="heading">
            <h1 className="title">View post</h1>
          </div>
          <div
            className="btn btn-back"
            onClick={HendlePostClose}
            tabIndex="0"
            aria-label="Back"
            role="button"
          >
            <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
              <title>Close</title>
              <g id="Frame 2595">
                <path
                  id="Shape"
                  d="M24.072 5.69684L24.1924 5.80567C24.7728 6.3861 24.8091 7.30462 24.3012 7.92731L24.1924 8.04771L17.242 14.9981L24.1924 21.9484C24.7728 22.5288 24.8091 23.4473 24.3012 24.07L24.1924 24.1904C23.612 24.7709 22.6934 24.8072 22.0707 24.2993L21.9503 24.1904L15 17.2401L8.04966 24.1904C7.46923 24.7709 6.55071 24.8072 5.92802 24.2993L5.80761 24.1904C5.22718 23.61 5.19091 22.6915 5.69878 22.0688L5.80761 21.9484L12.758 14.9981L5.80761 8.04771C5.22718 7.46729 5.19091 6.54877 5.69878 5.92608L5.80761 5.80567C6.38804 5.22524 7.30656 5.18896 7.92925 5.69684L8.04966 5.80567L15 12.756L21.9503 5.80567C22.5308 5.22524 23.4493 5.18896 24.072 5.69684L24.1924 5.80567L24.072 5.69684Z"
                  fill={
                    theme === "Light"
                      ? "#1891a2"
                      : theme === "Dark"
                      ? "#ffffff"
                      : window.matchMedia("(prefers-color-scheme: light)")
                          .matches
                      ? "#1891a2"
                      : "#ffffff"
                  }
                />
              </g>
            </svg>
          </div>
        </div>

        <main className="container" style={{ display: "flex" }}>
          <div
            className="upload-image-container"
            style={{ width: "450px", height: "562.5px" }}
            ref={sliderWrapperRef}
            onDoubleClick={handleDoubleClick}
          >
            <Slider
              ref={sliderRef}
              {...{
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                // style: { width: dimensions.width },
                swipe: false,
                afterChange: (currentSlide) => {
                  const currentMedia = imagesList[currentSlide];
                  // updateDimensions(currentMedia);
                  setMediaIndexFinal(currentSlide);
                },
              }}
            >
              {imagesList?.map((images, index) =>
                images?.type === IMAGE ? (
                  <div className="next-img" key={index}>
                    <div
                      className="upload-image"
                      key={index}
                      style={{
                        backgroundImage: `url(${getTepars(images)})`,
                        backgroundSize:
                          dimensions.width > dimensions.height
                            ? "contain"
                            : dimensions.width === dimensions.height
                            ? "contain"
                            : "cover",
                      }}
                    />
                  </div>
                ) : images?.type === VIDEO ? (
                  <>
                    <div className="upload-image" key={index}>
                      <video
                        className="upload-video"
                        src={getTepars(images)}
                        ref={videoRef}
                        autoplay
                        controls={false}
                        width="100%"
                        height="100%"
                        onLoadedMetadata={handleLoadedMetadata}
                        onClick={(e) => handleCropPlayPauseToggle(e, index)}
                        style={{
                          objectFit: getObjectFit(),
                        }}
                      />
                    </div>
                    {videoPause && images?.type === "Video" && (
                      <div
                        className={`video ${videoPause}`}
                        onClick={() => {
                          handlePlayPauseToggleClick(`${images?._id}-${index}`);
                        }}
                        // onContextMenu={disableRightClick}
                        onContextMenu={disableRightClick}
                        title="Play"
                      >
                        {/* <img className="pause" src={Pause} alt="play/pause" /> */}
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <title>Play</title>
                          <g id="line-md:play-to-pause-transition">
                            <path
                              id="Vector"
                              d="M1.83399 11.0104L1.83398 17.8482C1.83398 19.6325 3.97888 20.7407 5.69234 19.8541L12.2873 16.4297L18.8822 13.0053C20.5957 12.1187 20.5957 9.89112 18.8822 8.99345L12.2873 5.56901L5.69234 2.14456C3.97889 1.25798 1.83399 2.36621 1.83399 4.15047L1.83399 10.9883L1.83399 11.0104Z"
                              fill="white"
                            />
                          </g>
                        </svg>
                      </div>
                    )}
                  </>
                ) : null
              )}
            </Slider>
            {/* Dots */}
            <div className="cta-wrapper" style={{ justifyContent: "center" }}>
              <div className="add-tepars-container">
                <div className="add-tepars">
                  <div className="media-wrapper">
                    {imagesList?.length > 1 && (
                      <ShowDots
                        media={postDetails?.media}
                        mediaIndexFinal={mediaIndexFinal}
                        getSelectedImage={setSelectedTeparsImage}
                        getCurrentIndex={setGetCurrentIndex}
                        setRightClick={setRightClick}
                        checkRightClick={rightClick}
                        setLeftClick={setLeftClick}
                        checkLeftClick={leftClick}
                        goToSlide={goToSlide}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="gradient" aria-hidden="true" />
            {showAnimation && (
              <div className="doubleTepAnimation">
                <img src={BigTep} alt="Teps" />
              </div>
            )}
          </div>
          <div className="filters">
            <CommentView
              postData={postDetails}
              setPassPostData={setPassPostData}
              setIsCommentOrReply={setIsCommentOrReply}
              isCommentId={isCommentId}
              setIsCommentId={setIsCommentId}
              isReplyId={isReplyId}
              setIsReplyId={setIsReplyId}
              setIsUserPost={setIsUserPost}
              isCommentDelete={isCommentDelete}
              setIsCommentDelete={setIsCommentDelete}
              isReplyDelete={isReplyDelete}
              setIsReplyDelete={setIsReplyDelete}
              goToSlide={goToSlide}
              setSelectedMenu={setSelectedMenu}
              toggleSelfLike={toggleSelfLike}
            />
          </div>
        </main>
      </div>
      {/* CommentAction */}
      {isCommentOrReply && (
        <CommentAction
          passPostData={passPostData}
          setIsCommentOrReply={setIsCommentOrReply}
          isCommentId={isCommentId}
          setIsCommentId={setIsCommentId}
          isReplyId={isReplyId}
          setIsReplyId={setIsReplyId}
          isUserPost={isUserPost}
          setIsCommentDelete={setIsCommentDelete}
          setIsReplyDelete={setIsReplyDelete}
          setReportMenu={setReportMenu}
          setReportCommentAndReply={setReportCommentAndReply}
          setIsCommentAndReplyId={setIsCommentAndReplyId}
        />
      )}
      {/* ReportPost */}
      {reportMenu && (
        <ReportPost
          post={passPostData}
          reportMenu={reportMenu}
          setReportMenu={setReportMenu}
          isCommentAndReplyId={isCommentAndReplyId}
          setIsCommentAndReplyId={setIsCommentAndReplyId}
          reportCommentAndReply={reportCommentAndReply}
          setReportCommentAndReply={setReportCommentAndReply}
        />
      )}
    </div>
  );
}

export default ViewPost;
