import { React, useState, useEffect } from "react";
import { ERROR } from "../../../Utils/enums";
import axiosRequest from "../../../Middleware/api";
import { useDispatch, useSelector } from "react-redux";
import {
  handleToast,
  setSettings,
  setSelectedSetting,
  setExceptContactType,
  setAnimationDirection,
} from "../../../Redux/actions";
import "../../../assets/css/lastSeen.scss";

function ProfilePhoto() {
  const dispatch = useDispatch();

  const settings = useSelector((state) => state.settings);

  const [isMounted, setIsMounted] = useState(true);

  const handleBackButton = () => {
    setIsMounted(false);
    setAnimationDirection("right");
    dispatch(setSelectedSetting("privacy"));
  };

  useEffect(() => {
    const element = document.querySelector(".lastSeen");

    const handleAnimationEnd = () => {
      if (!isMounted) {
      }
    };

    if (isMounted) {
      element.classList.add("open");
      element.classList.remove("close");
      element.removeEventListener("animationend", handleAnimationEnd);
    } else {
      element.classList.add("close");
      element.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      element.classList.remove("open", "close");
      element.removeEventListener("animationend", handleAnimationEnd);
    };
  }, [isMounted]);

  const PrivacySettings = (key, value) => {
    const data = { [key]: value };
    axiosRequest
      .put("/user/setting/privacy", data)
      .then((response) => {
        dispatch(setSettings(response?.data?.data));
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  return (
    <div className="lastSeen">
      <div className="lastSeen-wrapper">
        <header className="header">
          <div className="back">
            <div
              className="btn btn-back"
              aria-label="Back"
              role="button"
              tabIndex="0"
              onClick={() => handleBackButton()}
            >
              <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                <title>Back</title>
                <g id="Group 18522">
                  <g id="Group 18522_2">
                    <path
                      id="Vector"
                      d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <span className="text">Profile photo</span>
          </div>
        </header>

        <div className="lastSeen-ctas-wrapper">
          <div className="lastSeen-cta">
            <div className="lastSeen-info">
              <div className="lastSeen-heading">
                <span className="heading">Who can see my profile photo</span>
              </div>

              <ul className="lastSeen-list">
                <li className="lastSeen-listItem">
                  <div className="group-check">
                    <input
                      id="everyone"
                      type="radio"
                      aria-label="Check this box"
                      checked={settings?.ProfilePhoto === 0}
                      onChange={(e) => PrivacySettings("ProfilePhoto", 0)}
                    />
                    <span
                      className="checkmark"
                      onClick={(e) => PrivacySettings("ProfilePhoto", 0)}
                    />
                  </div>
                  <label className="description" htmlFor="everyone">
                    Everyone
                  </label>
                </li>

                <li className="lastSeen-listItem">
                  <div className="group-check">
                    <input
                      id="myContacts"
                      type="radio"
                      aria-label="Check this box"
                      checked={settings?.ProfilePhoto === 1}
                      onChange={(e) => PrivacySettings("ProfilePhoto", 1)}
                    />
                    <span
                      className="checkmark"
                      onClick={(e) => PrivacySettings("ProfilePhoto", 1)}
                    />
                  </div>
                  <label className="description" htmlFor="myContacts">
                    My contacts
                  </label>
                </li>

                <li
                  className="lastSeen-listItem"
                  onClick={() => {
                    PrivacySettings("ProfilePhoto", 2);
                    dispatch(setExceptContactType("profile"));
                    dispatch(setSelectedSetting("exceptcontact"));
                    dispatch(setAnimationDirection("right"));
                  }}
                >
                  <div className="group-check">
                    <input
                      id="myContactExcept"
                      type="radio"
                      aria-label="Check this box"
                      checked={settings?.ProfilePhoto === 2}
                    />
                    <span className="checkmark" />
                  </div>
                  <label className="description" htmlFor="myContactExcept">
                    My contacts except...
                  </label>
                </li>

                <li className="lastSeen-listItem">
                  <div className="group-check">
                    <input
                      id="nobody"
                      type="radio"
                      aria-label="Check this box"
                      checked={settings?.ProfilePhoto === 3}
                      onChange={(e) => PrivacySettings("ProfilePhoto", 3)}
                    />
                    <span
                      className="checkmark"
                      onClick={(e) => PrivacySettings("ProfilePhoto", 3)}
                    />
                  </div>
                  <label className="description" htmlFor="nobody">
                    Nobody
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePhoto;
