import React, { useEffect, useState } from "react";
import WhyPost from "./WhyPost";
import MenuPopup from "./MenuPopup";
import RepostPost from "./RepostPost";
import TeparsPost from "./TeparsPost";
import ReportPost from "./ReportPost";
import PostLikeUser from "./PostLikes";
import Spinner from "../Common/Spinner";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ERROR } from "../../Utils/enums";
import axiosRequest from "../../Middleware/api";
import {
  setTepars,
  handleToast,
  setPostDetails,
  setTogglePostAction,
} from "../../Redux/actions";
import "../../assets/css/teparsView.scss";

function TeparsView({
  postContainerRef,
  selectedMenu,
  selectedTeparsType,
  sound,
  setSound,
  setSelectedMenu,
  teparsVideoThumb,
  progress,
  selected,
  handleCancelUpload,
}) {
  const dispatch = useDispatch();

  const UserID = localStorage.getItem("user_id");

  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const scrollToTop = useSelector((state) => state.scrollToTop);
  const postCreateProgress = useSelector((state) => state.postCreateProgress);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState();
  const [postWhy, setPostWhy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postMenu, setPostMenu] = useState(false);
  const [postLikes, setPostLikes] = useState(false);
  const [selectedPost, setSelectedPost] = useState();
  const [selectPostID, setSelectPostID] = useState();
  const [yourRepost, setYourRepost] = useState(false);
  const [reportMenu, setReportMenu] = useState(false);
  const [skeletonLoad, setSkeletonLoad] = useState(false);
  const [yourRepostData, setYourRepostData] = useState([]);
  const [selectedPostData, setSelectedPostData] = useState(null);

  useEffect(() => {
    fetchTepars(1);
    // if (!postContainerRef.current.scrollTop) return;
    // postContainerRef.current.scrollTop = 0;
  }, [selectedTeparsType, selected]);

  const fetchTepars = async (page) => {
    var url;
    // if (
    //   selectedMenu === "profile" &&
    //   selectedTeparsType === null &&
    //   selected === "repost"
    // ) {
    //   url = `post/repost/${UserID}?page=`;
    // } else
    if (selectedMenu === "profile" && selectedTeparsType === null) {
      if (selected === "repost") {
        url = `post/repost/${UserID}?page=`;
      } else {
        url = `post/getuserpost/${UserID}?page=`;
      }
    } else if (
      selectedMenu === "profile" &&
      (selectedTeparsType === "privacy" ||
        selectedTeparsType === "help" ||
        selectedTeparsType === "theme")
    ) {
      url = `post/getuserpost/${UserID}?page=`;
    } else if (
      selectedMenu === "profile" &&
      selectedTeparsType === "archived"
    ) {
      url = `post/archive?page=`;
    } else if (selectedMenu === "profile" && selectedTeparsType === "saved") {
      url = `post/save?page=`;
    } else {
      url = `post/tepars?page=`;
    }
    setLoading(true);
    if (page === 1) setSkeletonLoad(true);

    await axiosRequest
      .get(`${url}${page}`)
      .then((res) => {
        setCount(res.data.totalCount);
        if (res?.data?.totalCount !== 0) {
          let updatedTepars = [...Tepars, ...res.data.data];
          if (page === 1) {
            setSkeletonLoad(true);
            dispatch(setTepars(res.data.data));
          } else {
            dispatch(setTepars(updatedTepars));
          }
          setPage(page + 1);
          const newIsLiked = {};
          const newIsAnimating = {};
          res.data.data.forEach((post) => {
            newIsLiked[post._id] = post.selfLike || false;
            newIsAnimating[post._id] = false;
          });
        } else {
          dispatch(setTepars([]));
        }
        setLoading(false);
        setSkeletonLoad(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setLoading(false);
        setSkeletonLoad(false);
      });
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = postContainerRef.current;
    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      !loading &&
      count !== Tepars?.length
    ) {
      fetchTepars(page);
    }
  };

  const handleRepostModal = (post) => {
    dispatch(setPostDetails(post));
    dispatch(setTogglePostAction("RePost"));
  };

  const repostPopup = (post) => {
    setYourRepost(true);
    setYourRepostData(post);
  };

  const whySeenPost = (post) => {
    setPostMenu(false);
    setPostWhy(true);
    setSelectedPostData(post);
  };

  // For scroll to top of the post view when post is reposted
  useEffect(() => {
    if (scrollToTop) {
      postContainerRef.current.scrollTop = 0;
    }
  }, [scrollToTop]);

  return (
    <>
      {Tepars?.length > 0 ? (
        <div
          className="teparsView"
          onScroll={handleScroll}
          ref={postContainerRef}
        >
          <div className="teparsView-wrapper">
            {postCreateProgress && (
              <div className="upload-progress">
                <div className="upload-info">
                  <div className="upload-info-wrapper">
                    <div className="profile-image">
                      {teparsVideoThumb === null ? (
                        <Spinner />
                      ) : (
                        <img src={teparsVideoThumb} alt="profile" />
                      )}
                    </div>
                    <span className="upload-progress-text">
                      Post upload in progress...
                    </span>
                  </div>
                  <div
                    className="btn btn-close"
                    aria-label="Close"
                    role="button"
                    onClick={handleCancelUpload}
                  >
                    <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
                      <title>Close</title>
                      <g id="Frame 2595">
                        <path
                          id="Shape"
                          d="M24.072 5.69684L24.1924 5.80567C24.7728 6.3861 24.8091 7.30462 24.3012 7.92731L24.1924 8.04771L17.242 14.9981L24.1924 21.9484C24.7728 22.5288 24.8091 23.4473 24.3012 24.07L24.1924 24.1904C23.612 24.7709 22.6934 24.8072 22.0707 24.2993L21.9503 24.1904L15 17.2401L8.04966 24.1904C7.46923 24.7709 6.55071 24.8072 5.92802 24.2993L5.80761 24.1904C5.22718 23.61 5.19091 22.6915 5.69878 22.0688L5.80761 21.9484L12.758 14.9981L5.80761 8.04771C5.22718 7.46729 5.19091 6.54877 5.69878 5.92608L5.80761 5.80567C6.38804 5.22524 7.30656 5.18896 7.92925 5.69684L8.04966 5.80567L15 12.756L21.9503 5.80567C22.5308 5.22524 23.4493 5.18896 24.072 5.69684L24.1924 5.80567L24.072 5.69684Z"
                          fill={
                            theme === "Light"
                              ? "#2a2a2a"
                              : theme === "Dark"
                              ? "#ffffff"
                              : window.matchMedia(
                                  "(prefers-color-scheme: light)"
                                ).matches
                              ? "#2a2a2a"
                              : "#ffffff"
                          }
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="upload-progress-range">
                  <div
                    className="selected-color"
                    style={{ width: `${progress}%` }}
                  />
                </div>
              </div>
            )}
            {/* UpToDate */}
            {/* <div className="uptodate-post">
              <div className="done-image">
                // <img src={tick} alt="you're all caught up" />
                <svg width="72" height="69" viewBox="0 0 72 69" fill="none">
                    <g id="solar:camera-linear">
                    <path id="Vector" d="M8 35.8L25.8182 54L64 15"   stroke={
                          theme === "Light"
                            ? "#2a2a2a"
                            : theme === "Dark"
                            ? "#ffffff"
                            : window.matchMedia("(prefers-color-scheme: light)")
                                .matches
                            ? "#2a2a2a"
                            : "#ffffff"
                        } strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    </svg>
              </div>
              <p className="done-text">You're all caught up</p>
              <p className="done-info">
                You've seen all new posts from the past 3 days.
              </p>
            </div> */}
            {Tepars?.map((post, index) => (
              <TeparsPost
                key={index}
                skeletonLoad={skeletonLoad}
                post={post}
                setPostMenu={setPostMenu}
                setSelectedPost={setSelectedPost}
                setPostLikes={setPostLikes}
                whySeenPost={whySeenPost}
                repostPopup={repostPopup}
                handleRepostModal={handleRepostModal}
                setSelectPostID={setSelectPostID}
                sound={sound}
                setSound={setSound}
                setSelectedMenu={setSelectedMenu}
              />
            ))}
          </div>
          {loading && (
            <div className="teparsView-spinner">
              <Spinner />
            </div>
          )}
          {postMenu && (
            <MenuPopup
              onClose={() => setPostMenu(false)}
              post={selectedPost}
              whySeenPost={(e) => whySeenPost(e)}
              repostPost={(e) => handleRepostModal(e)}
              setReportMenu={setReportMenu}
            />
          )}
          {postLikes && (
            <PostLikeUser
              onClose={setPostLikes}
              selectPostID={selectPostID}
              setSelectPostID={setSelectPostID}
              setSelectedMenu={setSelectedMenu}
            />
          )}
          {reportMenu && (
            <ReportPost
              post={selectedPost}
              reportMenu={reportMenu}
              setReportMenu={setReportMenu}
            />
          )}
          {postWhy && (
            <WhyPost onClose={setPostWhy} userId={selectedPostData?._id} />
          )}
          {yourRepost && (
            <RepostPost onClose={setYourRepost} postData={yourRepostData} />
          )}
        </div>
      ) : (
        <div className="default">
          <div className="wrapper">
            <div className="logo">
              <div className="no-post">
                <div className="no-post-image">
                  <svg width="55" height="55" viewBox="0 0 72 69" fill="none">
                    <path
                      d="M36 45.9336C40.9706 45.9336 45 42.128 45 37.4336C45 32.7392 40.9706 28.9336 36 28.9336C31.0294 28.9336 27 32.7392 27 37.4336C27 42.128 31.0294 45.9336 36 45.9336Z"
                      stroke={
                        theme === "Light"
                          ? "#2a2a2a"
                          : theme === "Dark"
                          ? "#ffffff"
                          : window.matchMedia("(prefers-color-scheme: light)")
                              .matches
                          ? "#2a2a2a"
                          : "#ffffff"
                      }
                      strokeWidth="2.25"
                    />
                    <path
                      d="M29.334 60.1017H42.666C52.029 60.1017 56.712 60.1017 60.075 58.0192C61.5261 57.121 62.7758 55.9618 63.753 54.6079C66 51.4912 66 47.1477 66 38.4664C66 29.7822 66 25.4415 63.753 22.3249C62.7759 20.9708 61.5261 19.8117 60.075 18.9135C57.915 17.5734 55.209 17.0945 51.066 16.9245C49.089 16.9245 47.388 15.5362 47.001 13.737C46.7052 12.4192 45.9368 11.2381 44.8255 10.3936C43.7143 9.54898 42.3284 9.09265 40.902 9.10169H31.098C28.134 9.10169 25.581 11.0425 24.999 13.737C24.612 15.5362 22.911 16.9245 20.934 16.9245C16.794 17.0945 14.088 17.5762 11.925 18.9135C10.4749 19.8119 9.22612 20.971 8.25 22.3249C6 25.4415 6 29.7822 6 38.4664C6 47.1477 6 51.4884 8.247 54.6079C9.219 55.9565 10.467 57.1154 11.925 58.0192C15.288 60.1017 19.971 60.1017 29.334 60.1017Z"
                      stroke={
                        theme === "Light"
                          ? "#2a2a2a"
                          : theme === "Dark"
                          ? "#ffffff"
                          : window.matchMedia("(prefers-color-scheme: light)")
                              .matches
                          ? "#2a2a2a"
                          : "#ffffff"
                      }
                      strokeWidth="2.25"
                    />
                    <path
                      d="M57 28.9336H54"
                      stroke={
                        theme === "Light"
                          ? "#2a2a2a"
                          : theme === "Dark"
                          ? "#ffffff"
                          : window.matchMedia("(prefers-color-scheme: light)")
                              .matches
                          ? "#2a2a2a"
                          : "#ffffff"
                      }
                      strokeWidth="2.25"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <span className="title">No posts yet</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TeparsView;
