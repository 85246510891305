import React, { useEffect, useState, useRef } from "react";
import axiosRequest from "../../Middleware/api";
import { useDispatch } from "react-redux";
import { ERROR } from "../../Utils/enums";

import Spinner from "../Common/Spinner";
import { handleToast } from "../../Redux/actions";

import { convertToAgoFormat, getUserProfilePic } from "../../Utils/helpers";

import "../../assets/css/storyViewed.scss";

function StoryViewed({ selectStoryId, onClose }) {
  const popupRef = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [viewedData, setViewedData] = useState([]);

  const getViewed = (storyId) => {
    setLoading(true);
    axiosRequest
      .get(`story/webseen/${storyId}`)
      .then((res) => {
        if (res?.data?.status === "200") {
          const sortedData = res?.data?.data.map((viewed) => ({
            ...viewed,
            seenUsers: viewed.seenUsers.sort(
              (a, b) => new Date(b.seenAt) - new Date(a.seenAt)
            ),
          }));
          setViewedData(sortedData);
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectStoryId) {
      getViewed(selectStoryId);
    }
  }, [selectStoryId]);
  const handleClickOutside = (event) => {
    if (popupRef.current === event.target) {
      onClose();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const totalViews = viewedData.reduce(
    (total, item) => total + item.seenUsers.length,
    0
  );

  return (
    <div className="storyViewed" ref={popupRef}>
      <div className="storyViewed-container">
        <div className="heading">
          <div className="hidden" aria-hidden="true" />
          <h4 className="title">Views {totalViews}</h4>
          <div
            className="btn btn-close"
            aria-label="Close"
            role="button"
            tabIndex="0"
            onClick={onClose}
          >
            <svg width="18" height="18" viewBox="0 0 25 25" fill="none">
              <title>Close</title>
              <g id="iconoir:cancel">
                <path
                  id="Vector"
                  d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                  stroke="white"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </div>
        </div>

        {loading ? (
          <div className="no-data">
            <Spinner />
          </div>
        ) : totalViews > 0 ? (
          viewedData.map((viewed, index) =>
            viewed?.seenUsers?.map((user, userIndex) => (
              <div className="profiles" key={`${index}-${userIndex}`}>
                <div className="profile" tabIndex="0">
                  <div className="profile-image">
                    <img
                      className="profile-avatar"
                      src={getUserProfilePic(user?.profile)}
                      alt="Profile Avatar"
                    />
                  </div>
                  <div className="profile-info-container">
                    <div className="profile-info">
                      <span className="name">{user?.name}</span>
                      <span className="description">
                        {convertToAgoFormat(user?.seenAt)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )
        ) : (
          <p className="no-views">No Views yet</p>
        )}
      </div>
    </div>
  );
}

export default StoryViewed;
