import React from 'react';

function StatusCircle({ stories, gapLength = 5 }) {
  const radius = 60; 
  const strokeWidth = 4; 
  const circumference = 2 * Math.PI * radius;
  const segmentLength = stories?.length === 1
    ? circumference
    : (circumference / stories?.length) - gapLength;

  const segments = stories?.map((story, index) => {
    const offset = (index * (segmentLength + gapLength)) % circumference;
    return {
      key: index,
      strokeDasharray: `${segmentLength} ${circumference - segmentLength}`,
      strokeDashoffset: offset,
      stroke: story?.isSeen ? 'grey' : 'var(--color-primary-d)',
    };
  });

  return (
    <svg
      width={2 * (radius + strokeWidth)}
      height={2 * (radius + strokeWidth)}
      viewBox={`0 0 ${2 * (radius + strokeWidth)} ${2 * (radius + strokeWidth)}`}
      className="statusCircle"
    >
      {segments?.map(({ key, strokeDasharray, strokeDashoffset, stroke }) => (
        <circle
          key={key}
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius}
          fill="none"
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={-strokeDashoffset}
        />
      ))}
    </svg>
  );
}

export default StatusCircle;

