import { React, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAnimationDirection, setSelectedSetting } from "../../Redux/actions";
import "../../assets/css/mediaAutoDownload.scss";

function MediaAutoDownload() {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  const handleBackButton = () => {
    dispatch(setAnimationDirection("right"));
    dispatch(setSelectedSetting(""));
    setIsMounted(false);
  };

  useEffect(() => {
    const element = document.querySelector(".mediaAutoDownload");

    const handleAnimationEnd = () => {
      if (!isMounted) {
      }
    };

    if (isMounted) {
      element.classList.add("open");
      element.classList.remove("close");
      element.removeEventListener("animationend", handleAnimationEnd);
    } else {
      element.classList.add("close");
      element.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      element.classList.remove("open", "close");
      element.removeEventListener("animationend", handleAnimationEnd);
    };
  }, [isMounted]);

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="mediaAutoDownload">
      <div className="mediaAutoDownload-wrapper">
        <header className="header">
          <div className="back">
            <div
              className="btn btn-back"
              aria-label="Back"
              role="button"
              tabIndex="0"
              onClick={() => handleBackButton()}
            >
              <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                <title>Back</title>
                <g id="Group 18522">
                  <g id="Group 18522_2">
                    <path
                      id="Vector"
                      d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <span className="text">Media auto download</span>
          </div>
        </header>

        <div className="mediaAutoDownload-options">
          <ul className="list">
            <li className="mediaAutoDownload-listItem">
              <div className="group-check">
                <input
                  type="radio"
                  aria-label="Check this box"
                  checked={isChecked}
                  id="photos"
                />
                <span className="checkmark" onClick={handleCheckmarkClick} />
              </div>
              <label
                className="description"
                htmlFor="photos"
                //   onClick={handleCheckmarkClick}
              >
                Photos
              </label>
            </li>

            <li className="mediaAutoDownload-listItem">
              <div className="group-check">
                <input
                  type="radio"
                  aria-label="Check this box"
                  checked={isChecked}
                  id="audio"
                />
                <span className="checkmark" onClick={handleCheckmarkClick} />
              </div>
              <label
                className="description"
                htmlFor="audio"
                //   onClick={handleCheckmarkClick}
              >
                Audio
              </label>
            </li>

            <li className="mediaAutoDownload-listItem">
              <div className="group-check">
                <input
                  type="radio"
                  aria-label="Check this box"
                  checked={isChecked}
                  id="videos"
                />
                <span className="checkmark" onClick={handleCheckmarkClick} />
              </div>
              <label
                className="description"
                htmlFor="videos"
                //   onClick={handleCheckmarkClick}
              >
                Videos
              </label>
            </li>

            <li className="mediaAutoDownload-listItem">
              <div className="group-check">
                <input
                  type="radio"
                  aria-label="Check this box"
                  checked={isChecked}
                  id="documents"
                />
                <span className="checkmark" onClick={handleCheckmarkClick} />
              </div>
              <label
                className="description"
                htmlFor="documents"
                //   onClick={handleCheckmarkClick}
              >
                Documents
              </label>
            </li>
          </ul>

          <div className="mediaAutoDownload-options-info">
            <p className="info">
              Voice messages are always automatically downloaded for the best
              communication experience.
            </p>
          </div>
        </div>

        <div className="mediaAutoDownload-cta">
          <div className="reset-autoDownload" tabIndex="0" role="listitem">
            <div className="reset-autoDownload-listItem-image">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M20 8C18.597 5.04 15.537 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12M21 3V9H15"
                  stroke="white"
                  strokeWidth="2"
                />
              </svg>
            </div>
            <div className="reset-autoDownload-listItem-info">
              <span className="reset-autoDownload-listItem-name">
                Reset auto-download settings
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MediaAutoDownload;
