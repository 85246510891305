import { combineReducers } from "redux";

const Contacts = [];
const Users = [];
const SavedContacts = [];
const Messages = [];
const Loading = false;
const DBConnectionObj = [];
const InboxContacts = [];
const ChatBoxContact = [];
const MessageContacts = [];
const MessageMedia = [];
const Groups = [];
const SelfContact = [];
const Tepars = [];
const UserTepars = [];
const User = null;
const Theme = "";
const Toast = null;
const index = 0;
const direction = "left";
const time = 0;
const styleWidth = 0;
const SettingOptions = false;
const SelectedSetting = "";
const PrivacyOption = "";
const Settings = [];
const ExceptContactType = "";
const Notification = false;
const Notifications = [];
const CurrentLocation = null;
const AuthSync = null;
const SelectedTepars = null;
const PostDetails = null;
const ViewProfilePhoto = null;
const TogglePostAction = null;
const PostCreateProgress = false;
const AwsUploadedMedia = [];
const TeparsProgress = 0;
const ToggleViewOtherProfile = false;
const ScrollToTop = false;
const teparsPage = 1;
const teparsCount = 0;
const initialState = {
  storyList: [],
  myStatus: [],
};

const reset = () => ({
  Contacts: [],
  Users: [],
  SavedContacts: [],
  Messages: [],
  Loading: false,
  DBConnectionObj: [],
  InboxContacts: [],
  ChatBoxContact: [],
  MessageContacts: [],
  MessageMedia: [],
  Groups: [],
  SelfContact: [],
  Tepars: [],
  User: null,
  Theme: "",
  Toast: null,
  index: 0,
  direction: "left",
  SettingOptions: false,
  SelectedSetting: "",
  PrivacyOption: "",
  Settings: [],
  ExceptContactType: "",
  Notification: false,
  CurrentLocation: null,
  AuthSync: null,
});

const dbConnectionReducer = (state = DBConnectionObj, action) => {
  switch (action.type) {
    case "SET_DB_CONNECTION_OBJ":
      return action.payload;
    default:
      return state;
  }
};

const messagesReducer = (state = Messages, action) => {
  switch (action.type) {
    case "SET_MESSAGES":
      return action.payload;
    // return [...state, action.payload];
    default:
      return state;
  }
};

const contactsReducer = (state = Contacts, action) => {
  switch (action.type) {
    case "SET_CONTACTS":
      return action.payload;
    default:
      return state;
  }
};

const usersReducer = (state = Users, action) => {
  switch (action.type) {
    case "SET_USERS":
      return action.payload;
    default:
      return state;
  }
};

const savedContactsReducer = (state = SavedContacts, action) => {
  switch (action.type) {
    case "SET_SAVED_CONTACTS":
      return action.payload;
    default:
      return state;
  }
};

const loaderReducer = (state = Loading, action) => {
  switch (action.type) {
    case "LOADER":
      return action.payload;
    default:
      return state;
  }
};

const inboxContactsReducer = (state = InboxContacts, action) => {
  switch (action.type) {
    case "SET_INBOX_CONTACTS":
      return action.payload;
    default:
      return state;
  }
};

const chatBoxContactReducer = (state = ChatBoxContact, action) => {
  switch (action.type) {
    case "SET_CHAT_BOX_CONTACT":
      return action.payload;
    case "CLEAR_CHAT_BOX_CONTACT":
      return action.payload;
    default:
      return state;
  }
};

const messageContactReducer = (state = MessageContacts, action) => {
  switch (action.type) {
    case "SET_MESSAGE_CONTACTS":
      return action.payload;
    default:
      return state;
  }
};

const messageMediaReducer = (state = MessageMedia, action) => {
  switch (action.type) {
    case "SET_MESSAGE_MEDIA":
      return action.payload;
    default:
      return state;
  }
};

const groupsReducer = (state = Groups, action) => {
  switch (action.type) {
    case "SET_GROUPS":
      return action.payload;
    default:
      return state;
  }
};

const selfContactReducer = (state = SelfContact, action) => {
  switch (action.type) {
    case "SET_SELF_CONTACT":
      return action.payload;
    default:
      return state;
  }
};

// const teparsReducer = (state = Tepars, action) => {
//   switch (action.type) {
//     case "SET_TEPARS":
//       return action.payload;
//     default:
//       return state;
//   }
// };

const teparsReducer = (state = Tepars, action) => {
  switch (action.type) {
    case "SET_TEPARS":
      return action.payload;
    case "INCREMENT_POST_COMMENTS":
      return state.map((post) =>
        post._id === action.payload
          ? { ...post, comments: post.comments + 1 }
          : post
      );
    case "DELETE_POST_COMMENTS":
      return state.map((post) =>
        post._id === action.payload
          ? { ...post, comments: post.comments - 1 }
          : post
      );
    default:
      return state;
  }
};

const userTeparsReducer = (state = UserTepars, action) => {
  switch (action.type) {
    case "SET_USER_TEPARS":
      return action.payload;
    case "INCREMENT_POST_COMMENTS":
      return state.map((post) =>
        post._id === action.payload
          ? { ...post, comments: post.comments + 1 }
          : post
      );
    case "DELETE_POST_COMMENTS":
      return state.map((post) =>
        post._id === action.payload
          ? { ...post, comments: post.comments - 1 }
          : post
      );
    default:
      return state;
  }
};

const userReducer = (state = User, action) => {
  switch (action.type) {
    case "SET_USER":
      return action.payload;
    default:
      return state;
  }
};

const themeReducer = (state = Theme, action) => {
  switch (action.type) {
    case "SET_THEME":
      return action.payload;
    default:
      return state;
  }
};

const manageStory = (state = initialState, action) => {
  switch (action?.type) {
    case "ADD_STORIES":
      return {
        ...state,
        storyList: action.payload.storyList,
      };

    case "ADD_TO_MY_STATUS":
      return {
        ...state,
        myStatus: action.payload.myStatus,
      };

    case "UPDATE_MY_STATUS": {
      const storyUserId = action.payload?.userId;
      const newStory = action.payload?.newStory;
      const updatedMyStatus = [...state.myStatus];
      const userStoryIndex = updatedMyStatus.findIndex(
        (storyGroup) =>
          storyGroup[0]?.userId?._id === storyUserId ||
          storyGroup[0]?.webUserId?._id === storyUserId
      );

      if (userStoryIndex > -1) {
        updatedMyStatus[userStoryIndex] = [
          ...updatedMyStatus[userStoryIndex],
          newStory,
        ];
      } else {
        updatedMyStatus.push([newStory]);
      }

      return {
        ...state,
        myStatus: updatedMyStatus,
      };
    }

    case "UPDATE_STORY": {
      const updatedStoryList = state?.storyList?.map((storyGroup) => {
        if (
          storyGroup[0]?.userId?._id === action?.payload?.userId ||
          storyGroup[0]?.webUserId?._id === action?.payload?.userId
        ) {
          return [...storyGroup, action?.payload?.newStory];
        }
        return storyGroup;
      });

      if (
        !updatedStoryList?.some(
          (storyGroup) =>
            storyGroup[0]?.userId?._id === action?.payload?.userId ||
            storyGroup[0]?.webUserId?._id === action?.payload?.userId
        )
      ) {
        updatedStoryList.push([action.payload.newStory]);
      }

      return {
        ...state,
        storyList: updatedStoryList,
      };
    }

    case "DELETE_STORY": {
      const updatedStoryList = state.storyList?.map((storyGroup) =>
        storyGroup?.filter((story) => story?._id !== action.payload)
      );
      return {
        ...state,
        storyList: updatedStoryList?.filter(
          (storyGroup) => storyGroup?.length > 0
        ),
      };
    }

    case "DELETE_MY_STATUS": {
      const updatedMyStatus = state.myStatus?.map((storyGroup) =>
        storyGroup?.filter((story) => story?._id !== action.payload)
      );
      return {
        ...state,
        myStatus: updatedMyStatus?.filter(
          (storyGroup) => storyGroup?.length > 0
        ),
      };
    }

    case "UPDATE_SEEN_USERS": {
      const { storyId, seenUsers } = action.payload;
      const updatedMyStatus = state.myStatus.map((statusGroup) => {
        return statusGroup.map((status) => {
          if (status.storyId === storyId) {
            if (typeof status.seenUsers === "number") {
              return {
                ...status,
                seenUsers: status.seenUsers + 1,
              };
            } else if (Array.isArray(status.seenUsers)) {
              return {
                ...status,
                seenUsers: seenUsers,
              };
            }
          }
          return status;
        });
      });
      return {
        ...state,
        myStatus: updatedMyStatus,
      };
    }

    case "MARK_STORY_AS_SEEN": {
      const storyId = action?.payload;
      const updatedStoryList = state?.storyList?.map((storyGroup) => {
        return storyGroup.map((story) => {
          if (story?._id === storyId) {
            return {
              ...story,
              isSeen: true,
            };
          }
          return story;
        });
      });

      const updatedMyStatus = state?.myStatus?.map((storyGroup) => {
        return storyGroup.map((story) => {
          if (story?._id === storyId) {
            return {
              ...story,
              isSeen: true,
            };
          }
          return story;
        });
      });

      return {
        ...state,
        storyList: updatedStoryList,
        myStatus: updatedMyStatus,
      };
    }
    case "UPDATE_STORY_LIST":
      return {
        ...state,
        storyList: action?.payload,
      };

    default:
      return state;
  }
};

const messageCtaNotification = (state = Toast, action) => {
  switch (action.type) {
    case "SET_TOAST":
      return action.payload;
    default:
      return state;
  }
};

const currentIndexReducer = (state = index, action) => {
  switch (action.type) {
    case "SET_CURRENT_INDEX":
      return action.payload;
    default:
      return state;
  }
};

const animationDirectionReducer = (state = direction, action) => {
  switch (action.type) {
    case "SET_ANIMATION_DIRECTION":
      return action.payload;
    default:
      return state;
  }
};

const videoCurrentTimeReducer = (state = time, action) => {
  switch (action.type) {
    case "SET_VIDEO_CURRENT_TIME":
      return action.payload;
    default:
      return state;
  }
};

const settingOptionsReducer = (state = SettingOptions, action) => {
  switch (action.type) {
    case "SET_SETTING_OPTIONS":
      return action.payload;
    default:
      return state;
  }
};

const selectedsettingReducer = (state = SelectedSetting, action) => {
  switch (action.type) {
    case "SET_SELECTED_SETTING":
      return action.payload;
    default:
      return state;
  }
};

const privacyOptionReducer = (state = PrivacyOption, action) => {
  switch (action.type) {
    case "SET_PRIVACY_OPTION":
      return action.payload;
    default:
      return state;
  }
};

const settingsReducer = (state = Settings, action) => {
  switch (action.type) {
    case "SET_SETTINGS":
      return action.payload;
    default:
      return state;
  }
};

const exceptContactTypeReducer = (state = ExceptContactType, action) => {
  switch (action.type) {
    case "SET_EXCEPT_CONTACT_TYPE":
      return action.payload;
    default:
      return state;
  }
};

const notificationReducer = (state = Notification, action) => {
  switch (action.type) {
    case "SET_NOTIFICATION":
      return action.payload;
    default:
      return state;
  }
};

const notificationsReducer = (state = Notifications, action) => {
  switch (action.type) {
    case "SET_NOTIFICATIONS":
      return action.payload;
    default:
      return state;
  }
};

const currentLocationReducer = (state = CurrentLocation, action) => {
  switch (action.type) {
    case "SET_CURRENT_LOCATION":
      return action.payload;
    default:
      return state;
  }
};

const authenticateSyncReducer = (state = AuthSync, action) => {
  switch (action.type) {
    case "SET_AUTHENTICATE_SYNC":
      return action.payload;
    default:
      return state;
  }
};

const selectedTeparsReducer = (state = SelectedTepars, action) => {
  switch (action.type) {
    case "SET_SELECTED_TEPARS":
      return action.payload;
    default:
      return state;
  }
};

const postDetailsReducer = (state = PostDetails, action) => {
  switch (action.type) {
    case "SET_POST_DETAILS":
      return action.payload;
    default:
      return state;
  }
};

const viewProfilePhotoReducer = (state = ViewProfilePhoto, action) => {
  switch (action.type) {
    case "SET_VIEW_PROFILE_PHOTO":
      return action.payload;
    default:
      return state;
  }
};

const togglePostActionReducer = (state = TogglePostAction, action) => {
  switch (action.type) {
    case "SET_TOGGLE_POST_ACTION":
      return action.payload;
    default:
      return state;
  }
};

const postCreateProgressReducer = (state = PostCreateProgress, action) => {
  switch (action.type) {
    case "SET_POST_CREATE_PROGRESS":
      return action.payload;
    default:
      return state;
  }
};

const awsUploadedMediaReducer = (state = AwsUploadedMedia, action) => {
  switch (action.type) {
    case "SET_AWS_UPLOADED_MEDIA":
      return action.payload;
    default:
      return state;
  }
};

const teparsProgressReducer = (state = TeparsProgress, action) => {
  switch (action.type) {
    case "SET_TEPARS_PROGRESS":
      return action.payload;
    default:
      return state;
  }
};

const toggleViewOtherProfileReducer = (
  state = ToggleViewOtherProfile,
  action
) => {
  switch (action.type) {
    case "SET_TOGGLE_VIEW_OTHER_PROFILE":
      return action.payload;
    default:
      return state;
  }
};

const scrollToTopReducer = (state = ScrollToTop, action) => {
  switch (action.type) {
    case "SET_SCROLL_TO_TOP":
      return action.payload;
    default:
      return state;
  }
};

const teparsPageReducer = (state = teparsPage, action) => {
  switch (action.type) {
    case "SET_TEPARS_PAGE":
      return action.payload;
    default:
      return state;
  }
};

const teparsCountReducer = (state = teparsCount, action) => {
  switch (action.type) {
    case "SET_TEPARS_COUNT":
      return action.payload;
    default:
      return state;
  }
};

const resetReducer = (state = reset, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return reset();
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  loader: loaderReducer,
  groups: groupsReducer,
  contacts: contactsReducer,
  users: usersReducer,
  messages: messagesReducer,
  selfContact: selfContactReducer,
  messageMedia: messageMediaReducer,
  savedContacts: savedContactsReducer,
  inboxContacts: inboxContactsReducer,
  dbConnectionObj: dbConnectionReducer,
  chatBoxContact: chatBoxContactReducer,
  messageContacts: messageContactReducer,
  tepars: teparsReducer,
  user: userReducer,
  theme: themeReducer,
  stories: manageStory,
  toast: messageCtaNotification,
  currentIndex: currentIndexReducer,
  animationDirection: animationDirectionReducer,
  videoCurrentTime: videoCurrentTimeReducer,
  settingOptions: settingOptionsReducer,
  selectedSetting: selectedsettingReducer,
  privacyOption: privacyOptionReducer,
  settings: settingsReducer,
  exceptContactType: exceptContactTypeReducer,
  notification: notificationReducer,
  notifications: notificationsReducer,
  currentLocation: currentLocationReducer,
  authSync: authenticateSyncReducer,
  selectedTepars: selectedTeparsReducer,
  postDetails: postDetailsReducer,
  viewProfilePhoto: viewProfilePhotoReducer,
  togglePost: togglePostActionReducer,
  postCreateProgress: postCreateProgressReducer,
  awsUploadedMedia: awsUploadedMediaReducer,
  teparsProgress: teparsProgressReducer,
  toggleViewOtherProfile: toggleViewOtherProfileReducer,
  scrollToTop: scrollToTopReducer,
  userTepars: userTeparsReducer,
  teparsPage: teparsPageReducer,
  teparsCount: teparsCountReducer,
  reset: resetReducer,
});
export default rootReducer;
