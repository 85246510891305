import React, { useState } from "react";
import Spinner from "../Common/Spinner";
import { useSelector } from "react-redux";
import { getGridTepars } from "../../Utils/helpers";
import "../../assets/css/repost.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function Repost({ userRepost, loading, postLoading, handlePostSelect }) {
  const theme = useSelector((state) => state.theme);
  const [imageErrors, setImageErrors] = useState({});

  const handleImageError = (index) => {
    setImageErrors((prev) => ({ ...prev, [index]: true }));
  };

  return (
    <>
      {postLoading ? (
        <div className="tepars-profile-media">
          <div className="media-container" style={{ rowGap: "0px" }}>
            {Array.from({ length: 21 }).map((posts, index) => (
              <SkeletonTheme
                baseColor={
                  theme === "Light"
                    ? ""
                    : theme === "Dark"
                    ? "#202020"
                    : window.matchMedia("(prefers-color-scheme: light)").matches
                    ? ""
                    : "#202020"
                }
                highlightColor={
                  theme === "Light"
                    ? ""
                    : theme === "Dark"
                    ? "#2A2A2A"
                    : window.matchMedia("(prefers-color-scheme: light)").matches
                    ? ""
                    : "#2A2A2A"
                }
              >
                <div className="media">
                  <Skeleton width={130} height={130} />
                </div>
              </SkeletonTheme>
            ))}
          </div>
        </div>
      ) : (
        <>
          {userRepost && userRepost?.length > 0 ? (
            <div className="tepars-profile-media">
              <div className="media-container">
                {userRepost?.map((post, index) => (
                  <div
                    className="media"
                    key={index}
                    tabIndex="0"
                    role="listitem"
                    onClick={() => handlePostSelect(post?._id)}
                  >
                    {getGridTepars(post.media[0]) && !imageErrors[index] ? (
                      <img
                        src={getGridTepars(post.media[0])}
                        alt="post media"
                        onError={() => handleImageError(index)} 
                      />
                    ) : (
                      <div className="blank-image"></div>
                    )}

                    {post?.media?.length > 1 && (
                      <div className="media-type">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 26 25"
                          fill="none"
                        >
                          <path
                            d="M17.167 3.125H4.66699V16.6667"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.83398 7.29175H21.334V19.7917C21.334 20.3443 21.1145 20.8742 20.7238 21.2649C20.3331 21.6556 19.8032 21.8751 19.2507 21.8751H10.9173C10.3648 21.8751 9.83488 21.6556 9.44418 21.2649C9.05348 20.8742 8.83398 20.3443 8.83398 19.7917V7.29175Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    )}
                    {post?.media[0]?.type === "Video" && (
                      <div className="media-type">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <rect
                            x="3.42861"
                            y="2.00002"
                            width="13.1429"
                            height="13.1429"
                            rx="1.57143"
                            stroke="white"
                            stroke-width="1.14286"
                          />
                          <path
                            d="M4.28564 17.1429L15.7142 17.1429"
                            stroke="white"
                            stroke-width="1.14286"
                            stroke-linecap="round"
                          />
                          <path
                            d="M12.9285 8.69514L8.64279 11.1695C8.54755 11.2245 8.4285 11.1558 8.4285 11.0458V6.09706C8.4285 5.98709 8.54755 5.91836 8.64279 5.97334L12.9285 8.4477C13.0237 8.50269 13.0237 8.64015 12.9285 8.69514Z"
                            stroke="white"
                            stroke-width="1.14286"
                          />
                        </svg>
                      </div>
                    )}

                    {/* <div className="story-viewed-number">
                      <div className="viewIcon">
                        <img src={view} alt="view" />
                        <span className="viewed-number">12</span>
                      </div>
                    </div> */}
                  </div>
                ))}
              </div>
              {loading && <Spinner />}
            </div>
          ) : (
            <div className="repost-post">
              <div className="default-repost-wrapper">
                <div className="default-repost">
                  <div className="default-repost-image">
                    <svg width="55" height="55" viewBox="0 0 71 71" fill="none">
                      <path
                        d="M57.164 31.7275C56.7173 28.2996 55.5294 25.0087 53.6822 22.0812C47.7104 12.5437 36.7014 7.97514 25.793 10.4697C15.1061 12.9098 7.1246 21.9925 5.9544 33.0434C4.32418 48.4111 17.3573 61.9937 32.866 61.1355C38.309 60.8834 43.5332 58.9282 47.7938 55.5485C48.0846 55.3003 48.4018 55.0844 48.7397 54.9049C49.8898 54.3671 51.2929 54.7247 51.9226 55.7345C52.6413 56.8788 52.4458 57.9344 51.4338 58.7954C47.9326 61.7871 43.778 63.9244 39.3006 65.0375C19.0508 70.1438 -0.224335 54.2498 1.06086 33.5183C1.95792 18.8973 13.4988 6.82227 28.1334 5.19739C44.5564 3.37513 59.1852 14.3516 61.9339 30.5975C62.0834 31.4729 62.4112 31.7161 63.2191 31.6703C64.0809 31.6216 64.9449 31.6216 65.8067 31.6703C68.311 31.8162 69.5243 33.9589 68.2966 36.133C66.3914 39.4914 64.4612 42.8346 62.506 46.1626C61.1518 48.4712 58.5987 48.5312 57.233 46.2513C55.2242 42.8985 53.2595 39.5201 51.3389 36.1158C50.1083 33.9245 51.3676 31.8248 53.892 31.7218C54.9271 31.6932 55.9593 31.7275 57.164 31.7275Z"
                        fill={
                          theme === "Light"
                            ? "#2a2a2a"
                            : theme === "Dark"
                            ? "#ffffff"
                            : window.matchMedia("(prefers-color-scheme: light)")
                                .matches
                            ? "#2a2a2a"
                            : "#ffffff"
                        }
                      />
                      <path
                        d="M21.9855 35.1347C21.9855 31.0896 21.9855 27.0416 21.9855 22.9965C21.9855 21.4574 22.4427 21.1885 23.7912 21.9638C30.9505 26.0661 38.104 30.1808 45.2519 34.3079C46.5198 35.0403 46.517 35.5638 45.2519 36.3105C38.0983 40.43 30.9399 44.5409 23.7768 48.6432C22.4571 49.4013 21.997 49.1181 21.9941 47.5704C21.9798 43.4166 21.9855 39.2771 21.9855 35.1347Z"
                        fill={
                          theme === "Light"
                            ? "#2a2a2a"
                            : theme === "Dark"
                            ? "#ffffff"
                            : window.matchMedia("(prefers-color-scheme: light)")
                                .matches
                            ? "#2a2a2a"
                            : "#ffffff"
                        }
                      />
                    </svg>
                  </div>
                  <span className="default-text">No reposts yet</span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Repost;
