import React, { useRef } from "react";
import "../../assets/css/profileMenu.scss";

function ProfileMenu({
  ddref,
  user,
  updateUser,
  setcloseAlert,
  setCloseCamera,
  setProfileMenu,
  setProfilePhoto,
  setlocalstream,
  setAskPermission,
  setProfilePhotoMenu,
  setHaveCamaraPermissions,
}) {
  const fileInputRef = useRef(null);

  const handleFileSelected = async (e) => {
    const selectedFile = URL.createObjectURL(e.target.files[0]);
    setProfilePhoto(selectedFile);
    setProfilePhotoMenu("adjust");
  };

  const handleImageClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const checkPermissions = () => {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions
        .query({ name: "camera" })
        .then((cameraPermission) => {
          if (cameraPermission.state === "prompt") {
            setTimeout(() => {
              setAskPermission(true);
            }, 2000);
          }
        })
        .catch((error) => {
          console.error("Error querying camera permission:", error);
        });
    }
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        setlocalstream(stream);

        const tracks = stream.getTracks();
        if (tracks.some((track) => track.readyState === "live")) {
          setAskPermission(false);
        }
        setHaveCamaraPermissions((prevState) => !prevState.havePermissions);
      })

      .catch((err) => {
        if (err.name === "NotAllowedError" || err.name === "NotFoundError") {
          setAskPermission(false);
          setHaveCamaraPermissions(false);
        } else {
          console.log(`${err.name}: ${err.message}`);
        }
      });
  };

  const handleCamera = () => {
    handleSelectMenu("take");
    setCloseCamera(false);
    setTimeout(() => {
      setcloseAlert(false);
    }, 500);
    checkPermissions();
  };

  const handleSelectMenu = (option) => {
    setProfilePhotoMenu(option);
    setProfileMenu(false);
  };

  return (
    <>
      <div ref={ddref} className="profileMenu">
        <ul className="profile-menu-list">
          {user?.profile && (
            <li onClick={() => handleSelectMenu("view")}>View photo</li>
          )}
          <li onClick={() => handleCamera("take")}>Take photo</li>
          <li onClick={(e) => handleImageClick(e)}>Upload photo</li>
          {user?.profile && (
            <li onClick={() => updateUser("")}>Remove photo</li>
          )}
        </ul>
      </div>
      <input
        ref={fileInputRef}
        accept="image/*"
        type="file"
        style={{ display: "none" }}
        onChange={handleFileSelected}
      />
    </>
  );
}

export default ProfileMenu;
