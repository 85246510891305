import React, { useCallback, useRef } from "react";
import Webcam from "react-webcam";
import { VideoConstraints } from "../../Utils/helpers";
import "../../assets/css/takeProfilePhoto.scss";

function TakeProfilePhoto({
  localstream,
  setProfilePhoto,
  setProfilePhotoMenu,
}) {
  const webcamRef = useRef(null);

  const capture = useCallback(async () => {
    const base64img = webcamRef.current.getScreenshot({
      width: 1920,
      height: 1080,
    });
    setProfilePhoto(base64img);
    setProfilePhotoMenu("adjust");

    if (localstream && localstream.getTracks) {
      localstream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }, []);

  return (
    <div className="takeProfilePhoto">
      <div className="dialog">
        <div className="header">
          <h4>Take Photo</h4>
          <div
            className="btn btn-close"
            aria-label="close"
            tabIndex="0"
            onClick={() => {
              setProfilePhotoMenu(null);
              if (localstream && localstream.getTracks) {
                localstream.getTracks().forEach((track) => {
                  track.stop();
                });
              }
            }}
          >
            <svg width="20" height="20" viewBox="0 0 25 25" fill="none">
              <title>Close</title>
              <g id="iconoir:cancel">
                <path
                  id="Vector"
                  d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                  stroke="white"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </div>
        </div>

        <div className="photo-container">
          <Webcam
            ref={webcamRef}
            imageSmoothing={true}
            videoConstraints={VideoConstraints}
            screenshotFormat="image/jpeg"
          />
        </div>

        <div className="extra-space">
          <div className="camera-icon" tabIndex="0" onClick={capture}>
            <svg width="30" height="31" viewBox="0 0 30 31" fill="none">
              <title>Take Photo</title>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.70393 3.00686L9.70308 3.00856L8.61332 5.23244L8.60898 5.2413L8.60891 5.24126C8.09111 6.25316 7.05115 6.91826 5.90415 6.91826C2.94911 6.91826 0.595134 9.44583 0.786809 12.4424L0.786876 12.4435L1.57521 25.1326L1.57538 25.1352C1.66151 26.6336 2.09969 27.931 2.9486 28.8485C3.78622 29.7539 5.08867 30.3596 7.05634 30.3596H22.9444C24.9119 30.3596 26.2103 29.7541 27.0461 28.849C27.8936 27.9311 28.3321 26.6329 28.4255 25.1326L28.4255 25.1325L29.2139 12.4435L29.2139 12.4433C29.2582 11.7331 29.1584 11.0213 28.9206 10.3521C28.6828 9.68284 28.3122 9.07066 27.8322 8.55316C27.3523 8.03569 26.7731 7.62392 26.1308 7.34288C25.4885 7.06186 24.7964 6.91742 24.0973 6.91826L24.0966 6.91826C22.9496 6.91826 21.9097 6.25316 21.3919 5.24126L21.3874 5.23248L21.3874 5.23244L20.2977 3.00856L20.2968 3.00677C20.0043 2.42157 19.4578 1.85678 18.7906 1.43682C18.1222 1.0161 17.3846 0.773438 16.7438 0.773438H13.2721C12.6228 0.773438 11.8812 1.01672 11.2115 1.43717C10.5427 1.85703 9.99634 2.42173 9.70393 3.00686ZM12.8991 7.60089C12.5848 7.60089 12.3301 7.85562 12.3301 8.16985C12.3301 8.48409 12.5848 8.73882 12.8991 8.73882H17.1007C17.4149 8.73882 17.6696 8.48409 17.6696 8.16985C17.6696 7.85562 17.4149 7.60089 17.1007 7.60089H12.8991ZM14.9999 12.7785C12.1498 12.7785 9.87918 15.2227 9.87918 18.1552C9.87918 21.0877 12.1498 23.5319 14.9999 23.5319C17.85 23.5319 20.1206 21.0877 20.1206 18.1552C20.1206 15.2227 17.85 12.7785 14.9999 12.7785ZM11.0171 18.1552C11.0171 15.7917 12.8361 13.9164 14.9999 13.9164C17.1637 13.9164 18.9826 15.7917 18.9826 18.1552C18.9826 20.5187 17.1637 22.394 14.9999 22.394C12.8361 22.394 11.0171 20.5187 11.0171 18.1552Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TakeProfilePhoto;
