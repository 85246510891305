import React from "react";

function NoMediaYet({ text, icon }) {
  return (
    <div className="media-post">
      <div className="default-media-wrapper settings-media">
        <div className="default-media">
          <div className="default-media-image">
            <img src={icon} alt="" />
          </div>
          <span className="default-text">{text}</span>
        </div>
      </div>
    </div>
  );
}

export default NoMediaYet;
